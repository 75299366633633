<section class="xs:visible gt-xs:hidden">
  <div
    class="bg-cirrus absolute bottom-[7%] mb-2 flex w-full flex-col rounded-t-2xl dark:bg-black">
    <div class="flex w-full cursor-pointer py-0">
      <div class="mx-auto flex w-auto">
        <button
          type="button"
          (click)="toggleEventData()"
          class="outline-none focus:ring-transparent">
          <crew-ui-icon
            class="text-nickel outline-none focus:ring-transparent"
            [name]="!isEventVisible ? 'expand_less' : 'expand_more'" />
        </button>
      </div>
    </div>
    <div
      class="text-carbon flex w-full justify-between px-4 pb-2 dark:text-white">
      <div class="text-[20px] font-medium leading-[25px]">
        {{ showEventDate(selectedEvent) }}
      </div>
      <div
        *ngIf="!isEventVisible"
        class="text-[17px] font-normal leading-[24px]">
        {{ showFlightLegCount(selectedEvent) }}
      </div>
      <div
        *ngIf="isEventVisible"
        class="text-[17px] font-normal leading-[24px]">
        <crew-web-card-day-buttons
          [showPreviousNextButton]="true"
          [showTodayButton]="true" />
      </div>
    </div>
    <div *ngIf="isEventVisible" class="flex flex-col overflow-y-auto pb-2">
      <ng-container *ngTemplateOutlet="calendarEventTemplate"></ng-container>
    </div>
  </div>
</section>
<section class="xs:hidden">
  <div
    class="border-turbine dark:border-charcoal flex justify-between border-b border-solid bg-white px-4 pb-[1.20rem] pt-4 dark:bg-black">
    <div class="w-full justify-between pt-4">
      <div class="py-2">
        <div class="flex items-center pb-1 pt-4 dark:text-white">
          <span
            class="text-carbon text-[20px] font-medium leading-[25px] dark:text-white"
            >{{ showEventDate(selectedEvent) }}</span
          >
          <div class="ml-auto flex justify-between pr-2">
            <div class="ml-auto flex justify-between">
              <crew-web-card-day-buttons
                [showPreviousNextButton]="false"
                [showTodayButton]="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="calendarEventTemplate"></ng-container>
</section>

<!-- Calendar Event Template for Mobile and Large devices -->
<ng-template #calendarEventTemplate>
  <div
    *ngIf="
      selectedEvent &&
        selectedEvent.eventData &&
        selectedEvent.eventData.length > 0;
      else zeroEvent
    ">
    <ng-container *ngFor="let event of selectedEvent.eventData">
      <crew-web-card-non-sequence-info
        *ngIf="
          !event.daysEvent.sequenceActivity &&
          event.daysEvent.eventType !== eventType.STANDBY &&
          event.color
        "
        [sequenceInfo]="event"
        [day]="selectedEvent.day" />
      <crew-web-card-sequence-info
        *ngIf="
          (event.daysEvent.sequenceActivity ||
            event.daysEvent.eventType === eventType.STANDBY) &&
          event.color
        "
        [sequenceInfo]="event"
        [day]="selectedEvent.day" />
    </ng-container>
  </div>
  <ng-template #zeroEvent>
    <section class="flex flex-col px-2">
      <div class="py-4">
        <div
          class="dark:bg-charcoal text-carbon bg-stratus flex rounded px-4 py-3 dark:text-white">
          <span class="text-[15px] font-medium leading-5">{{
            'No events today'
          }}</span>
        </div>
      </div>
    </section>
  </ng-template>
</ng-template>
