import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { LocationService } from './../../services/location.service';
import { TimeService } from '../../services/time.service';
import { SendEmailsV1Response } from './../../services/azure/core.service.types';
import { CoreService } from './../../services/azure/core.service';
import { UserService } from '../../services/user.service';
import { environment } from './../../../../environments/environment';
import { SabreDocumentService } from './../sabre-document/sabre-document.service';
import {
  Validators,
  FormGroup,
  FormBuilder,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import jsPDF from 'jspdf';
@Component({
  selector: 'crew-web-forward-message',
  templateUrl: './forward-message.component.html',
  styleUrls: ['./forward-message.component.scss'],
})
export class ForwardMessageComponent implements OnInit {
  @Output() callOnClickCancel: EventEmitter<boolean> = new EventEmitter();

  forwardMessageForm!: FormGroup;

  isSubmit = false;

  isEmailResponseVisible = false;

  get tripSignInAlert() {
    return this.sabreDocumentService.tripSignInAlert();
  }

  get sabreResponse() {
    return this.sabreDocumentService.sabreResponse();
  }

  constructor(
    private timeService: TimeService,
    private fb: FormBuilder,
    private coreService: CoreService,
    private userService: UserService,
    private sabreDocumentService: SabreDocumentService,
    private locationService: LocationService,
  ) {}

  ngOnInit() {
    this.validateForm();
  }

  getMessageTime(): string {
    return this.timeService.getCurrentDateTime(new Date(), 'ddd, MMM D, YYYY');
  }

  onClickCancel() {
    this.callOnClickCancel.emit(true);
  }

  onClickSend(e: Event): void {
    if (e && this.forwardMessageForm.valid) {
      this.isSubmit = true;
      const toRecipients = this.getRecipients(this.forwardMessageForm.value.to);
      const ccRecipients = this.getRecipients(this.forwardMessageForm.value.cc);
      const subject = this.forwardMessageForm.value.subject;
      const formattedEmailBody = this.getEmailTemplate(this.forwardMessageForm);
      this.getFormDataValues(
        toRecipients,
        ccRecipients,
        subject,
        formattedEmailBody,
      );
    }
  }

  onClickClose() {
    this.isEmailResponseVisible = false;
  }

  getEFlightPlanPdfFileName(): string {
    return this.sabreDocumentService.getEFlightPlanPdfFileName(
      this.sabreResponse?.payload,
    );
  }

  private getFormDataValues(
    toRecipients: string[],
    ccRecipients: string[],
    subject: string,
    formattedEmailBody: string,
  ): void {
    const fileName = `${this.getEFlightPlanPdfFileName()}.pdf`;
    const buCode =
      this.userService.emulatedOrDefaultBusinessUnit()?.toString() || '';
    const formData = new FormData();
    formData.append(
      'employeeNumber',
      this.userService.emulatedOrDefaultEmployeeNumber().toString(),
    );
    formData.append('buCode', buCode);
    formData.append('appVersion', environment.version);
    formData.append('emailBody', formattedEmailBody);
    formData.append('subject', subject);
    formData.append('toRecepients', toRecipients.join().toString());
    formData.append('ccRecepients', ccRecipients.join().toString());
    formData.append('isFeedback', '0');
    formData.append('deviceOS', this.locationService.getDeviceInfo().os ?? '');
    formData.append(
      'osVersion',
      this.locationService.getDeviceInfo().os_version ?? '',
    );
    this.getPdfContent(formData, subject, fileName);
  }

  private getPdfContent(
    formData: FormData,
    subject: string,
    fileName: string,
  ): void {
    if (this.sabreResponse && this.sabreResponse.pdfOutput) {
      this.base64ToFile(
        formData,
        this.sabreResponse.pdfOutput.toString(),
        fileName,
      );
    } else if (this.sabreResponse && this.sabreResponse.returnData) {
      this.htmlToPdf(this.sabreResponse.returnData, subject).then((base64) => {
        if (base64) {
          this.base64ToFile(formData, base64.toString(), fileName);
        }
      });
    }
  }

  private htmlToPdf(html: string, title: string) {
    return new Promise((resolve) => {
      const doc = new jsPDF('p', 'pt', 'a4');
      doc.setFont('Courier');
      doc.html(this.setPdfHtml(html, title), {
        callback: (doc) => {
          resolve(doc.output('datauristring').split(',')[1].toString());
        },
        x: 10,
        y: 10,
        margin: [50, 50, 40, 50],
        autoPaging: 'text',
      });
    });
  }

  private setPdfHtml(html: string, title: string) {
    return `<!DOCTYPE html><html><head><style></style></head>
      <body>
        <div style="font-size: 12px">
          <pre style="text-align:center; font-size: 14px; margin-bottom: 1rem; text-align: center">${title}</pre>
          <pre>${html}</pre>
        </div>
      </body></html>`;
  }

  /**
   * File uplood
   * Converts base64 to Formdata attachment.
   * @param base64
   * @param filename
   * @returns
   */
  private base64ToFile(
    formData: FormData,
    base64: string,
    fileName: string,
  ): void {
    const buffer = new Uint8Array(
      atob(base64.toString())
        .split('')
        .map(function (c) {
          return c.charCodeAt(0);
        }),
    );
    formData.append(
      'fileUpload',
      new File([buffer], fileName, { type: 'application/pdf' }),
    );
    this.callSendEmailsV1(formData);
  }

  private callSendEmailsV1(formData: FormData) {
    this.coreService.sendEmailsV1(formData).subscribe({
      next: (response: SendEmailsV1Response) => {
        this.isSubmit = false;
        this.isEmailResponseVisible = false;
        if (response && response.success) {
          this.isEmailResponseVisible = true;
          this.onClickCancel();
          this.sabreDocumentService.setTripSignInAlertMessage(
            'Email sent successfully.',
            'Success',
          );
        }
      },
      error: (e) => {
        this.isSubmit = false;
        this.isEmailResponseVisible = false;
        this.onClickCancel();
        this.sabreDocumentService.setTripSignInAlertMessage(
          'Please try after some time.',
          'Something went wrong',
        );
        console.error(e);
      },
    });
  }

  /**
   * Validate the Forward message form
   */
  private validateForm() {
    this.forwardMessageForm = this.fb.group({
      to: [
        '',
        Validators.compose([
          (Validators.required,
          this.noWhitespaceValidator(),
          Validators.pattern(this.getEmailValidationRegex())),
        ]),
      ],
      cc: [
        '',
        Validators.compose([
          Validators.pattern(this.getEmailValidationRegex()),
        ]),
      ],
      subject: [
        this.sabreResponse?.keywordDisplay,
        [Validators.required, this.noWhitespaceValidator()],
      ],
      comments: [''],
      title: [this.sabreResponse?.keywordDisplay ?? ''],
    });
  }

  /**
   * Form validator
   * Form input should not allow whitespace
   * @returns
   */
  private noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  private getEmailTemplate(forwardMessageForm: FormGroup): string {
    const forwardMessage = forwardMessageForm.value;
    return `<div>
      <p>${forwardMessage?.comments}</p>
      <hr>
      <div>
          <span style="font-weight:bold;">Subject:</span>
          <span>${forwardMessage?.subject}</span>
      </div>
      <div>
          <span style=font-weight:bold;>Date & Time:</span>
          <span>${this.getMessageTime()}</span>
      </div>
      </br>
    </div>`;
  }

  /**
   * Log to get single or multiple recipients seperated by comma
   * @param value
   * @returns
   */
  private getRecipients(value: string): string[] {
    const recipients: string[] = [];
    if (value) {
      if (value.indexOf(',') > -1) {
        const emails = value.split(',');
        for (const email of emails) {
          if (
            this.getEmailValidationRegex().test(String(email).toLowerCase())
          ) {
            recipients.push(email);
          }
        }
      } else {
        if (this.getEmailValidationRegex().test(String(value).toLowerCase())) {
          recipients.push(value);
        }
      }
    }
    return recipients;
  }

  /**
   * old one - /^(\s*[\w+.%-]+@[\w\.-]+\.[A-Za-z]{2,4},?\s*)+$/
   * Email validation
   */
  private getEmailValidationRegex(): RegExp {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  }
}
