import { AppLayoutModule } from '../../layout/app.layout.module';
import { AppSharedModule } from '../../shared/app.shared.module';
import { CalendarComponent } from './outlets/calendar/calendar.component';
import { CalendarEventDetailsComponent } from './outlets/calendar/calendar-event-details/calendar-event-details.component';
import { CalendarInfoComponent } from './outlets/calendar/calendar-info/calendar-info.component';
import { CalendarMenuComponent } from './outlets/calendar/calendar-menu/calendar-menu.component';
import { CardDayButtonsComponent } from './outlets/calendar/templates/card-day-buttons/card-day-buttons.component';
import { CardFlightInfoComponent } from './outlets/calendar/templates/card-flight-info/card-flight-info.component';
import { CardNonSequenceInfoComponent } from './outlets/calendar/templates/card-non-sequence-info/card-non-sequence-info.component';
import { CardSequenceInfoComponent } from './outlets/calendar/templates/card-sequence-info/card-sequence-info.component';
import { CardTextHighlightComponent } from './outlets/calendar/templates/card-text-highlight/card-text-highlight.component';
import { CrewComponent } from './crew.component';
import { CrewRoutingModule } from './crew-routing.module';
import { DatePipe } from '@angular/common';
import { MessageComponent } from './outlets/message/message.component';
import { MessageDetailViewComponent } from './outlets/message/message-detail-view/message-detail-view.component';
import { MessageMenuComponent } from './outlets/message/message-menu/message-menu.component';
import { MessageMenuListItemComponent } from './outlets/message/message-menu-list-item/message-menu-list-item.component';
import { MessageModalComponent } from './outlets/message/message-modal/message-modal.component';
import { MessageShortViewComponent } from './outlets/message/message-short-view/message-short-view.component';
import { NgModule } from '@angular/core';
import { OverviewComponent } from './outlets/overview/overview.component';
import { OverviewNoEventsCardComponent } from './outlets/overview/overview-no-events-card/overview-no-events-card.component';
import { OverviewNonSequenceCardComponent } from './outlets/overview/overview-non-sequence-card/overview-non-sequence-card.component';
import { OverviewSequenceCardComponent } from './outlets/overview/overview-sequence-card/overview-sequence-card.component';
import { OverviewNotificationModalComponent } from './outlets/overview/overview-notification-modal/overview-notification-modal.component';
import { OverviewNotificationComponent } from './outlets/overview/overview-notification/overview-notification.component';
import { SearchComponent } from './outlets/search/search.component';
import { SearchMenuCardComponent } from './outlets/search/search-menu-card/search-menu-card.component';
import { SearchFilterCardComponent } from './outlets/search/search-filter-card/search-filter-card.component';
import { SearchCommandListCardComponent } from './outlets/search/search-command-list-card/search-command-list-card.component';
import { SignInModalComponent } from './outlets/overview/overview-sign-in-modal/overview-sign-in-modal.component';
import { SeatMapComponent } from './outlets/overview/overview-sequence-card/seat-map/seat-map.component';
import { PaxLoadComponent } from './outlets/overview/overview-sequence-card/pax-load/pax-load.component';

@NgModule({
  providers: [DatePipe],
  declarations: [
    CalendarComponent,
    CalendarEventDetailsComponent,
    CalendarInfoComponent,
    CalendarMenuComponent,
    CardDayButtonsComponent,
    CardFlightInfoComponent,
    CardNonSequenceInfoComponent,
    CardSequenceInfoComponent,
    CardTextHighlightComponent,
    CrewComponent,
    MessageComponent,
    MessageDetailViewComponent,
    MessageMenuComponent,
    MessageMenuListItemComponent,
    MessageModalComponent,
    MessageShortViewComponent,
    OverviewComponent,
    OverviewNoEventsCardComponent,
    OverviewNonSequenceCardComponent,
    OverviewNotificationComponent,
    OverviewNotificationModalComponent,
    OverviewSequenceCardComponent,
    PaxLoadComponent,
    SearchCommandListCardComponent,
    SearchComponent,
    SearchFilterCardComponent,
    SearchMenuCardComponent,
    SeatMapComponent,
    SignInModalComponent,
  ],
  imports: [CrewRoutingModule, AppSharedModule, AppLayoutModule],
  exports: [OverviewComponent],
})
export class CrewModule {}
