import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'crew-web-search-filter-card',
  templateUrl: './search-filter-card.component.html',
  styleUrls: ['./search-filter-card.component.scss'],
})
export class SearchFilterCardComponent {
  @Input()
  title = '';

  @Input()
  placeholder = '';

  @Input()
  value = '';

  @Output()
  clickClose: EventEmitter<boolean> = new EventEmitter();

  @Output()
  searchValue: EventEmitter<string> = new EventEmitter();

  onKeyup(input: string) {
    this.searchValue.emit(input);
  }

  onClose() {
    this.clickClose.emit(true);
  }
}
