<div class="relative w-full">
  <div class="absolute inset-y-0 left-0 flex items-center pl-3">
    <crew-ui-icon [color]="this.searchIconColor" name="search" />
  </div>
  <input
    type="text"
    class="dark:ring-steel dark:bg-charcoal dark:placeholder-nickel border-turbine outline-turbine text-carbon ring-turbine block h-10 w-full rounded-full p-2.5 px-10 text-base ring-1 dark:bg-opacity-80 dark:text-white"
    [placeholder]="placeholder"
    (ngModelChange)="emit($event)"
    [(ngModel)]="value"
    (focus)="onFocus()" />
  <div *ngIf="value && value !== ''">
    <button
      type="button"
      class="absolute inset-y-0 right-2 flex items-center"
      (click)="onCancel()">
      <crew-ui-icon [color]="iconColor" name="cancel" />
    </button>
  </div>
</div>
