import { Component } from '@angular/core';
import {
  NotificationPayload,
  FirebaseIdbService,
} from './shared/services/idb/firebase.idb.service';
import { HttpService } from './shared/services/http.service';
import { Messaging, onMessage } from '@angular/fire/messaging';
import { MessageIdbService } from './shared/services/idb/message.idb.service';
import { InsightsService } from './shared/services/azure/insights.service';
import { TokenService } from './shared/services/token.service';
import { CREW_ROUTES } from './routes/routes';

// ! DYNATRACE NOT WORKING
// eslint-disable-next-line no-var, @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
// declare var dT_: any;

@Component({
  selector: 'crew-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private firebaseIdbService: FirebaseIdbService,
    private httpService: HttpService,
    private insights: InsightsService,
    private messageIdbService: MessageIdbService,
    private messaging: Messaging,
    private tokenService: TokenService,
  ) {
    // ! DYNATRACE NOT WORKING
    // if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
    //   dT_.initAngularNg(http, HttpHeaders);
    //   // console.log('Dynatrace Angular Monitoring initialized');
    // } else console.error('Dynatrace Angular Monitoring not initialized');

    if (this.firebaseIdbService.supportsNotifications()) {
      onMessage(this.messaging, async (payload) => {
        // We do not want to store silent notification or read and acknowledge notifications
        const messageId = payload.data?.['messageId'];
        const isReadAndSign = payload.data?.['isReadAndSign'];
        if (!messageId || isReadAndSign === 'true') return;
        await this.messageIdbService.refreshMessages();
        await this.firebaseIdbService.createNotification(
          payload as NotificationPayload,
        );
      });
    }

    // effectively this prevents someone from being stuck at the auth callback page
    // this, for example, could happen while navigating backwards
    // on the initial load, keys aren't populated yet which prevents this from running
    if (!window.location.href.includes(CREW_ROUTES.authCallback)) return;
    const keys = this.tokenService.getLocalStorageKeyStartingWith('oidc.user');
    if (keys === null) return;
    setTimeout(() => {
      // we just want to capture how often this is occurring
      insights.trackTrace({
        message: `Timeout trigger at auth callback.`,
      });
      window.location.href = CREW_ROUTES.crew.root;
    }, 1000);
  }

  get isLoading() {
    return this.httpService.isLoading();
  }
}
