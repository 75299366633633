import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router,
} from '@angular/router';
import {
  getOidcConfiguration,
  PingAuthenticationService,
} from '@techops-ui/ping-authentication';
import { forkJoin, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { AccessToken, TokenService } from '../services/token.service';
import { PING_AUTH_CONFIG } from '../../app.module';
import { CREW_ROUTES } from '../../routes/routes';
import { UserService } from '../services/user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable, OnDestroy } from '@angular/core';
import { InsightsService } from '../services/azure/insights.service';

@Injectable({
  providedIn: 'root',
})
export class CustomPingAuthGuard implements CanActivate, OnDestroy {
  constructor(
    private deviceService: DeviceDetectorService,
    private insights: InsightsService,
    private pingAuthService: PingAuthenticationService,
    private router: Router,
    private tokenService: TokenService,
    private userService: UserService,
  ) {}

  private refreshTokenFailure$ =
    this.pingAuthService.OnTokenRenewalFailed.subscribe((exception) => {
      this.insights.trackException({ exception });
      this.tokenService.logout();
    });

  public canActivate(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    // bypass any public / auth related routes
    if (route.url.length > 0) {
      switch (route.url[0].path) {
        case CREW_ROUTES.authCallback:
          return of(true);
        case CREW_ROUTES.authError:
          return of(true);
        case CREW_ROUTES.authError401:
          return of(true);
        case CREW_ROUTES.authLogin:
          return of(true);
        case CREW_ROUTES.authLogout:
          return of(true);
        case CREW_ROUTES.authRenewCallback:
          return of(true);
        case CREW_ROUTES.authSignoutCallback:
          return of(true);
        default:
          break;
      }
    }

    const roles = ((route.data && route.data['roles']) || []) as Array<string>;
    return forkJoin([
      this.pingAuthService.hasAnyRole$(roles).pipe(first()),
    ]).pipe(
      mergeMap(async ([hasRole]) => {
        // initialize pf auth service
        try {
          const config = getOidcConfiguration(PING_AUTH_CONFIG);
          await this.pingAuthService.initialize(config);
        } catch (error) {
          this.insights.trackException({
            exception: new Error(
              `Guard Reinitialize Auth Service Failure: ${JSON.stringify(
                error,
              )}`,
            ),
          });
        }

        const user = this.pingAuthService.User;

        // if user object isn't returned from oidc store, redirect to login
        if (user === null) {
          window.location.href = `${CREW_ROUTES.authLogin}?redirect_url=${state.url}`;
          return false;
        } else this.tokenService.load();

        // grab expire time from access token
        let { exp } = this.tokenService.decodeTokenBody<AccessToken>(
          user.access_token,
        );

        let isTokenExpired = this.tokenService.isExpired(exp);

        // continuously try to refresh access token if expired
        // on refresh failure user would be logged out on event trigger
        while (isTokenExpired) {
          // use the refresh token to get a new access token
          try {
            await this.pingAuthService.signinSilent();
          } catch (error) {
            this.insights.trackException({
              exception: new Error(
                `Guard Failed To Refresh Access Token: ${JSON.stringify(
                  error,
                )}`,
              ),
            });
          }

          // get user management config and reinitialize the auth service to load silent signin
          try {
            const userManagerSettings = getOidcConfiguration(PING_AUTH_CONFIG);
            await this.pingAuthService.initialize(userManagerSettings);
          } catch (error) {
            this.insights.trackException({
              exception: new Error(
                `Guard Reinitialize Auth Service Failure: ${JSON.stringify(
                  error,
                )}`,
              ),
            });
          }

          // assign exp from new access token
          exp = this.tokenService.decodeTokenBody<AccessToken>(
            this.pingAuthService.AccessToken,
          ).exp;

          // update expired to break loop
          // loop will also break if refresh token fails
          isTokenExpired = this.tokenService.isExpired(exp);

          // refresh token service
          this.tokenService.load();
        }

        if (!hasRole) {
          this.router.navigate([CREW_ROUTES.authError401]);
          return false;
        }

        return true;
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.refreshTokenFailure$) this.refreshTokenFailure$.unsubscribe();
  }
}
