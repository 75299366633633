import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase } from 'idb';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root',
})
export class IdbService {
  private dbName = 'cci';
  private dbVersion = 1;

  dbPromise: Promise<IDBPDatabase>;

  constructor(private tokenService: TokenService) {
    this.dbPromise = openDB(this.dbName, this.dbVersion, {
      upgrade: (db) => {
        db.createObjectStore(Stores.SavedMessages);
        db.createObjectStore(Stores.Messages);
        db.createObjectStore(Stores.Firebase);
      },
    });
  }
}

export enum Stores {
  SavedMessages = 'saved_messages',
  Messages = 'messages',
  Firebase = 'firebase',
}

export enum Permissions {
  Read = 'readonly',
  Write = 'readwrite',
}
