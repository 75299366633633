<ng-container *ngIf="menuList && menuList.length > 0">
  <div class="dark:bg-charcoal rounded-xl bg-white p-2 dark:text-white">
    <div class="p-2">
      <h2 class="text-[20px] font-medium leading-[25px]"> {{ title }} </h2>
    </div>
    <div
      *ngFor="let menu of menuList; let last = last"
      class="flex w-full flex-col text-[16px] font-normal leading-[24px]">
      <button
        type="button"
        [ngClass]="{
          'border-cirrus dark:border-charcoal border-b border-solid': !last
        }"
        (click)="onClickMenuList(menu.title)"
        class="hover:bg-cirrus dark:hover:bg-charcoal hover:text-carbon px-2 py-3 text-start hover:rounded-sm dark:hover:text-white">
        {{ menu.title }}
      </button>
    </div>
  </div>
</ng-container>
