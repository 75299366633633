import { Component, OnInit } from '@angular/core';
import { Colors, CrewUIDarkModeService } from '@cci-web/crew-ui';
import { MessageService, MessageTitles } from '../message.service';
import { BusinessUnit } from '../../../../../shared/types';
import { UserService } from '../../../../../shared/services/user.service';
import { MessagePriority } from '../../../../../shared/services/azure/message.service.types';
import { MessageIdbService } from '../../../../../shared/services/idb/message.idb.service';
import { HttpService } from '../../../../../shared/services/http.service';
@Component({
  selector: 'crew-web-message-menu',
  templateUrl: './message-menu.component.html',
  styleUrls: [
    './message-menu.component.scss',
    './../../../../../shared/styles/scroll-bar.scss',
  ],
})
export class MessageMenuComponent implements OnInit {
  businessUnits = BusinessUnit;
  colors = Colors;
  messagePriority = MessagePriority;
  messageTitles = MessageTitles;

  get businessUnit() {
    return this.userService.apiDetails()?.businessUnit;
  }

  get selectedPriority() {
    return this.messageService.selectedMenu();
  }

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  get hi6Count() {
    return this.messageService.hi6Count();
  }

  constructor(
    private darkModeService: CrewUIDarkModeService,
    private messageService: MessageService,
    private userService: UserService,
    private httpService: HttpService,
    private messageIdbService: MessageIdbService,
  ) {}

  ngOnInit(): void {
    this.refreshMessage();
  }

  refreshMessage(): void {
    this.messageIdbService.refreshMessages();
  }

  messageCount(priority: MessagePriority): number {
    return this.messageService.getUnreadCount(priority) || 0;
  }
}
