<div class="lt-md:h-[95%] h-full overflow-y-auto">
  <div class="dark:bg-charcoal sticky top-0 bg-white pt-2">
    <div class="flex items-center justify-between px-2">
      <button title="Back" class="p-2" (click)="goBack()">
        <crew-ui-icon
          name="chevron_left"
          [color]="isDark ? colors.Cirrus : colors.Black" />
      </button>
      <button title="Search" class="p-2" (click)="toggleSearch()">
        <crew-ui-icon
          [name]="showSearchBar ? 'search_off' : 'search'"
          [color]="isDark ? colors.Cirrus : colors.Black" />
      </button>
    </div>
    <div *ngIf="showSearchBar" class="mt-6 px-6">
      <crew-ui-search-bar (valueChange)="search($event)" />
    </div>
    <h1
      class="border-cirrus dark:border-carbon dark:bg-charcoal mt-2 border-b px-4 py-4 text-[32px] font-bold leading-10 dark:text-white">
      {{ title }}
    </h1>
  </div>
  <div class="lt-sm:pb-10 dark:bg-charcoal bg-white pb-4" *ngIf="messages">
    <ul>
      <li
        class="cursor-pointer px-2 py-4"
        *ngFor="let message of messages; let i = index; let last = last"
        (click)="onSelectMessage(message)"
        [ngClass]="{
          'bg-stratosphere rounded-lg text-white':
            selectedId === message.messageId,
          'border-cirrus dark:border-steel border-b': !last,
          'hover:bg-cirrus dark:hover:bg-carbon hover:rounded-lg':
            selectedId !== message.messageId
        }">
        <div class="flex w-full items-center justify-start">
          <div class="flex"
            ><div
              *ngIf="message.messageRead === 0"
              class="bg-stratosphere mr-1 h-2 w-2 rounded-full">
            </div
          ></div>
          <p
            class="line-clamp-2 w-full text-[16px] font-medium leading-[24px] dark:text-white"
            [class.mx-1]="message.messageRead === 0"
            [class.mx-4]="message.messageRead === 1">
            {{ message.messageTitle }}
          </p>
          <span
            class="dark:text-nickel ml-auto px-1 text-[14px] font-normal leading-[20px]">
            {{ showDateTime(message.effectiveDate) }}
          </span>
        </div>
        <!-- <div
          class="ml-4 text-[14px] font-normal leading-[20px] dark:text-white">
          {{ message.messageSignature }}
        </div> -->
      </li>
    </ul>
  </div>
  <div
    *ngIf="!messages || messages.length === 0"
    class="bg-cirrus dark:bg-carbon border-turbine dark:border-steel flex justify-center border-t">
    <p class="text-nickel mt-16 cursor-none text-center"> No messages </p>
  </div>
</div>
