import { formatDate, unsetTimeZone } from './date.utils';
import { FlightLeg as CalendarFlightLeg } from '../services/azure/calendar.service.types';
import {
  FlightLeg as ScheduleFlightLeg,
  SequenceEvent,
} from '../services/azure/schedule.service.types';
import { CheckListItemParameterType, DateFormat } from '../types';
import {
  DeadHeadAirlineCode,
  REASSIGNMENT_CODE,
  DELAYED_CODE,
} from './../types';
export const getDepartureColor = (
  flightInfo: CalendarFlightLeg | ScheduleFlightLeg,
): string => {
  let style = `dark:text-calathea text-zanzibar`;
  if (
    flightInfo.actual &&
    formatDate(
      flightInfo.actual.departureDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) >
      formatDate(
        flightInfo.scheduled.departureDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    style = `dark:text-sushi text-afterburner`;
  }
  if (
    flightInfo.reScheduled &&
    formatDate(
      flightInfo.scheduled.departureDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) !==
      formatDate(
        flightInfo.reScheduled.departureDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    style = `dark:text-sushi text-afterburner`;
  }
  return style;
};

export const getArrivalColor = (
  flightInfo: CalendarFlightLeg | ScheduleFlightLeg,
): string => {
  let style = `dark:text-calathea text-zanzibar`;
  if (
    flightInfo.actual &&
    formatDate(
      flightInfo.actual.arrivalDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) >
      formatDate(
        flightInfo.scheduled.arrivalDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    style = `dark:text-sushi text-afterburner`;
  }
  if (
    flightInfo.reScheduled &&
    formatDate(
      flightInfo.scheduled.arrivalDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) !==
      formatDate(
        flightInfo.reScheduled.arrivalDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    style = `dark:text-sushi text-afterburner`;
  }
  return style;
};

export const showArrivalTime = (
  flightInfo: CalendarFlightLeg | ScheduleFlightLeg,
): string => {
  let arrivalTime = ``;
  if (flightInfo.actual) {
    arrivalTime = flightInfo.actual.arrivalDateTime.localTime;
  }
  if (flightInfo.actual === null && flightInfo.reScheduled) {
    arrivalTime = flightInfo.reScheduled.arrivalDateTime.localTime;
  }
  return arrivalTime;
};

export const showDepartureTime = (
  flightInfo: CalendarFlightLeg | ScheduleFlightLeg,
): string => {
  let departureTime = ``;
  if (flightInfo.actual) {
    departureTime = flightInfo.actual.departureDateTime.localTime;
  }
  if (flightInfo.actual === null && flightInfo.reScheduled) {
    departureTime = flightInfo.reScheduled.departureDateTime.localTime;
  }
  return departureTime;
};

export const validateActualRescheduledDepartureTime = (
  flightInfo: CalendarFlightLeg | ScheduleFlightLeg,
): boolean => {
  let response = false;
  if (
    flightInfo.actual &&
    formatDate(
      flightInfo.actual.departureDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) !==
      formatDate(
        flightInfo.scheduled.departureDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    response = true;
  }
  if (
    flightInfo.reScheduled &&
    formatDate(
      flightInfo.reScheduled.departureDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) !==
      formatDate(
        flightInfo.scheduled.departureDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    response = true;
  }
  return response;
};

export const validateActualRescheduledArrivalTime = (
  flightInfo: CalendarFlightLeg | ScheduleFlightLeg,
): boolean => {
  let response = false;
  if (
    flightInfo.actual &&
    formatDate(
      flightInfo.actual.arrivalDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) !==
      formatDate(
        flightInfo.scheduled.arrivalDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    response = true;
  }
  if (
    flightInfo.reScheduled &&
    formatDate(
      flightInfo.reScheduled.arrivalDateTime.localTime,
      DateFormat.DateTimeFormat24,
    ) !==
      formatDate(
        flightInfo.scheduled.arrivalDateTime.localTime,
        DateFormat.DateTimeFormat24,
      )
  ) {
    response = true;
  }
  return response;
};

export const airlineCodeStyle = (
  flightInfo: CalendarFlightLeg | ScheduleFlightLeg,
  type: string,
): string => {
  let style = ``;
  let title = ``;
  // Flight Delayed
  if (
    flightInfo.flightStatus &&
    flightInfo.flightStatus.length > 0 &&
    flightInfo.flightStatus.includes(DELAYED_CODE)
  ) {
    style = `dark:border-tangerine border-nectarine dark:text-tangerine text-nectarine`;
    title = DeadHeadAirlineCode.DELAYED;
  }
  // Flight Reassigned
  if (
    flightInfo.legAssignmentCode &&
    REASSIGNMENT_CODE.includes(flightInfo.legAssignmentCode)
  ) {
    style = `dark:border-sushi border-afterburner dark:text-sushi text-afterburner`;
    title = DeadHeadAirlineCode.REASSIGNED;
  }
  // Flight Deadhead
  if (flightInfo.deadHead) {
    style = `dark:border-troposphere border-stratosphere text-stratosphere dark:text-troposphere`;
    title = DeadHeadAirlineCode.DEADHEAD;
    if (
      flightInfo.deadHeadAirlineCode &&
      flightInfo.deadHeadAirlineCode === DeadHeadAirlineCode.RPT
    ) {
      style = `dark:border-sushi border-afterburner dark:text-sushi text-afterburner`;
      title = DeadHeadAirlineCode.REASSIGNED;
      // } else if (
      //   flightInfo.deadHeadAirlineCode === DeadHeadAirlineCode.NS ||
      //   flightInfo.deadHeadAirlineCode === DeadHeadAirlineCode.AA
      // ) {
      //   style = `dark:border-troposphere border-stratosphere text-stratosphere dark:text-troposphere`;
      //   title = DeadHeadAirlineCode.DEADHEAD;
    }
  }
  // Flight Cancelled
  if (flightInfo.cancelled) {
    style = `dark:border-sushi border-afterburner dark:text-sushi text-afterburner`;
    title = DeadHeadAirlineCode.CANCELLED;
  }
  return type === 'style' ? style : title;
};

export const stringReplace = (
  parameter: string,
  param: string,
  value: string,
) => {
  return parameter.split(param).join(value);
};

/**
 * - replace() did not worked, so used split and join
 * @param keywordDisplay
 * @param leg
 * @returns
 */
export const getFlightDataFromParameter = (
  parameter: string,
  leg: ScheduleFlightLeg | CalendarFlightLeg,
  employeeNumber: string,
): string => {
  if (parameter) {
    const departureDateTime = unsetTimeZone(
      leg.scheduled?.departureDateTime?.localTime,
    );
    const arrivalDateTime = unsetTimeZone(
      leg.scheduled?.arrivalDateTime?.localTime,
    );
    const listType = CheckListItemParameterType;
    parameter = stringReplace(parameter, listType.Space, listType.Gap);
    parameter = stringReplace(
      parameter,
      listType.GmtDepartureDate,
      departureDateTime as string,
    );
    parameter = stringReplace(
      parameter,
      listType.ArrivalGate,
      leg?.arrivalGate ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.DepartureTime,
      formatDate(departureDateTime, DateFormat.HHmm),
    );
    parameter = stringReplace(
      parameter,
      listType.ArrivalTime,
      formatDate(arrivalDateTime, DateFormat.HHmm),
    );
    parameter = stringReplace(
      parameter,
      listType.DepartureGate,
      leg?.departureGate ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.DepatureDateDay,
      formatDate(departureDateTime, DateFormat.DD).toString(),
    );
    parameter = stringReplace(
      parameter,
      listType.FlightNumber,
      leg?.flightNumber?.toString(),
    );
    parameter = stringReplace(
      parameter,
      listType.EmployeeNumber,
      employeeNumber,
    );
    parameter = stringReplace(
      parameter,
      listType.DepartureDate,
      formatDate(departureDateTime, DateFormat.DDMMM)
        .toString()
        .toLocaleUpperCase(),
    );
    parameter = stringReplace(
      parameter,
      listType.DepartureStation,
      leg?.departureStation?.toString(),
    );
    parameter = stringReplace(
      parameter,
      listType.DepatureTimeActual,
      formatDate(departureDateTime, DateFormat.HHmm).toString(),
    );
    parameter = stringReplace(
      parameter,
      listType.ArrivalStation,
      leg?.arrivalStation?.toString(),
    );
    parameter = stringReplace(
      parameter,
      listType.FlightOriginationDate,
      formatDate(leg?.flightOriginationDate, DateFormat.DDMMM)
        .toLocaleUpperCase()
        .toString(),
    );
  }
  return parameter;
};

export const getScheduleFlightDataFromParameter = (
  parameter: string,
  leg: ScheduleFlightLeg,
  sequence: SequenceEvent,
) => {
  if (parameter) {
    const departureDateTime = unsetTimeZone(
      leg.scheduled?.departureDateTime?.localTime,
    );
    const arrivalDateTime = unsetTimeZone(
      leg.scheduled?.arrivalDateTime?.localTime,
    );
    const listType = CheckListItemParameterType;
    parameter = parameter.split(listType.CrewSeat).join(sequence?.positionCode);
    parameter = parameter
      .split(listType.TailNumber)
      .join(leg?.assignedTail ?? '');
    parameter = parameter
      .split(listType.SequenceNumber)
      .join(sequence?.sequenceNumber);
    parameter = stringReplace(
      parameter,
      listType.CrewDivision,
      sequence?.activeSequence?.division ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.StartDate,
      formatDate(departureDateTime, DateFormat.DDMMM).toString() ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.EndDate,
      formatDate(departureDateTime, DateFormat.DDMMM).toString() ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.StartTime,
      formatDate(departureDateTime, DateFormat.HHmm).toString() ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.EndTime,
      formatDate(arrivalDateTime, DateFormat.HHmm).toString() ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.CrewBase,
      sequence?.activeSequence?.base ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.CrewEquipment,
      leg?.equipmentQuals?.equipmentGroup ?? '',
    );
    parameter = stringReplace(
      parameter,
      listType.SequenceDate,
      formatDate(
        sequence?.tripSignInInfo?.sequenceOriginationDate,
        DateFormat.DDMMM,
      )
        .toString()
        .toLocaleUpperCase(),
    );
    // ! Need to check it is required
    // parameter = parameter.split(listType.WeatherRegion).join('');
    // parameter = parameter.split(listType.WeatherType).join('');
    // parameter = parameter.split(listType.PlannedTakeoffWay).join('');
    // parameter = parameter.split(listType.PlannedOffWeight).join('');
    // parameter = parameter.split(listType.Date).join('');
    // parameter = parameter.split(listType.Month).join('');
    // parameter = parameter.split(listType.SelNum).join('');
    // parameter = parameter.split(listType.EmpInputAllowed).join('');
    // parameter = parameter.split(listType.CabinClass).join('');
    // parameter = parameter.split(listType.CkaSelSeat).join('');
    // parameter = parameter.split(listType.CkaSelEmpNbr).join('');
    // parameter = parameter.split(listType.FlapSettings).join('');
    // parameter = parameter.split(listType.NumberOfDays).join('');
    // parameter = parameter.split(listType.AircraftType).join('');
  }

  return parameter;
};
