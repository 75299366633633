import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import { BottomNavComponent } from './bottom-nav/bottom-nav.component';
import { AppSharedModule } from '../shared/app.shared.module';

@NgModule({
  declarations: [SideNavComponent, BottomNavComponent],
  imports: [CommonModule, AppSharedModule],
  exports: [SideNavComponent, BottomNavComponent],
})
export class AppLayoutModule {}
