<div class="w-full">
  <div class="relative">
    <input
      [type]="type"
      class="dark:bg-charcoal text-carbon focus:ring-stratosphere m-0 block h-14 w-full rounded-lg p-4 pr-10 text-base ring-1 transition ease-in-out dark:bg-opacity-0 dark:text-white"
      [ngClass]="{
        'dark:ring-aluminum': !value && !isInvalid,
        'dark:ring-steel': value && !isInvalid,
        'focus:ring-afterburner': isInvalid,
        'ring-afterburner': isInvalid,
        'ring-steel': value && !isInvalid,
        'ring-turbine': !value && !isInvalid,
      }"
      [(ngModel)]="value"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [placeholder]="isFocused && placeholder ? '' : placeholder"
      (click)="$event.stopPropagation()" />
    <label
      *ngIf="!placeholder || isFocused"
      class="absolute -top-1 left-0 h-full p-4 text-xs"
      [ngClass]="{
        'text-afterburner': isInvalid,
        'text-stratosphere': !isInvalid
      }">
      {{ label }}
    </label>
    <button
      *ngIf="value && type !== 'number'"
      type="button"
      class="absolute inset-y-0 right-2 flex items-center pr-3"
      (click)="this.onCancel()">
      <crew-ui-icon [color]="iconColor" name="cancel" />
    </button>
  </div>
  <span
    *ngIf="assistiveLabel && assistiveLabel !== ''"
    class="ml-4 mt-1 flex text-base"
    [ngClass]="{
      'text-afterburner':isInvalid,
      'text-nickel':!isInvalid,
    }">
    - {{ assistiveLabel }}
  </span>
</div>
