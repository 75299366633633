import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { CoreService } from './azure/core.service';
import { BidStatus, BusinessUnit } from '../types';
import { TokenService } from './token.service';
import {
  CoreServiceBaseRequest,
  CoreServiceWebsite,
  UserBidStatus,
} from './azure/core.service.types';
import { firstValueFrom } from 'rxjs';
import {
  GetDashboardInfoRequest,
  BidStatuses,
} from './azure/schedule.service.types';
import { ScheduleService } from './azure/schedule.service';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { formatDate } from '../utils/date.utils';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private coreService: CoreService,
    private tokenService: TokenService,
    private scheduleService: ScheduleService,
    private pingAuthService: PingAuthenticationService,
  ) {
    this.loadEmulationState();
  }

  isLoading = signal(true);

  airlineCode: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.Organization ?? 'AA',
  );

  firstName: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.first_name ?? '',
  );

  lastName: Signal<string> = computed(
    () => this.tokenService.tokens()?.accessToken.last_name ?? '',
  );

  employeeNumber: Signal<number> = computed(() =>
    Number.parseInt(this.tokenService.tokens()?.accessToken.uid ?? ''),
  );

  isEmulationEnabled: Signal<boolean> = computed(
    () => !!this.emulatedBusinessUnit(),
  );

  isLoggedIn: Signal<boolean> = computed(
    () => !!this.tokenService.tokens()?.accessToken,
  );

  bidStatus: WritableSignal<UserBidStatus | undefined> = signal(undefined);

  apiDetails: WritableSignal<
    | {
        businessUnit: BusinessUnit;
        isAdmin: boolean;
        isActive: boolean;
        emulatedFirstName: string;
        emulatedLastName: string;
        websites: CoreServiceWebsite[];
        mailBox: string;
      }
    | undefined
  > = signal(undefined);

  emulatedEmployeeNumber: WritableSignal<number | undefined> =
    signal(undefined);

  emulatedBusinessUnit: WritableSignal<BusinessUnit | undefined> =
    signal(undefined);

  emulatedAirlineCode: WritableSignal<string | undefined> = signal(undefined);

  isAAFAMQFA = computed(() => {
    return (
      this.apiDetails()?.businessUnit === BusinessUnit.AAFA ||
      this.apiDetails()?.businessUnit === BusinessUnit.MQFA
    );
  });

  isAAPIMQPI = computed(() => {
    return (
      this.apiDetails()?.businessUnit === BusinessUnit.AAPI ||
      this.apiDetails()?.businessUnit === BusinessUnit.MQPI
    );
  });

  paddedEmployeeNumber = computed(() => {
    let employeeNumber = this.employeeNumber().toString();
    if (employeeNumber && employeeNumber.length !== 6) {
      employeeNumber = employeeNumber.toString().padStart(6, '0');
    }
    return employeeNumber;
  });

  emulatedOrDefaultEmployeeNumber = computed(
    () => this.emulatedEmployeeNumber() ?? this.employeeNumber(),
  );

  emulatedOrDefaultBusinessUnit = computed(() =>
    this.isEmulationEnabled()
      ? this.emulatedBusinessUnit()
      : this.apiDetails()?.businessUnit,
  );

  emulatedOrDefaultAirlineCode = computed(
    () => this.emulatedAirlineCode() ?? this.airlineCode(),
  );

  // {versionNumber}-{empId}-{emulatedEmpId}-{useragent}
  appSession = computed(
    () =>
      `${environment.version}-${this.employeeNumber()}-${
        this.emulatedEmployeeNumber() ?? 'NotEmulating'
      }-${navigator.userAgent}`,
  );

  isEmployeeCheckAirman = computed(
    () => this.bidStatus()?.checkAirman || this.bidStatus()?.inactiveSupervisor,
  );

  private emulationSettingsLocalStorageKey = 'emulation-settings';

  /**
   * Set four part Bit status
   * It is used in search, overview, message pages
   * @returns
   */
  async setFourPartBitStatus(
    employeeLogin: number,
    airlineCode: string,
    businessUnit: BusinessUnit,
  ) {
    const bidStatusPayload: GetDashboardInfoRequest = {
      localDate: formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss'),
      siteMinderEmployeeId: this.employeeNumber(),
      employeeLogin,
      airlineCode,
      businessUnit,
      appSessionId: this.appSession(),
    };

    const bidStatusResult = await firstValueFrom(
      this.scheduleService.getDashBoardInfo(bidStatusPayload),
    );

    if (bidStatusResult.success && bidStatusResult.dashBoardResponse) {
      const bidStatus = bidStatusResult?.dashBoardResponse?.bidStatuses?.find(
        (bitStatus: BidStatuses) =>
          bitStatus.contractMonthType === BidStatus.CONTRACT,
      );
      const departureStation =
        bidStatusResult?.dashBoardResponse?.departureStations;
      if (bidStatus) {
        this.bidStatus.set({
          contractMonth: bidStatus.contractMonth,
          crewBase: bidStatus.currentBase,
          crewDivision: bidStatus.currentDivision,
          crewEquipment: bidStatus.currentEquipment,
          crewSeat: bidStatus.seatCategory,
          checkAirman: bidStatus.checkAirman,
          fsmSupervisorNumber: bidStatus?.fsmSupervisorNumber ?? '',
          checkAirmanType:
            bidStatus.checkAirmanCurrentMonthStatus?.checkAirmanType,
          depatureStations: departureStation,
          inactiveSupervisor: bidStatus.inactiveSupervisor,
        });
      }
    } else {
      this.isLoading.set(false);
      // console.error('Failed to load bid status', bidStatusResult);
      return;
    }
  }

  /**
   * On emulate get the emulated user details
   * @returns
   */
  async setApiDetails() {
    const payload: CoreServiceBaseRequest = {
      airlineCode: this.airlineCode(),
      appSessionId: this.appSession(),
      empIdLogin: this.emulatedOrDefaultEmployeeNumber(),
      siteMinderEmpId: this.employeeNumber(),
    };
    const userDetailsResult = await firstValueFrom(
      this.coreService.getUserDetailsByBu(payload),
    );
    if (!userDetailsResult || !userDetailsResult.success) {
      this.isLoading.set(false);
      console.error('Failed to load user details', userDetailsResult);
      return;
    } else {
      this.emulatedAirlineCode.set(userDetailsResult.businessUnit.airlineCode);
      this.apiDetails.set({
        businessUnit: userDetailsResult.businessUnit.bu as BusinessUnit,
        isActive: userDetailsResult.businessUnit.active,
        isAdmin: userDetailsResult.businessUnit.admin,
        emulatedFirstName: userDetailsResult.firstName,
        emulatedLastName: userDetailsResult.lastName,
        websites: userDetailsResult.websites,
        mailBox: userDetailsResult.businessUnit.mailBox,
      });

      const employeeLogin = this.emulatedOrDefaultEmployeeNumber();
      const airlineCode = this.emulatedOrDefaultAirlineCode();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const businessUnit = this.emulatedOrDefaultBusinessUnit()!;

      await this.setFourPartBitStatus(employeeLogin, airlineCode, businessUnit);
    }
  }

  async load() {
    this.isLoading.set(true);
    const loggedIn = await firstValueFrom(this.pingAuthService.loggedIn$);
    if (!loggedIn) {
      this.isLoading.set(false);
      return;
    }
    await this.setApiDetails();
    this.isLoading.set(false);
  }

  clearEmulation() {
    this.emulatedBusinessUnit.set(undefined);
    this.emulatedEmployeeNumber.set(undefined);
    localStorage.removeItem(this.emulationSettingsLocalStorageKey);
    this.load();
  }

  emulate(employeeId: number, businessUnit: BusinessUnit) {
    this.emulatedBusinessUnit.set(businessUnit);
    this.emulatedEmployeeNumber.set(employeeId);
    this.saveEmulationState(employeeId, businessUnit);
    this.load();
  }

  loadEmulationState() {
    const localStorageSettings = localStorage.getItem(
      this.emulationSettingsLocalStorageKey,
    );

    if (!localStorageSettings) return;

    const settings = JSON.parse(localStorageSettings) as EmulationState;

    this.apiDetails.set({
      businessUnit: settings.businessUnit as BusinessUnit,
      isAdmin: false,
      isActive: false,
      emulatedFirstName: String(settings.emulatedFirstName),
      emulatedLastName: String(settings.emulatedLastName),
      websites: settings.websites as CoreServiceWebsite[],
      mailBox: '',
    });

    this.emulate(
      Number(settings.employeeId),
      settings.businessUnit as BusinessUnit,
    );
  }

  saveEmulationState(employeeId: number, businessUnit: BusinessUnit) {
    const emulationState: EmulationState = {
      employeeId,
      businessUnit,
      emulatedFirstName: this.apiDetails()?.emulatedFirstName ?? '',
      emulatedLastName: this.apiDetails()?.emulatedLastName ?? '',
      websites: this.apiDetails()?.websites ?? [],
    };

    localStorage.setItem(
      this.emulationSettingsLocalStorageKey,
      JSON.stringify(emulationState),
    );
  }

  logout() {
    this.clearEmulation();
    this.tokenService.logout();
  }
}

type EmulationState = {
  employeeId: number;
  businessUnit: BusinessUnit;
  emulatedFirstName: string;
  emulatedLastName: string;
  websites: CoreServiceWebsite[];
};
