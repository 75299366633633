<div class="dark:bg-charcoal ml-px bg-white pt-4">
  <div class="flex items-center justify-start">
    <crew-web-settings-button class="ml-4" />
  </div>

  <div class="dark:border-steel ml-auto flex justify-between border-b pb-2">
    <h1 class="ml-3 mt-4 pr-4 text-[32px] font-bold leading-10 dark:text-white">
      Messages
    </h1>
    <button
      title="Refresh schedule"
      type="button"
      (click)="refreshMessage()"
      class="ml-3 mt-4 pr-4 pt-1">
      <crew-ui-icon
        name="refresh"
        [color]="
          isDark ? colors.Troposphere : colors.Stratosphere
        "></crew-ui-icon>
    </button>
  </div>
  <!-- TODO Remove everything related to HI6 when it's no longer needed -->
  <ol>
    <crew-web-message-menu-list-item
      [priority]="messagePriority.HI6"
      [title]="messageTitles.HI6"
      [color]="colors.Monarch"
      [messageCount]="hi6Count"
      icon="assignment_late" />
  </ol>
  <div class="lt-sm:h-128 overflow-auto pb-4">
    <!-- TODO See above -->
    <h5
      class="dark:bg-carbon bg-stratus dark:border-steel border-y px-4 py-3 font-medium dark:text-white">
      CCI messages
    </h5>
    <ol>
      <crew-web-message-menu-list-item
        *ngIf="businessUnit === businessUnits.AAPI"
        [priority]="messagePriority.ReadAndAcknowledge"
        [title]="messageTitles.ReadAndAcknowledge"
        [color]="colors.Afterburner"
        [messageCount]="messageCount(messagePriority.ReadAndAcknowledge)"
        icon="error" />
      <crew-web-message-menu-list-item
        *ngIf="businessUnit !== businessUnits.AAPI"
        [priority]="messagePriority.High"
        [title]="messageTitles.High"
        [color]="colors.Afterburner"
        [messageCount]="messageCount(messagePriority.High)"
        icon="error" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Medium"
        [title]="messageTitles.Medium"
        [color]="colors.Nectarine"
        [messageCount]="messageCount(messagePriority.Medium)"
        icon="warning" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Low"
        [title]="messageTitles.Low"
        [color]="colors.Stratosphere"
        [messageCount]="messageCount(messagePriority.Low)"
        icon="info" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Saved"
        [title]="messageTitles.Saved"
        [color]="colors.Stratosphere"
        [messageCount]="messageCount(messagePriority.Saved)"
        icon="file_save" />
      <crew-web-message-menu-list-item
        [priority]="messagePriority.Deleted"
        [title]="messageTitles.Deleted"
        [color]="colors.Stratosphere"
        [messageCount]="messageCount(messagePriority.Deleted)"
        icon="delete" />
    </ol>
    <div *ngIf="businessUnit === businessUnits.AAPI">
      <h5
        class="dark:bg-carbon bg-stratus dark:border-steel border-y px-4 py-3 font-medium dark:text-white">
        HISEND messages
      </h5>
      <ol>
        <crew-web-message-menu-list-item
          [color]="colors.Stratosphere"
          [isModal]="true"
          icon="edit_square"
          modalTitle="Crew Scheduling" />
        <crew-web-message-menu-list-item
          [color]="colors.Stratosphere"
          [isModal]="true"
          icon="edit_square"
          modalTitle="Crew Planning" />
        <crew-web-message-menu-list-item
          [color]="colors.Stratosphere"
          [isModal]="true"
          icon="edit_square"
          modalTitle="Crew Manning" />
      </ol>
    </div>
  </div>
</div>
