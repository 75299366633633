import { Component } from '@angular/core';
import { SettingsModalService } from '../settings-modal/settings-modal.service';

@Component({
  selector: 'crew-web-settings-button',
  templateUrl: './settings-button.component.html',
  styleUrls: ['./settings-button.component.scss'],
})
export class SettingsButtonComponent {
  constructor(private settingsModalService: SettingsModalService) {}

  show() {
    this.settingsModalService.show();
  }
}
