import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Colors } from '@cci-web/crew-ui';
import {
  CheckListItem,
  CoreServiceWebsite,
} from '../../../../../shared/services/azure/core.service.types';
@Component({
  selector: 'crew-web-search-command-list-card',
  templateUrl: './search-command-list-card.component.html',
  styleUrls: ['./search-command-list-card.component.scss'],
})
export class SearchCommandListCardComponent {
  colors = Colors;

  @Input() commands = [] as CheckListItem[] | undefined;

  @Input() websites = [] as CoreServiceWebsite[] | undefined;

  @Input() showIcon = false as boolean;

  @Input() showViewText = false as boolean;

  @Output() selectedValue: EventEmitter<CheckListItem> = new EventEmitter();

  onClickCommand(command: CheckListItem) {
    this.selectedValue.emit(command);
  }

  openWebsite(websiteUrl: string) {
    window.open(websiteUrl);
  }
}
