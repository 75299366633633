export const CREW_ROUTES = Object.freeze({
  authCallback: 'auth-callback',
  authError: 'error',
  authError401: 'error-401',
  authLogin: 'login',
  authLogout: 'logout',
  authRenewCallback: 'renew-callback',
  authSignoutCallback: 'signout-callback',
  crew: {
    root: 'crew',
    overview: 'overview',
    calendar: 'calendar',
    message: 'message',
    search: 'search',
    install: 'install',
  },
});
