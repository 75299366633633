import { Component, OnInit } from '@angular/core';
import { Colors, CrewUIDarkModeService } from '@cci-web/crew-ui';
import { SettingsModalService } from '../../../../shared/components/settings-modal/settings-modal.service';
import { TimeService } from '../../../../shared/services/time.service';
import { EventTypes, OverviewService } from './overview.service';
import {
  formatDate,
  isCurrentTimeLessThanGivenTime,
} from '../../../../shared/utils/date.utils';
import {
  Events,
  SequenceEvent,
} from '../../../../shared/services/azure/schedule.service.types';
import { FirebaseIdbService } from '../../../../shared/services/idb/firebase.idb.service';
import { SabreDocumentService } from '../../../../shared/components/sabre-document/sabre-document.service';
import { TripSignInButtonTitle } from '../../../../shared/types';
import { UserService } from '../../../../shared/services/user.service';
import { InsightsService } from '../../../../shared/services/azure/insights.service';
import { LocationService } from '../../../../shared/services/location.service';
@Component({
  selector: 'crew-web-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private firebaseService: FirebaseIdbService,
    private insightsService: InsightsService,
    private locationService: LocationService,
    private overviewService: OverviewService,
    private sabreDocumentService: SabreDocumentService,
    private settingsModalService: SettingsModalService,
    private timeService: TimeService,
    private userService: UserService,
  ) {}

  /**
   * Toggle trip sign alert
   */
  get tripSignInAlert() {
    return this.sabreDocumentService.tripSignInAlert();
  }

  get isMobileOrEmulating() {
    if (!this.locationService.isDesktopDevice()) return true;
    // separated the check to allow for testing
    if (this.userService.isEmulationEnabled()) return true;
    return false;
  }

  set clearTripSignInAlertMessage(event: Event) {
    this.sabreDocumentService.clearTripSignInAlertMessage(event);
  }

  /**
   * Holds the trip sign in response data
   */
  get tripSignInResponse() {
    return this.sabreDocumentService.tripSignInResponse();
  }

  /**
   * Holds the trip sign in response data
   */
  get hasSabreResponse() {
    return this.sabreDocumentService.hasSabreResponse();
  }

  get sabreResponse() {
    return this.sabreDocumentService.sabreResponse();
  }

  /**
   * Events
   */

  get todaysEvents() {
    return this.overviewService.todaysEvents();
  }

  get upcomingEvents() {
    return this.overviewService.upcomingEvents();
  }

  get lastUpdatedTime() {
    return this.overviewService.lastUpdatedTime();
  }

  /**
   * Firebase service
   */

  get hasNotifications() {
    return this.firebaseService.notifications().length > 0;
  }

  get supportsNotifications() {
    return this.firebaseService.supportsNotifications();
  }

  /**
   * Theme
   */

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  /**
   * Modal window
   */

  get showLocationModal() {
    return this.overviewService.showLocationModal();
  }

  get showNotificationModal() {
    return this.overviewService.showNotificationModal();
  }

  get upcomingDate() {
    return this.upcomingEvents && this.upcomingEvents.length > 0
      ? this.overviewService.getShortMonth(
          new Date(this.upcomingEvents[0].activityStartTime),
        )
      : '';
  }

  get isAAPIMQPI() {
    return this.userService.isAAPIMQPI();
  }

  colors = Colors;

  eventTypes = EventTypes;

  todaysDate = this.overviewService.getShortMonth(new Date());

  ngOnInit(): void {
    this.insightsService.trackPageView({
      name: 'Overview',
    });
    this.overviewService.loadDashBoardInfo();
    this.firebaseService.reloadUnacknowledgedONENotifications();
  }

  showUpdatedTime(): string {
    let response = ``;
    response = `Updated ${formatDate(
      this.lastUpdatedTime,
      'MM/DD/YYYY',
    )} at ${this.timeService.getTime(this.lastUpdatedTime)}`;
    return response;
  }

  onClickClose(event: Event) {
    this.clearTripSignInAlertMessage = event;
  }

  getType(event: Events) {
    return this.overviewService.getType(event);
  }

  toggleLocationModal() {
    this.overviewService.toggleShowLocationModal();
  }

  toggleNotificationModal() {
    this.overviewService.toggleNotificationModal();
  }

  showSettingsModal() {
    this.settingsModalService.show();
  }

  showToday(selectedDate: string) {
    return (
      selectedDate ===
      new Date().toLocaleString('en-US', {
        month: 'short',
        day: '2-digit',
      })
    );
  }

  /* closeTripSignInOverlay(event: Event): void {
    event.stopPropagation();
    this.sabreDocumentService.clearTripSignInData();
  } */

  onClickEligibleForTrip(event: Events) {
    const {
      sequence = {} as SequenceEvent,
      activityStartTime,
      sequenceNumber,
      isStandby,
    } = event;
    const { firstFlightLeg } = sequence;
    if (
      (firstFlightLeg?.legIndex === 1 && !event.isStandby) ||
      event.isStandby
    ) {
      let signInDepSta = firstFlightLeg?.departureStation ?? '';
      if (event.isStandby) {
        signInDepSta = event.signInAirport;
      }
      const payload = {
        standBy: isStandby,
        signInDepSta: signInDepSta,
        sequenceOriginationDate: formatDate(activityStartTime),
        sequenceNumber: sequenceNumber,
      };
      this.sabreDocumentService.callCrewTripSignIn(payload, event);
    }
  }

  /**
   * ! [SignIn Button = `Sign in + confirm FFD`/`Sign in for trip`]
   * signedIn = false && legIndex =1 && isSignInEligible = true && user is pilot == 'Sign in + confirm FFD'
   * signedIn = false && legIndex =1 && isSignInEligible = true && user is not pilot == 'Sign in for trip'
   * ! If this.onClickEligibleForTrip() is SUCCESSFUL hide the [SignIn Button] with the key this.tripSignInResponse
   * @param event
   * @param type
   */
  eligibleForTrip(event: Events, type: string | boolean): boolean | string {
    let responseAsBoolean = false;
    let title = ``;
    if (event) {
      const { signedIn, isSignInEligible, sequence } = event;
      if (
        this.tripSignInResponse !== undefined &&
        this.tripSignInResponse.success &&
        this.tripSignInResponse.tripSignedIn
      ) {
        event.signedIn = true;
        if (
          sequence &&
          sequence.activeFlightLeg &&
          sequence.activeFlightLeg.fltPlanSignature &&
          'confirmedFFD' in sequence.activeFlightLeg.fltPlanSignature
        ) {
          sequence.activeFlightLeg.fltPlanSignature.confirmedFFD = true;
        }
      }
      // Show Eligible for trip button
      responseAsBoolean =
        !signedIn &&
        isSignInEligible &&
        ((sequence?.firstFlightLeg?.legIndex === 1 && !event.isStandby) ||
          event.isStandby);
      if (type === 'title' && responseAsBoolean) {
        title = this.isAAPIMQPI
          ? TripSignInButtonTitle.SignInConfirmFFD
          : TripSignInButtonTitle.SignInTrip;
      }
    }
    return type === 'title' ? title : responseAsBoolean;
  }

  validateTripOnFirstLegSuccess(event: Events) {
    return (
      (event?.sequence?.activeFlightLeg?.legIndex === 1 && event.signedIn) ||
      event?.sequence?.activeFlightLeg?.legIndex !== 1
    );
  }

  /**
   * ! Please don't remove this code. Trips sign in Implementation
   * ! Sign in for trip at HH:mm / hh:mm A
   * @param event
   * @param type
   * @returns
   */
  signInForTrip(event: Events, type: string | boolean): boolean | string {
    const responseAsBoolean =
      !event.signedIn &&
      !event.isSignInEligible &&
      event.signInTime &&
      isCurrentTimeLessThanGivenTime(event.signInTime)
        ? true
        : false;
    return type === 'title' && responseAsBoolean
      ? `Sign in for trip at ${this.formatTime(event.signInTime)}`
      : responseAsBoolean;
  }

  onClickSignFlightButton(event: Events): void {
    this.sabreDocumentService.onClickViewOrSignInFlightPlan(event);
  }

  signFlightPlan(event: Events, type = ''): boolean | string {
    return this.sabreDocumentService.viewOrSignInFlightPlan(event, type);
  }

  formatTime(dateTimeString?: string | null) {
    let formatted = this.timeService.getTime(dateTimeString);
    if (formatted === '') formatted = '--';
    return formatted;
  }

  refresh() {
    this.overviewService.loadDashBoardInfo();
    this.firebaseService.reloadUnacknowledgedONENotifications();
  }
}
