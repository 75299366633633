<div class="lt-sm:h-[92%] flex h-full">
  <div
    *ngIf="!showFullInfo"
    class="border-turbine dark:border-steel border-r p-3"
    [ngClass]="toggleSearchView()">
    <div class="dark:bg-charcoal p-3 dark:text-white">
      <div class="flex items-center justify-start">
        <crew-web-settings-button />
      </div>
      <h2 class="mt-2 text-2xl font-semibold">Search</h2>
    </div>
    <div class="hidden py-2">
      <crew-web-search-menu-card
        [title]="'Flights'"
        (selectedMenu)="onSelectInputRequiredModal($event)"
        [menuList]="flightsMenuList" />
    </div>
    <div class="py-2">
      <crew-web-search-menu-card
        [title]="'Commands'"
        (selectedMenu)="onSelectInputRequiredModal($event)"
        [menuList]="commandMenuList" />
    </div>
  </div>
  <div
    *ngIf="showFullInfo && flightResponse"
    class="gt-md:w-1/4 border-steel w-1/3 border-r p-3">
    <div class="pb-5">
      <div class="mt-3 flex justify-between">
        <button
          class="m-2 font-bold text-blue-500"
          (click)="onToggleFlightModal()">
          <crew-ui-icon name="keyboard_arrow_left" />
        </button>
        <div class="mt-2 text-xl font-semibold dark:text-white">
          Flight {{ flightResponse.legDetailArray[0].fltNumber }}
        </div>
        <button class="m-2 font-bold text-blue-500">
          <crew-ui-icon name="more_horiz" />
        </button>
      </div>
      <div
        class="bg-cirrus dark:bg-charcoal mt-2 h-full w-full rounded-lg p-2 shadow-lg dark:text-white">
        <div class="lt-sm:px-1 flex items-center justify-between px-2">
          <div class="text-nickel font-semibold">
            {{ getFancyFlightDate(flightResponse.legDetailArray[0].gmtLTD) }}
          </div>
          <div class="text-nickel font-semibold">
            {{ getFancyFlightDate(flightResponse.legDetailArray[0].gmtLTA) }}
          </div>
        </div>
        <div class="lt-sm:px-1 flex items-center justify-between px-2">
          <div class="lt-sm:text-3xl text-4xl font-bold">
            {{ flightResponse.legDetailArray[0].depStation }}
          </div>
          <div class="w-full px-5">
            <crew-ui-divider
              [text]="
                getFlyingTime(flightResponse.legDetailArray[0].flyingTime)
              "
              kind="secondary" />
          </div>
          <div class="lt-sm:text-3xl text-4xl font-bold">
            {{ flightResponse.legDetailArray[0].arrStation }}
          </div>
        </div>
        <div class="lt-sm:p-1 flex items-center justify-between px-2">
          <div class="lt-sm:text-xl text-2xl font-bold">
            {{ flightResponse.legDetailArray[0].localSTD.slice(-4, -2) }}:
            {{ flightResponse.legDetailArray[0].localSTD.slice(-2) }}
          </div>
          <div class="lt-sm:text-xl text-2xl font-bold">
            {{ flightResponse.legDetailArray[0].localSTA.slice(-4, -2) }}:
            {{ flightResponse.legDetailArray[0].localSTA.slice(-2) }}
          </div>
        </div>
        <div
          class="lt-sm:p-1 dark:border-nickel flex items-center justify-between p-2 pt-0">
          <div
            class="lt-sm:text-lg text-stratosphere dark:text-troposphere text-2xl font-medium">
            {{ flightResponse.legDetailArray[0].depStatus }}
          </div>
          <div
            class="lt-sm:text-lg text-stratosphere dark:text-troposphere text-2xl font-medium">
            {{ flightResponse.legDetailArray[0].arrGate }}
          </div>
        </div>
      </div>
      <div
        class="bg-cirrus dark:bg-charcoal mt-2 h-full w-full rounded-lg p-2 shadow-lg dark:text-white">
        <div class="mt-3 flex justify-between">
          <div class="text-xl font-semibold">Preflight Docs</div>
        </div>
        <div class="flex h-12 items-center">
          <crew-ui-icon name="file_save" />
          <p class="ml-2">Saved docs</p>
          <div class="ml-auto mt-4">
            <button class="m-2 font-bold">
              <crew-ui-icon name="keyboard_arrow_right" />
            </button>
          </div>
        </div>
        <div class="flex h-12 items-center">
          <crew-ui-icon name="quick_reference_all" />
          <p class="ml-2">All commands</p>
          <div class="ml-auto mt-4">
            <button class="m-2 font-bold">
              <crew-ui-icon name="keyboard_arrow_right" />
            </button>
          </div>
        </div>
      </div>
      <div
        class="bg-cirrus dark:bg-charcoal mt-2 h-full w-full rounded-lg p-2 shadow-lg dark:text-white">
        <div class="mt-3 flex justify-between">
          <div class="text-xl font-semibold">Favorite groups</div>
          <button class="m-2 font-bold text-blue-500">
            <crew-ui-icon name="more_horiz" />
          </button>
        </div>
        <div class="flex h-12 items-center">
          <crew-ui-icon name="folder" />
          <p class="ml-2">FAV</p>
          <div class="ml-auto">
            <button class="m-2 font-bold">
              <crew-ui-icon name="keyboard_arrow_right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex h-full flex-grow items-center justify-center">
    <div
      class="text-nickel dark:text-steel p-10 text-center text-xl"
      *ngIf="!showInputRequiredModal && !showFullInfo">
      Select a Category
    </div>
    <div
      class="lt-sm:w-1/9 my-4 h-full w-full overflow-y-auto p-2"
      *ngIf="showInputRequiredModal">
      <ng-container
        *ngIf="
          !showInputRequiredDetailsModal &&
          !showHICommandDetailsModal &&
          commandTitleList.includes(commandTitle)
        ">
        <crew-web-search-filter-card
          [title]="commandTitle"
          [placeholder]="
            commandTitle === cardTitle.WEBSITES
              ? 'Search websites'
              : 'Search commands'
          "
          [value]="searchInput"
          (clickClose)="onClickCloseBtn(closeButtonType.FilterCard)"
          (searchValue)="searchCommands($event)"></crew-web-search-filter-card>
        <ng-container
          *ngIf="
            commandTitle === cardTitle.HIC ||
            commandTitle === cardTitle.IR ||
            commandTitle === cardTitle.CKPILOT ||
            commandTitle === cardTitle.CKPILOTIR
          ">
          <div class="dark:bg-carbon bg-cirrus gt-md:h-[95%]">
            <crew-web-search-command-list-card
              [commands]="visibleCommands"
              [showIcon]="
                commandTitle === cardTitle.IR ||
                commandTitle === cardTitle.CKPILOT ||
                commandTitle === cardTitle.CKPILOTIR
              "
              [showViewText]="commandTitle === cardTitle.HIC"
              (selectedValue)="
                openCommandDetailsModal($event)
              "></crew-web-search-command-list-card>
          </div>
        </ng-container>
        <ng-container *ngIf="commandTitle === cardTitle.WEBSITES">
          <crew-web-search-command-list-card
            [websites]="websites"
            [showIcon]="true"></crew-web-search-command-list-card>
        </ng-container>
      </ng-container>
      <div
        *ngIf="
          (showWebsitesDetailsModal && !!selectedWebsitesRequiredModal) ||
          (showInputRequiredDetailsModal && !!selectedInputRequiredCommand) ||
          (showHICommandDetailsModal && !!selectedHICommandDetailsModal)
        "
        class="dark:bg-charcoal rounded-xl bg-white p-2 dark:text-white">
        <ng-container
          *ngIf="
            showInputRequiredDetailsModal && !!selectedInputRequiredCommand
          ">
          <div
            class="dark:bg-charcoal flex items-center justify-between rounded-t-xl bg-white py-2">
            <div
              (click)="closeInputRequiredCommandModal()"
              class="flex cursor-pointer items-center justify-start">
              <crew-ui-icon
                [color]="isDark ? colors.Troposphere : colors.Stratosphere"
                name="keyboard_arrow_left" />
              <span class="dark:text-troposphere text-stratosphere">Back</span>
            </div>
            <h2 class="mx-auto text-center font-semibold dark:text-white">
              {{ selectedInputRequiredCommand.commandKeywordDisplay }}
            </h2>
            <button
              class="disabled:text-aluminum dark:text-troposphere text-stratosphere relative right-4"
              (click)="openHICommandDetailsModal(selectedInputRequiredCommand)"
              [disabled]="!isCommandValuePresent()">
              View
            </button>
          </div>
          <div class="dark:bg-charcoal rounded-b-xl bg-white p-4">
            <crew-ui-input
              *ngFor="let param of inputParametersArray"
              ngDefaultControl
              class="my-2 ml-2"
              [value]="param.commandValue"
              [(ngModel)]="param.commandValue"
              [placeholder]="param.commandParameterDesc" />
          </div>
        </ng-container>
        <ng-container
          *ngIf="showHICommandDetailsModal && !!selectedHICommandDetailsModal">
          <crew-web-sabre-document
            (clickClose)="onClickCloseBtn(closeButtonType.SabreClose)"
            *ngIf="hasSabreResponse" />
        </ng-container>
        <!--Websites-->
        <ng-container
          *ngIf="showWebsitesDetailsModal && !!selectedWebsitesRequiredModal">
          <div
            class="dark:bg-charcoal flex items-center justify-between rounded-t-xl bg-white py-2 shadow">
            <button
              class="text-stratosphere dark:text-troposphere relative left-2"
              (click)="closeInputRequiredCommandModal()">
              <crew-ui-icon name="keyboard_arrow_left" />
              Back
            </button>
            <h2 class="mx-auto text-center font-semibold dark:text-white"> </h2>
            <button
              class="disabled:text-aluminum text-stratosphere dark:text-troposphere relative right-4"
              (click)="openHICommandDetailsModal(selectedInputRequiredCommand)"
              [disabled]="!isCommandValuePresent()">
              View
            </button>
          </div>
          <div class="dark:bg-charcoal rounded-b-xl bg-white p-4 shadow-sm">
            <crew-ui-input
              *ngFor="let param of inputParametersArray"
              ngDefaultControl
              class="my-2 ml-2"
              [(ngModel)]="param.commandValue"
              [placeholder]="param.commandParameterDesc" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Search Flights / Flight Details / Etc Below -->
<!-- <div class="w-3/4 p-2" *ngIf="showFullInfo && flightResponse">
        <div
          class="bg-cirrus dark:bg-charcoal mt-2 h-full w-full rounded-lg p-2 shadow-lg dark:text-white">
          <div class="m-2 mt-3 flex justify-between p-2">
            <div class="text-xl font-semibold">Time difference</div>
            <p class="ml-auto">{{
              flightResponse.legDetailArray[0].timeZoneDiff
            }}</p>
            <button class="m-0 font-bold">
              <crew-ui-icon name="expand_less" />
            </button>
          </div>
          <div class="mx-2 flex h-12 items-center p-2">
            <crew-ui-icon name="schedule" />
            <p class="ml-2 font-bold">{{
              flightResponse.legDetailArray[0].depStation
            }}</p>
            <div class="ml-auto">
              <div class="font-bold">
                {{ flightResponse.legDetailArray[0].localSTD.slice(-4, -2) }}:
                {{ flightResponse.legDetailArray[0].localSTD.slice(-2) }}
              </div>
            </div>
          </div>
          <div class="mx-2 flex h-12 items-center p-2">
            <crew-ui-icon name="schedule" />
            <p class="ml-2 font-bold">{{
              flightResponse.legDetailArray[0].arrStation
            }}</p>
            <div class="ml-auto">
              <div class="font-bold"
                >{{ flightResponse.legDetailArray[0].localSTA.slice(-4, -2) }}:
                {{ flightResponse.legDetailArray[0].localSTA.slice(-2) }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-cirrus dark:bg-charcoal mt-2 h-full w-full rounded-lg p-2 shadow-lg dark:text-white">
          <div
            class="border-carbon m-2 mt-3 flex justify-between border-b-4 p-2">
            <div class="text-xl font-semibold">Crew</div>
            <p class="ml-auto">{{ crewInfo.length }} / {{ crewInfo.length }}</p>
            <button
              class="pl-2 font-bold text-blue-500"
              (click)="toggleCrewInfoVisibility()">
              <crew-ui-icon *ngIf="crewInfoVisible" name="keyboard_arrow_up" />
              <crew-ui-icon
                *ngIf="!crewInfoVisible"
                name="expand_circle_down" />
            </button>
          </div>
          <ng-container *ngIf="crewInfoVisible">
            <div class="mt-2">
              <div class="m-2 mt-4 flex items-center justify-start p-2">
                <crew-ui-icon name="description" />
                <div class="ml-3">
                  <h2 class="text-semibold">NS/NST</h2>
                  <p>Crew list with nicknames</p>
                </div>
                <button class="ml-auto text-blue-500">View </button>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          class="bg-cirrus dark:bg-charcoal mt-2 h-full w-full rounded-lg p-2 shadow-lg dark:text-white">
          <div class="m-2 mt-3 flex justify-between p-2">
            <div class="text-xl font-semibold">Aircraft</div>
            <p class="ml-auto">7LF</p>
            <button class="pl-2 font-bold">
              <crew-ui-icon name="keyboard_arrow_up" />
            </button>
          </div>
          <div class="flex">
            <div class="ml-3 mt-4">
              <h2 class="text-aluminum text-xs"> EQUIPMENT </h2>
              <p class="font-semibold">
                {{ flightResponse.legDetailArray[0].eqpType }}
              </p>
            </div>
            <div class="ml-3 mt-4">
              <h2 class="text-aluminum text-xs"> TAIL </h2>
              <p class="font-semibold">
                {{ flightResponse.legDetailArray[0].tailNumber }}
              </p>
            </div>
          </div>
          <div class="border-carbon ml-3 mt-4 border-b-4 pb-4">
            <h2 class="text-aluminum text-xs"> NOSE </h2>
            <p class="font-semibold">
              {{ flightResponse.legDetailArray[0].acType }}
            </p>
          </div>
          <div class="mt-2">
            <div
              class="border-carbon m-2 mt-4 flex items-center justify-start border-b-4 p-2 pb-4">
              <crew-ui-icon name="language" />
              <div class="ml-3">
                <h2 class="font-bold">SAFE eAML</h2>
              </div>
              <button class="ml-auto text-blue-500">
                <crew-ui-icon name="open_in_new" />
              </button>
            </div>
          </div>
          <div class="mt-2">
            <div
              class="border-carbon m-2 mt-4 flex items-center justify-start border-b py-2">
              <crew-ui-icon name="description" />
              <div class="ml-3">
                <h2 class="font-bold">RGA/F</h2>
                <p>Aircraft routing - future</p>
              </div>
              <button class="ml-auto text-blue-500">View </button>
            </div>
            <div class="m-2 mt-4 flex items-center justify-start py-2">
              <crew-ui-icon name="description" />
              <div class="ml-3">
                <h2 class="font-bold">RGMN</h2>
                <p>Aircraft MELS</p>
              </div>
              <button class="ml-auto text-blue-500">View </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="showSearchFlightModal"
    class="fixed left-0 top-0 z-50 h-full w-full">
    <crew-ui-modal>
      <div class="lt-sm:w-96 gt-xs:w-128 h-192">
        <div
          class="dark:bg-charcoal flex items-center justify-between rounded-t-xl bg-white p-4">
          <button
            class="text-stratosphere dark:text-troposphere relative left-2"
            (click)="onToggleFlightModal()">
            Cancel
          </button>
          <h2 class="mx-auto text-center font-semibold dark:text-white">
            Search All Flights
          </h2>
          <button
            class="text-stratosphere dark:text-troposphere relative right-2"
            *ngIf="showFullInfo"
            (click)="showSearchFlightInput()">
            Back
          </button>
          <button
            class="text-stratosphere dark:text-troposphere relative right-2"
            *ngIf="!showFullInfo"
            [disabled]="!isFlightDataPresent()"
            (click)="searchFlight()">
            Search
          </button>
        </div>
        <div class="" *ngIf="!showFullInfo">
          <div class="dark:bg-charcoal rounded-b-xl bg-white p-4 shadow-sm">
            <crew-ui-input
              ngDefaultControl
              class="my-2 ml-2"
              [(ngModel)]="flightNumber"
              [isUpperCase]="true"
              placeholder="Flight Number" />
            <input
              datepicker
              class="dark:bg-charcoal text-carbon focus:ring-stratosphere dark:ring-aluminum ring-turbine z-50 m-0 my-2 mt-4 block h-14 w-full rounded-lg p-4 pr-10 text-base ring-1 transition ease-in-out dark:bg-opacity-0 dark:text-white"
              [(ngModel)]="displayDate"
              placeholder="Flight Date" />
            <crew-ui-input
              ngDefaultControl
              class="my-2 ml-2"
              [(ngModel)]="depAirport"
              [isUpperCase]="true"
              placeholder="Departure Airport" />
            <crew-ui-input
              ngDefaultControl
              class="my-2 ml-2"
              [(ngModel)]="arrAirport"
              [isUpperCase]="true"
              placeholder="Arrival Airport" />
          </div>
          <div
            *ngIf="flightResponse"
            (click)="showCompleteFlightInfo()"
            class="bg-cirrus dark:bg-steel mt-2 h-full w-full cursor-pointer rounded-lg p-2 shadow-lg dark:text-white">
            <div class="text-center">
              <span
                class="dark:bg-steel bg-cirrus text-stratosphere dark:text-troposphere mx-auto rounded-xl px-2 py-1 font-medium"
                >{{ flightResponse.legDetailArray[0].fltNumber }}</span
              >
            </div>
            <div class="lt-sm:px-1 flex items-center justify-between px-2">
              <div class="text-nickel font-semibold"
                >{{
                  getFancyFlightDate(flightResponse.legDetailArray[0].gmtLTD)
                }}
              </div>
              <div class="text-nickel font-semibold">{{
                getFancyFlightDate(flightResponse.legDetailArray[0].gmtLTA)
              }}</div>
            </div>
            <div class="lt-sm:px-1 flex items-center justify-between px-2">
              <div class="lt-sm:text-3xl text-4xl font-bold">{{
                flightResponse.legDetailArray[0].depStation
              }}</div>
              <div class="w-full px-5">
                <crew-ui-divider
                  [text]="
                    getFlyingTime(flightResponse.legDetailArray[0].flyingTime)
                  "
                  kind="secondary" />
              </div>
              <div class="lt-sm:text-3xl text-4xl font-bold">{{
                flightResponse.legDetailArray[0].arrStation
              }}</div>
            </div>
            <div
              class="lt-sm:p-1 dark:border-nickel flex items-center justify-between border-b p-2 pt-0">
              <div
                class="lt-sm:text-lg text-stratosphere dark:text-troposphere text-2xl font-medium">
                {{ flightResponse.legDetailArray[0].depStatus }}
              </div>
              <div
                class="lt-sm:text-lg text-stratosphere dark:text-troposphere text-2xl font-medium">
                {{ flightResponse.legDetailArray[0].arrGate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </crew-ui-modal>
  </div>
</div> -->
