import { environment } from '../../../../environments/environment';
import {
  CrewTripSignInRequest,
  CrewTripSignInResponse,
  GetFlightPaxCountRequest,
  GetFlightPaxCountResponse,
  GetSabreCommandsRequest,
  GetSabreCommandsResponse,
  HiSendServiceRequest,
  HiSendServiceResponse,
  SendFlightPlanSignRequestV1,
  SendFlightPlanSignRequestV2,
  SendFlightPlanSignResponseV1,
  SendFlightPlanSignResponseV2,
} from './sabre.service.types';
import { HttpService } from '../http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SabreService {
  constructor(private httpService: HttpService) {}

  private readonly base = `${environment.azure.services.url}/sabre`;

  private readonly baseSabreRes = `${environment.azure.services.url}/ccisabreres`;

  getFlightPaxCount(body: GetFlightPaxCountRequest, bypassLoader = false) {
    const url = `${this.baseSabreRes}/getFlightPaxCount`;
    return this.httpService.post<
      GetFlightPaxCountRequest,
      GetFlightPaxCountResponse
    >(url, body, bypassLoader);
  }

  getResSabreCommands(body: GetSabreCommandsRequest, bypassLoader = false) {
    const url = `${this.baseSabreRes}/getResSabreCommands`;
    return this.httpService.post<
      GetSabreCommandsRequest,
      GetSabreCommandsResponse
    >(url, body, bypassLoader);
  }

  getSabreCommands(body: GetSabreCommandsRequest, bypassLoader = false) {
    const url = `${this.base}/getSabreCommands`;
    return this.httpService.post<
      GetSabreCommandsRequest,
      GetSabreCommandsResponse
    >(url, body, bypassLoader);
  }

  hiSendService(body: HiSendServiceRequest, bypassLoader = false) {
    const url = `${this.base}/hiSendService`;
    return this.httpService.post<HiSendServiceRequest, HiSendServiceResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  crewTripSignIn(body: CrewTripSignInRequest, bypassLoader = false) {
    const url = `${this.base}/v1/crewTripSignIn`;
    return this.httpService.post<CrewTripSignInRequest, CrewTripSignInResponse>(
      url,
      body,
      bypassLoader,
    );
  }

  sendFlightPlanSignV1(
    body: SendFlightPlanSignRequestV1,
    bypassLoader = false,
  ) {
    const url = `${this.base}/v1/sendFlightPlanSign`;
    return this.httpService.post<
      SendFlightPlanSignRequestV1,
      SendFlightPlanSignResponseV1
    >(url, body, bypassLoader);
  }

  sendFlightPlanSignV2(
    body: SendFlightPlanSignRequestV2,
    bypassLoader = false,
  ) {
    const url = `${this.base}/v2/sendFlightPlanSign`;
    return this.httpService.post<
      SendFlightPlanSignRequestV2,
      SendFlightPlanSignResponseV2
    >(url, body, bypassLoader);
  }
}
