import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrewUICardComponent } from '../crew-ui-card/crew-ui-card.component';

@Component({
  selector: 'crew-ui-pillbox',
  standalone: true,
  imports: [CommonModule, CrewUICardComponent],
  templateUrl: './crew-ui-pillbox.component.html',
  styleUrls: ['./crew-ui-pillbox.component.css'],
})
export class CrewUIPillboxComponent {
  @Input()
  text = '';

  @Input()
  value = '';
}
