import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'crew-ui-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './crew-ui-button.component.html',
  styleUrls: ['./crew-ui-button.component.css'],
})
export class CrewUIButtonComponent {
  @Input()
  text = '';

  @Input()
  type: 'button' | 'reset' | 'submit' | 'menu' = 'button';

  @Input()
  kind: 'primary' | 'secondary' | 'tertiary' | 'quaternary' = 'primary';

  @Input()
  disabled = false;

  get buildTheme(): string[] {
    const common = [
      'border-0',
      'disabled:bg-charcoal',
      'disabled:bg-stratus',
      'disabled:pointer-none',
      'disabled:text-aluminum',
      'inline-flex',
      'items-center',
      'justify-center',
      'px-2',
      'py-2',
      'rounded-lg',
      'truncate',
    ];

    const primary = [
      ...common,
      ...[
        'bg-stratosphere',
        'dark:bg-troposphere',
        'dark:hover:outline-troposphere',
        'hover:outline',
        'hover:outline-offset-1',
        'hover:outline-solid',
        'hover:outline-stratosphere',
        'pointer-clickable',
        'text-white',
      ],
    ];

    const secondary = [
      ...common,
      ...[
        'bg-ozone',
        'dark:bg-steel',
        'dark:hover:outline-troposphere',
        'dark:text-white',
        'hover:outline-stratosphere',
        'hover:outline',
        'hover:outline-offset-1',
        'pointer-clickable',
        'text-stratosphere',
      ],
    ];

    const tertiary = [
      ...common,
      ...[
        'bg-white',
        'dark:bg-opacity-0',
        'dark:hover:bg-charcoal',
        'dark:hover:bg-opacity-50',
        'dark:hover:outline-troposphere',
        'dark:outline-nickel',
        'dark:text-troposphere',
        'disabled:outline-aluminum',
        'hover:bg-cirrus',
        'outline-stratosphere',
        'outline',
        'pointer-clickable',
        'text-stratosphere',
      ],
    ];

    const quaternary = [
      ...common,
      ...[
        'bg-white',
        'dark:bg-charcoal',
        'dark:bg-opacity-0',
        'dark:hover:bg-charcoal',
        'dark:hover:bg-opacity-0',
        'dark:hover:outline-troposphere',
        'dark:text-troposphere',
        'hover:bg-cirrus',
        'hover:outline-stratosphere',
        'hover:outline',
        'pointer-clickable',
        'text-stratosphere',
      ],
    ];

    switch (this.kind) {
      case 'primary':
        return primary;
      case 'secondary':
        return secondary;
      case 'quaternary':
        return quaternary;
      case 'tertiary':
        return tertiary;
      default:
        return primary;
    }
  }
}
