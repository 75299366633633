import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Colors } from '../../colors';
import { CrewUIIconComponent } from '../crew-ui-icon/crew-ui-icon.component';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'crew-ui-input',
  standalone: true,
  imports: [CommonModule, CrewUIIconComponent, FormsModule],
  templateUrl: './crew-ui-input.component.html',
  styleUrls: ['./crew-ui-input.component.css'],
})
export class CrewUIInputComponent {
  @Input()
  assistiveLabel?: string;

  @Input()
  iconColor: Colors = Colors.Carbon;

  @Input()
  isFocused?: boolean;

  @Output()
  isFocusedChange = new EventEmitter<boolean>();

  @Input()
  isInvalid = false;

  @Input()
  isUpperCase = false;

  @Input()
  label?: string = '';

  @Input()
  placeholder?: string = '';

  @Input()
  type: 'text' | 'email' | 'password' | 'tel' | 'search' | 'url' | 'number' =
    'text';

  @Input()
  value = '';

  @Output()
  valueChange = new EventEmitter<string>();

  onBlur() {
    this.isFocused = false;
    this.isFocusedChange.emit(this.isFocused);
  }

  onCancel() {
    this.value = '';
    this.valueChange.emit(this.value);
  }

  onFocus() {
    this.isFocused = true;
    this.isFocusedChange.emit(this.isFocused);
  }
}
