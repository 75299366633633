import { Component } from '@angular/core';
import { FullMessageData } from '../../../../../shared/services/azure/message.service.types';
import { Colors, CrewUIDarkModeService } from '@cci-web/crew-ui';
import { MessageService } from '../message.service';
import { TimeService } from './../../../../../shared/services/time.service';
import { MessageIdbService } from '../../../../../shared/services/idb/message.idb.service';
@Component({
  selector: 'crew-web-message-short-view',
  templateUrl: './message-short-view.component.html',
  styleUrls: [
    './message-short-view.component.scss',
    './../../../../../shared/styles/scroll-bar.scss',
  ],
})
export class MessageShortViewComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private messagesIdbService: MessageIdbService,
    private messageService: MessageService,
    private timeService: TimeService,
  ) {}

  get selectedId() {
    if (this.messageService.selectedMessage()?.messageId)
      return this.messageService.selectedMessage()?.messageId;
    else return -1;
  }

  colors = Colors;

  showSearchBar = false;

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  get messages() {
    return this.messageService.getSelectedMenuMessages();
  }

  get title() {
    return this.messageService.selectedTitle();
  }

  search(input: string) {
    if (input === '') this.messageService.clearSearch();
    else this.messageService.search(input);
  }

  toggleSearch() {
    this.showSearchBar = !this.showSearchBar;
    if (!this.showSearchBar) {
      this.messageService.clearSearch();
    }
  }

  goBack() {
    this.messageService.selectedMenu.set(undefined);
    this.messageService.selectedMessage.set(undefined);
    this.messageService.clearSearch();
  }

  onSelectMessage(message: FullMessageData) {
    if (!message.isHi6Message) {
      this.messagesIdbService.markAsRead(message).subscribe();
      this.messagesIdbService.populateMessageBody(message);
    } else if (message.isHi6Message) {
      this.messageService.setSelectedMessage(message);
    }
  }

  showDateTime(value: string): string {
    return this.timeService.getMessageDateTimeShort(value);
  }
}
