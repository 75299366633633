import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Colors } from '../../colors';
@Component({
  selector: 'crew-ui-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './crew-ui-icon.component.html',
  styleUrls: ['./crew-ui-icon.component.css'],
})
export class CrewUIIconComponent {
  get buildTheme(): string[] {
    return [
      'material-symbols-outlined',
      `text-${this.color}`,
      'text-center',
      'align-middle',
    ];
  }

  @Input()
  name = '';

  @Input()
  color?: Colors;
}
