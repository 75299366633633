import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CREW_ROUTES } from './routes/routes';
import { ReadAndAcknowledgeComponent } from './routes/read-and-acknowledge/read-and-acknowledge.component';
import { CustomPingAuthGuard } from './shared/guards/custom-ping-auth.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: CREW_ROUTES.crew.root,
    pathMatch: 'full',
  },
  {
    canActivate: [CustomPingAuthGuard],
    path: CREW_ROUTES.crew.root,
    loadChildren: () =>
      import('./routes/crew/crew.module').then((c) => c.CrewModule),
  },
  {
    path: 'read-and-acknowledge',
    canActivate: [CustomPingAuthGuard],
    component: ReadAndAcknowledgeComponent,
  },
  { path: '**', redirectTo: CREW_ROUTES.crew.root },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
