import { Injectable, signal } from '@angular/core';
import * as moment from 'moment';
import { formatDate } from './../utils/date.utils';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  constructor() {
    this.changeMeridiemValue();
    this.isTimeFormat24.set(this.loadLocalStoragePreference());
  }

  private readonly isTimeFormat24Default = true;

  private readonly localStorageKey = 'prefers-24';

  timeFormat24 = `HH:mm`;

  timeFormat12 = `hh:mm A`;

  dateFormat = `YYYY-MM-DD`;

  isTimeFormat24 = signal(this.isTimeFormat24Default);

  toggle(isTimeFormat24: boolean) {
    this.isTimeFormat24.set(isTimeFormat24);
    this.saveLocalStoragePreference(isTimeFormat24);
  }

  /**
   * In 12hours forma, changed meridiem text values
   * AM to A,
   * PM to P
   */
  private changeMeridiemValue() {
    moment.updateLocale('en', {
      meridiem: function (hour, minute, isLowercase) {
        if (hour >= 12) return isLowercase ? 'p' : 'P';
        else return isLowercase ? 'a' : 'A';
      },
    });
  }

  /**
   * Input eg: 2023-11-02 10:56:00.0
   * Output eg: 10:56 / 12:01
   * @param value - 2023-11-02
   * @param format - HH:mm/ hh:mm A
   * @returns - eg: 15:56 / 12:01 PM
   */
  getTime(value?: string | Date | null): string {
    if (!value) return '';
    return moment(value).format(
      this.isTimeFormat24() ? this.timeFormat24 : this.timeFormat12,
    );
  }

  getCurrentDateTime(value: string | Date, format: string) {
    return moment(value).format(
      this.isTimeFormat24()
        ? `${format} ${this.timeFormat24}`
        : `${format} ${this.timeFormat12}`,
    );
  }

  /**
   *
   * @param value - 2023-11-02 10:56:00.0 / new Date()
   * @returns - 2015-06-17 14:24 / 2015-06-17 02:24 AM
   */
  getDateTime(value: string | Date): string {
    return moment(value).format(
      this.isTimeFormat24()
        ? `${this.dateFormat} ${this.timeFormat24}`
        : `${this.dateFormat} ${this.timeFormat12}`,
    );
  }

  /**
   * Input eg: 2023-11-02 10:56:00.0
   * Output eg: 42m ago
   * @param value - Eg. 2023-11-02 10:56:00.0
   * @param format - short/long
   * @returns - Eg: 42m ago
   */
  timeSince(value: string | Date, format = 'short'): string {
    const intervals = [
      { short: 'y', long: 'year', seconds: 31536000 },
      { short: 'm', long: 'month', seconds: 2592000 },
      { short: 'd', long: 'day', seconds: 86400 },
      { short: 'h', long: 'hour', seconds: 3600 },
      { short: 'm', long: 'minute', seconds: 60 },
      { short: 's', long: 'second', seconds: 1 },
    ];
    const date = new Date(value);
    const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
    const interval = intervals.find((i) => i.seconds < seconds);
    if (interval) {
      const count = Math.floor(seconds / interval.seconds);
      const label = format === 'short' ? interval.short : interval.long;
      return `${count}${label}${count !== 1 ? 's' : ''} ago`;
    }
    return ``;
  }

  /**
   * Get the date time in a fancy format like
   * @param date - Eg. 2023-11-02 10:56:00.0
   * @returns - Eg: 42m ago / Yesterday at 02:00 PM / 03/03/23 10:56
   */
  getFancyFormat(date?: Date): string {
    const input = date ? new Date(date) : new Date();

    const tomorrow = new Date(),
      yesterday = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);
    yesterday.setDate(yesterday.getDate() - 1);

    switch (input.toDateString()) {
      case yesterday.toDateString():
        return `Yesterday`;
      case date?.toDateString():
        // return this.timeSince(input);
        return this.getDateWithDifferentScenarios(input);
      case tomorrow.toDateString():
        return `Tomorrow`;
      default:
        return `${formatDate(input, 'MM/DD/YY')} at ${this.getTime(input)}`;
    }
  }

  /**
   * if today - display the time
   * if date between a week - display the day in `dddd` format
   * else display the date in `MM/DD/YY` format
   * @param input
   * @param response
   * @returns
   */
  getDateWithDifferentScenarios(input: Date) {
    const today = new Date(),
      lastWeekStartDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    let response = `${formatDate(input, 'MM/DD/YY')}`;
    if (
      input &&
      formatDate(input, 'MM/DD/YY') === formatDate(today, 'MM/DD/YY')
    ) {
      response = `${this.getTime(input)}`;
    } else if (
      input &&
      formatDate(input, 'MM/DD/YY') >
        formatDate(lastWeekStartDate, 'MM/DD/YY') &&
      formatDate(input, 'MM/DD/YY') < formatDate(today, 'MM/DD/YY')
    ) {
      response = `${formatDate(input, 'dddd')}`;
    }
    return response;
  }

  /**
   * Eg. 2024-03-20T14:25:00.000+00:00
   * Remove .000+00:00 to show exact time matches backend value with new Date()
   * @param value
   */
  unsetTimeZone(value: string, format = '.000+00:00'): string {
    return value.split(format).join('');
  }

  /**
   *
   * @param value - 2023-11-02 10:56:00.0 / new Date()
   * @returns - 2015-02-11
   */
  getLocalDate(value: string | Date): string {
    return moment(value).format(`${this.dateFormat}`);
  }

  /**
   *
   * @param start - "2024-02-26T07:30:00"
   * @param end - "2024-02-26T10:10:00"
   * @returns - "2H 40M"
   */
  findTimeDifference(start: string, end: string): string {
    const startTime = new Date(start);
    const endTime = new Date(end);

    // Calculate the difference in milliseconds
    let difference = endTime.getTime() - startTime.getTime();

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(difference / (1000 * 60));

    return `${hours}h ${minutes}m`;
  }

  /**
   * This function is used to display the message in detail format in the following components
   * 1. cmessgage-detail-view.component
   * 2. message-card.component
   * @param date
   * @param format
   * @returns
   */
  getMessageDateTimeLong(date?: string, format = `MM/DD/YYYY`): string {
    let response = ``;
    if (date) {
      date = this.unsetTimeZone(date);
      response = `${formatDate(date, format)} at ${this.getTime(date)}`;
    }
    return response;
  }

  /**
   * This function is used to display the message in detail format in the following components
   * 1. message-short-view.component
   * @param date
   * @returns
   */
  getMessageDateTimeShort(date: string) {
    return this.getFancyFormat(new Date(this.unsetTimeZone(date)));
  }

  private loadLocalStoragePreference(): boolean {
    const preference = localStorage.getItem(this.localStorageKey);
    const result = preference
      ? preference === 'true'
      : this.isTimeFormat24Default;
    return result;
  }

  private saveLocalStoragePreference(preference: boolean): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(preference));
  }
}
