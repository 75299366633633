<crew-ui-card>
  <div class="pill-min-width flex items-center px-4 py-5">
    <div
      class="text-carbon flex-1 truncate text-lg font-medium dark:text-white">
      {{ text }}
    </div>
    <div
      class="text-carbon flex flex-1 justify-end truncate font-medium dark:text-white">
      {{ value }}
    </div>
  </div>
</crew-ui-card>
