import { Component } from '@angular/core';
import { CalendarService } from '../calendar.service';
import { formatDate } from '../../../../../shared/utils/date.utils';
import { CalendarEvent, SequenceInfo } from './../calendar.types';
import { GridHeightOperationType } from '../../../../../shared/types';
import { EventType } from '../../../../../shared/services/azure/calendar.service.types';
@Component({
  selector: 'crew-web-calendar-event-details',
  templateUrl: './calendar-event-details.component.html',
  styleUrls: ['./calendar-event-details.component.scss'],
})
export class CalendarEventDetailsComponent {
  get selectedEvent() {
    return this.calendarService.selectedEvent();
  }

  get calendarSelectedDate() {
    return this.calendarService.calendarSelectedDate();
  }

  readonly gridHeightOperationType = GridHeightOperationType;

  readonly eventType = EventType;

  isEventVisible = false;

  constructor(private calendarService: CalendarService) {}

  showDate(date: string | null): string {
    const format = 'dddd, MMM D';
    return date ? formatDate(date, format) : formatDate(new Date(), format);
  }

  showEventDate(selectedEvent: CalendarEvent): string {
    return selectedEvent && selectedEvent.day
      ? this.showDate(selectedEvent.day)
      : this.showDate(null);
  }

  toggleEventData(): void {
    this.isEventVisible = !this.isEventVisible;
  }

  showFlightLegCount(selectedEvent: CalendarEvent): string {
    let legCount = 0;
    if (
      selectedEvent &&
      selectedEvent.eventData &&
      selectedEvent.eventData.length > 0
    ) {
      selectedEvent.eventData.filter((event: SequenceInfo) => {
        legCount += event.legsCount;
      });
    }
    return legCount > 0 ? `${legCount} legs` : ``;
  }
}
