<div
  class="hover:border-cirrus dark:hover:border-charcoal dark:border-carbon dark:bg-carbon border-cirrus z-50 h-12 w-full border-t bg-white">
  <div class="mx-auto grid h-full grid-cols-4 font-medium">
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.crew.overview)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.crew.overview))]"
      class="xs:flex-col hover:bg-cirrus dark:hover:bg-charcoal group inline-flex items-center justify-center px-5 sm:flex-row">
      <crew-ui-icon name="where_to_vote" />
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.crew.overview))]"
        class="xs:text-[12px] xs:leading-[12px] xs:font-medium px-1 pb-1 pt-1 sm:text-[15px] sm:font-normal sm:leading-[18px]">
        Overview
      </span>
    </button>
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.crew.calendar)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.crew.calendar))]"
      class="xs:flex-col hover:bg-cirrus dark:hover:bg-charcoal group inline-flex items-center justify-center px-5 sm:flex-row">
      <crew-ui-icon name="calendar_month" />
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.crew.calendar))]"
        class="xs:text-[12px] xs:leading-[12px] xs:font-medium px-1 pb-1 pt-1 sm:text-[15px] sm:font-normal sm:leading-[18px]">
        Schedule
      </span>
    </button>
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.crew.message)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.crew.message))]"
      class="xs:flex-col hover:bg-cirrus dark:hover:bg-charcoal group inline-flex items-center justify-center px-5 sm:flex-row">
      <div class="relative">
        <crew-ui-icon name="chat" />
        <div
          class="bg-afterburner absolute -right-2 -top-2 flex h-[18px] w-[18px] items-center justify-center rounded-full p-[0.2rem] text-center text-[0.6rem] text-white"
          *ngIf="messageCount + hi6Count > 0">
          {{ messageCount + hi6Count > 99 ? '99+' : messageCount + hi6Count }}
        </div>
      </div>
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.crew.message))]"
        class="xs:text-[12px] xs:leading-[12px] xs:font-medium px-1 pb-1 pt-1 sm:text-[15px] sm:font-normal sm:leading-[18px]">
        Messages
      </span>
    </button>
    <button
      (click)="closeHSS()"
      [routerLink]="[getCrewPath(routes.crew.search)]"
      [ngClass]="[buildIconText(!isActiveRoute(routes.crew.search))]"
      class="xs:flex-col hover:bg-cirrus dark:hover:bg-charcoal group inline-flex items-center justify-center px-5 sm:flex-row">
      <crew-ui-icon name="more_horiz" />
      <span
        [ngClass]="[buildIconText(!isActiveRoute(routes.crew.search))]"
        class="xs:text-[12px] xs:leading-[12px] xs:font-medium px-1 pb-1 pt-1 sm:text-[15px] sm:font-normal sm:leading-[18px]">
        More
      </span>
    </button>
  </div>
</div>
