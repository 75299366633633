import { environment } from '../../../../environments/environment';
import {
  GetDashboardInfoRequest,
  GetDashboardInfoResponse,
  GetFlightDetailsRequest,
  GetFlightDetailsResponse,
  GetSequenceDetailsRequest,
  GetSequenceDetailsResponse,
  SearchFlightDetailsRequest,
  SearchFlightDetailsResponse,
} from './schedule.service.types';
import { HttpService } from '../http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  constructor(private httpService: HttpService) {}

  private readonly base = `${environment.azure.services.url}/schedule/v1`;

  getDashBoardInfo(body: GetDashboardInfoRequest, bypassLoader = false) {
    const url = `${this.base}/getDashBoardInfo`;
    return this.httpService.post<
      GetDashboardInfoRequest,
      GetDashboardInfoResponse
    >(url, body, bypassLoader);
  }

  getFlightDetails(body: GetFlightDetailsRequest, bypassLoader = false) {
    const url = `${this.base}/getFlightDetails`;
    return this.httpService.post<
      GetFlightDetailsRequest,
      GetFlightDetailsResponse
    >(url, body, bypassLoader);
  }

  getSequenceDetails(body: GetSequenceDetailsRequest, bypassLoader = false) {
    const url = `${this.base}/getSequenceDetails`;
    return this.httpService.post<
      GetSequenceDetailsRequest,
      GetSequenceDetailsResponse
    >(url, body, bypassLoader);
  }

  searchFlightDetails(body: SearchFlightDetailsRequest, bypassLoader = false) {
    const url = `${this.base}/CrewService/searchFlights`;
    return this.httpService.post<
      SearchFlightDetailsRequest,
      SearchFlightDetailsResponse
    >(url, body, bypassLoader);
  }
}
