import { Component, Input, OnInit } from '@angular/core';
import { FlightLeg } from '../../../../../../shared/services/azure/schedule.service.types';
import { UserService } from '../../../../../../shared/services/user.service';
import { BusinessUnit } from '../../../../../../shared/types';
import * as moment from 'moment';
import { SabreService } from '../../../../../../shared/services/azure/sabre.service';
import { GetFlightPaxCountRequest } from '../../../../../../shared/services/azure/sabre.service.types';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'crew-web-seat-map',
  templateUrl: './seat-map.component.html',
  styleUrls: ['./seat-map.component.css'],
})
export class SeatMapComponent implements OnInit {
  constructor(
    private sabreService: SabreService,
    private userService: UserService,
  ) {}

  @Input({ required: true })
  leg!: FlightLeg;

  values: { text: string; link: string }[] = [];

  ngOnInit(): void {
    const payload: GetFlightPaxCountRequest = {
      businessUnit:
        this.userService.emulatedOrDefaultBusinessUnit() ?? BusinessUnit.AAFA,
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      siteMinderEmpId: this.userService.employeeNumber(),
      appSessionId: this.userService.appSession(),
      employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      flightRequestFlightData: {
        depDupCode: this.leg.departureDuplicateCode,
        depStation: this.leg.departureStation,
        fltNumber: this.leg.flightNumber,
        fltOrgDate: this.leg.flightOriginationDate,
        arrStation: this.leg.arrivalStation,
      },
    };

    this.sabreService.getFlightPaxCount(payload, true).subscribe((x) => {
      if (!x.success) return;
      x.paxCounts
        .filter((c) => c.clazz === 'F' || c.clazz === 'C')
        .forEach((c) => {
          this.values.push({
            text: c.clazz,
            link: this.getSeatMapUrl(this.leg, c.clazz),
          });
        });
    });
  }

  formatDateToFlightDate(flightDate: Date): string {
    return moment(flightDate).format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Eg. ?airlineCode=AA&arrivalStation=MCO&departureStation=CLT&flightDate=2024-03-12&flightNo=2406&flightType=321K&flightClass=C
   */
  getSeatMapUrl(leg: FlightLeg, flightClass: string): string {
    return `${environment.seatMapUrl}?airlineCode=${leg.airlineCode}&arrivalStation=${leg.arrivalStation}&departureStation=${leg.departureStation}&flightDate=${leg.flightOriginationDate}&flightNo=${leg.flightNumber}&flightType=${leg.equipmentQuals.equipmentType}&flightClass=${flightClass}`;
  }
}
