import { CalendarOptions, ToggleButtonOption } from './calendar.types';
import {
  EventType,
  EventCode,
} from '../../../../shared/services/azure/calendar.service.types';
/**
 * Show it in the `calendar-menu.component.ts`
 */
export const toggleButtonOptions: ToggleButtonOption[] = [
  {
    name: `Sequence, SA, Standby`,
    type: EventType.S,
    code: [],
    color: `dark:bg-aluminum bg-midnight`,
    checked: false,
  },
  {
    name: `DFP, Golden day, Flex day`,
    type: EventType.D,
    code: [],
    color: `dark:bg-tangerine bg-nectarine`,
    checked: false,
  },
  {
    name: `RAP`,
    type: EventType.RAP,
    code: [],
    color: `dark:bg-troposphere bg-stratosphere`,
    checked: false,
  },
  {
    name: `Vacation`,
    type: EventType.VC,
    code: [],
    color: `bg-monarch`,
    checked: false,
  },
  {
    name: `Other activity`,
    type: EventType.A,
    code: [],
    color: `dark:bg-sushi bg-afterburner`,
    checked: false,
  },
  {
    name: 'Training',
    type: EventType.A,
    code: [EventCode.TwoR, EventCode.TI],
    color: `dark:bg-calathea bg-zanzibar`,
    checked: false,
  },
];

export const calendarOptions: CalendarOptions = {
  showSidePanel: true,
  showEvents: false,
  showPopover: false,
  crewInfoVisible: true,
  showCrewInfoSidePanel: false,
};

export enum CalendarCode {
  PREVIOUSMONTH = 'previousmonth',
  NEXTMONTH = 'nextmonth',
  PREVIOUSDAY = 'previousday',
  NEXTDAY = 'nextday',
  TODAY = 'today',
}
