import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Type } from '@angular/compiler';
import { Colors, CrewUIToastService } from '@cci-web/crew-ui';
import { Injectable } from '@angular/core';
import { TokenService } from '../services/token.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastService: CrewUIToastService,
    private tokenService: TokenService,
  ) {}

  intercept(
    request: HttpRequest<Type>,
    next: HttpHandler,
  ): Observable<HttpEvent<Type>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage =
            'server returned code :' + error.status + 'with body' + error.error;
        } else {
          if (error.status === 500) {
            errorMessage =
              'server returned code :' +
              error.status +
              '\nMessage:' +
              error.message;
          }
          if (error.status === 400) {
            errorMessage = error.error?.errorMessage;
          }
          if (error.status === 401) {
            // ! FIX THIS
            // this.tokenService.logOut();
          } else {
            errorMessage =
              'There was an error, please wait a moment before trying again.';
          }
        }
        return throwError(() => {
          this.toastService.showToast({
            message:
              errorMessage ?? 'Could not determine error, please try again.',
            textColor: Colors.Afterburner,
            backgroundColor: Colors.Charcoal,
          });
        });
      }),
    );
  }
}
