import { Component, Input } from '@angular/core';
import { sanitizeText } from '../../utils/string.utils';
@Component({
  selector: 'crew-web-safe-html',
  templateUrl: './safe-html.component.html',
  styleUrls: ['./safe-html.component.scss'],
})
export class SafeHtmlComponent {
  @Input() content: string | undefined;

  sanitizeSabreResponse(data: string | undefined) {
    let response = ``;
    if (data) {
      response = sanitizeText(data, false, false);
    }
    return response;
  }
}
