<div class="flex h-full">
  <div [ngClass]="buildMenuViewClasses()" class="h-full">
    <crew-web-message-menu
      *ngIf="
        !selectedPriority ||
        (selectedPriority && selectedPriority === messagePriorities.HI6)
      " />
    <crew-web-message-short-view
      *ngIf="selectedPriority && selectedPriority !== messagePriorities.HI6" />
  </div>
  <div
    class="border-turbine dark:border-steel h-full border-l"
    *ngIf="
      selectedMessageAndBody ||
      (selectedPriority && selectedPriority === messagePriorities.HI6)
    "
    [ngClass]="buildDetailViewClasses()">
    <crew-web-message-detail-view />
  </div>
  <crew-web-message-modal></crew-web-message-modal>
</div>
