import { Injectable, WritableSignal, signal } from '@angular/core';
import { CoreService } from '../../shared/services/azure/core.service';
import {
  GetTripSignInInfoV3Request,
  GetTripSignInInfoV3Response,
  V3TripSignInData,
} from '../../shared/services/azure/core.service.types';
import { UserService } from '../../shared/services/user.service';
import { formatDate } from '../../shared/utils/date.utils';
import { TripSignInButtonTitle } from '../../shared/types';
@Injectable({
  providedIn: 'root',
})
export class ReadAndAcknowledgeService {
  constructor(
    private coreService: CoreService,
    private userService: UserService,
  ) {}

  tripSignInInfoV3Response: WritableSignal<V3TripSignInData> = signal(
    {} as V3TripSignInData,
  );

  setTripSignInInfoV3Response(data: V3TripSignInData) {
    this.tripSignInInfoV3Response.set(data);
  }

  callGetTripSignInInfoV3() {
    const payload: GetTripSignInInfoV3Request = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      siteMinderEmpId: this.userService.employeeNumber(),
      appSessionId: this.userService.appSession(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
    };
    this.coreService.getTripSignInInfoV3(payload).subscribe({
      next: (response: GetTripSignInInfoV3Response) => {
        if (response && typeof response.tripSignInData === 'object') {
          this.setTripSignInInfoV3Response(response.tripSignInData);
        } else {
          this.setTripSignInInfoV3Response({} as V3TripSignInData);
        }
      },
      error: (e) => {
        console.error(e);
        this.setTripSignInInfoV3Response({} as V3TripSignInData);
      },
    });
  }

  getTimeFromString(tripSignInInfoV3Response: V3TripSignInData) {
    let time = ``;
    if (tripSignInInfoV3Response?.signInLocalTime) {
      const signInLocalTime =
        tripSignInInfoV3Response?.signInLocalTime.split('T');
      if (signInLocalTime && signInLocalTime.length > 0) {
        const localTime = signInLocalTime[signInLocalTime.length - 1];
        time = `${localTime.substring(0, 2)}:${localTime.substring(
          localTime.length - 2,
          localTime.length,
        )}`;
      }
    }
    return time;
  }

  showReminderMessage(tripSignInInfoV3Response: V3TripSignInData): string {
    return `Trip reminder for ${tripSignInInfoV3Response?.departureStation} ${
      tripSignInInfoV3Response?.sequenceNumber
    }/${formatDate(
      tripSignInInfoV3Response?.flightOriginDate,
      'DDMMM',
    ).toLocaleUpperCase()}. Sign in at ${this.getTimeFromString(
      tripSignInInfoV3Response,
    )} local for flight ${tripSignInInfoV3Response?.flightNumber}/${formatDate(
      tripSignInInfoV3Response?.flightOriginDate,
      'DDMMM',
    ).toLocaleUpperCase()}.`;
  }

  showTitleInReadAckPage(): string {
    return this.userService.isAAPIMQPI()
      ? TripSignInButtonTitle.SignInTripConfirmFFD
      : TripSignInButtonTitle.SignInTrip;
  }
}
