<button
  *ngIf="showPreviousNextButton"
  type="button"
  class="text-nickel mx-2 outline-none focus:ring-transparent focus-visible:outline-none"
  [disabled]="isPreviousDayValid"
  [ngClass]="iconColor(isPreviousDayValid)"
  (click)="onChangeDay(calendarCode.PREVIOUSDAY)">
  <crew-ui-icon
    class="outline-none focus:ring-transparent"
    name="chevron_left"></crew-ui-icon>
</button>
<button
  *ngIf="showPreviousNextButton"
  type="button"
  class="text-nickel mx-2 outline-none focus:ring-transparent focus-visible:outline-none"
  [disabled]="isNextDayValid"
  [ngClass]="iconColor(isNextDayValid)"
  (click)="onChangeDay(calendarCode.NEXTDAY)">
  <crew-ui-icon
    class="outline-none focus:ring-transparent"
    name="chevron_right"></crew-ui-icon>
</button>
<button
  *ngIf="showTodayButton"
  type="button"
  [disabled]="validateIsToday(selectedEvent.day)"
  [ngClass]="[iconColor(validateIsToday(selectedEvent.day))]"
  (click)="onChangeDay(calendarCode.TODAY)"
  class="mx-2 items-center text-[17px] font-normal leading-[24px] outline-none focus:ring-transparent focus-visible:outline-none">
  {{ 'Today' }}
</button>
