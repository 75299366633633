import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpService } from '../services/http.service';

@Injectable()
export class LoadingOverlayInterceptor implements HttpInterceptor {
  constructor(private httpService: HttpService) {}

  private requests: HttpRequest<unknown>[] = [];

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('bypass://')) {
      const url = req.url.replace('bypass://', '');
      const bypassLoaderRequest = req.clone({ url });
      this.httpService.isLoading.set(false);
      return next.handle(bypassLoaderRequest);
    }

    this.requests.push(req);
    this.httpService.isLoading.set(true);
    return next.handle(req).pipe(
      finalize(() => {
        const index = this.requests.indexOf(req);
        this.requests.splice(index, 1);
        if (this.requests.length === 0) {
          this.httpService.isLoading.set(false);
        }
      }),
    );
  }
}
