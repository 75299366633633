import {
  PingAuthenticationModule,
  PingAuthenticationConfig,
} from '@techops-ui/ping-authentication';
import { APP_BASE_HREF } from '@angular/common';
import { AppComponent } from './app.component';
import { AppLayoutModule } from './layout/app.layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppSharedModule } from './shared/app.shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CrewModule } from './routes/crew/crew.module';
import { environment } from '../environments/environment';
import { ErrorHandler, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { InsightsService } from './shared/services/azure/insights.service';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

export const PING_AUTH_CONFIG: PingAuthenticationConfig = {
  authority: environment.pingFederate.url,
  client_id: environment.pingFederate.clientId,
  use_html5_routing: true,
  scope: 'openid profile',
  idle_threshold: 1814400,
  timeout_duration: 60,
  post_logout_redirect_uri: window.location.origin,
  use_session_storage: false,
  base_href: '', // ! this is extremely important, leave it alone
} as const;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppSharedModule,
    AppLayoutModule,
    CrewModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: false,
      registrationStrategy: 'registerWhenStable:10000',
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      // registrationStrategy: 'registerWhenStable:30000'
      // registrationStrategy: 'registerImmediately'
    }),
    PingAuthenticationModule.forRoot(PING_AUTH_CONFIG),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase.config)),
    provideMessaging(() => getMessaging()),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    InsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private insights: InsightsService) {}
}
