import { Component } from '@angular/core';
import { OverviewService } from '../overview.service';
import { FirebaseIdbService } from '../../../../../shared/services/idb/firebase.idb.service';
import { Colors, CrewUIDarkModeService } from '@cci-web/crew-ui';

@Component({
  selector: 'crew-web-overview-notification-modal',
  templateUrl: './overview-notification-modal.component.html',
  styleUrls: ['./overview-notification-modal.component.css'],
})
export class OverviewNotificationModalComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private firebaseIdbService: FirebaseIdbService,
    private overviewService: OverviewService,
  ) {}

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  get hasNotifications() {
    return this.notifications.length > 0;
  }

  get hasDisabledNotifications() {
    if (Notification.permission === 'granted') return false;
    return true;
  }

  get notifications() {
    return this.firebaseIdbService.notifications();
  }

  colors = Colors;

  refresh() {
    this.firebaseIdbService.reloadUnacknowledgedONENotifications(false);
  }

  close(event?: Event) {
    event?.stopPropagation();
    this.overviewService.toggleNotificationModal();
  }
}
