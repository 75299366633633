import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'crew-ui-overlay',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './crew-ui-overlay.component.html',
  styleUrls: ['./crew-ui-overlay.component.css'],
})
export class CrewUIOverlayComponent {
  @Input()
  isHidden = false;
}
