<div class="xs:h-[92%] gt-xs:h-[95%] gt-sm:h-full overflow-hidden">
  <div
    *ngIf="!hasSabreResponse"
    class="lt-sm:flex-col gt-sm:flex-row flex h-full w-full">
    <div
      class="xs:w-full gt-md:w-[70%] bg-cirrus relative flex h-full flex-col rounded px-0 sm:w-[62%] sm:pl-1 md:w-[64%] dark:bg-black">
      <!-- Calendar menu -->
      <crew-web-calendar-menu />
      <!-- Calendar UI -->
      <crew-web-calendar-info class="h-full" />
    </div>
    <div
      class="xs:w-full gt-md:w-[30%] gt-md:pr-0 bg-cirrus flex flex-col sm:w-[38%] sm:px-0 md:w-[36%] dark:bg-black">
      <!-- Sequence info -->
      <crew-web-calendar-event-details />
    </div>
  </div>
  <crew-web-sabre-document *ngIf="hasSabreResponse" />
</div>
