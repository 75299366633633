import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { environment } from '../../../../environments/environment';
import {
  GetCCIMsgDetailByMsgIdRequest,
  GetCCIMsgDetailByMsgIdResponse,
  GetEmployeeMessagesRequest,
  GetEmployeeMessagesResponse,
  ManageEmployeeMessageRequest,
  ManageEmployeeMessageResponse,
  AcknowledgeCCIMessageRequest,
  AcknowledgeCCIMessageResponse,
} from './message.service.types';

@Injectable({
  providedIn: 'root',
})
export class MessageApiService {
  constructor(private httpService: HttpService) {}

  private readonly base = `${environment.azure.services.url}/message`;

  getCciMsgDetailByMsgId(
    body: GetCCIMsgDetailByMsgIdRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/getCciMsgDetailByMsgId`;
    return this.httpService.post<
      GetCCIMsgDetailByMsgIdRequest,
      GetCCIMsgDetailByMsgIdResponse
    >(url, body, bypassLoader);
  }

  getEmployeeMessages(body: GetEmployeeMessagesRequest, bypassLoader = false) {
    const url = `${this.base}/getEmployeeMessages`;
    return this.httpService.post<
      GetEmployeeMessagesRequest,
      GetEmployeeMessagesResponse
    >(url, body, bypassLoader);
  }

  manageEmployeeMessage(
    body: ManageEmployeeMessageRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/manageEmployeeMessage`;
    return this.httpService.post<
      ManageEmployeeMessageRequest,
      ManageEmployeeMessageResponse
    >(url, body, bypassLoader);
  }

  acknowledgeCCIMessage(
    body: AcknowledgeCCIMessageRequest,
    bypassLoader = false,
  ) {
    const url = `${this.base}/acknowledgeCCIMessage`;
    return this.httpService.post<
      AcknowledgeCCIMessageRequest,
      AcknowledgeCCIMessageResponse
    >(url, body, bypassLoader);
  }
}
