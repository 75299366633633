<div *ngIf="this.vertical; else horizontalBlock">
  <div class="flex min-h-full items-center px-2">
    <div
      class="inline-block w-px self-stretch"
      [ngClass]="backgroundTheme()"></div>
  </div>
</div>
<ng-template #horizontalBlock>
  <div class="flex h-px w-full items-center py-2">
    <div
      *ngIf="!this.text || this.text === ''; else withTextBlock"
      class="h-px flex-grow"
      [ngClass]="backgroundTheme()">
    </div>
    <ng-template #withTextBlock class="flex w-full items-center">
      <div class="h-px flex-grow" [ngClass]="backgroundTheme()"></div>
      <div
        class="text-carbon dark:text-nickel first-letter:font-condensed font-md font-nickel mx-3 flex-shrink"
        [innerText]="this.text">
      </div>
      <div class="h-px flex-grow" [ngClass]="backgroundTheme()"></div>
    </ng-template>
  </div>
</ng-template>
