<crew-ui-card *ngIf="!showMessage">
  <div class="p-4">
    <div class="flex justify-between">
      <span class="text-lg font-bold">New Message</span>
      <crew-ui-icon [color]="colors.Afterburner" name="report" />
    </div>
    <p class="mt-4">
      You must read and acknowledge this message before using the CCI app.
    </p>
    <div
      (click)="showMessage = !showMessage"
      class="bg-afterburner mt-4 flex cursor-pointer items-center justify-between rounded-lg bg-opacity-10 p-2">
      <span class="text-afterburner font-bold">Open message</span>
      <crew-ui-icon [color]="colors.Afterburner" name="arrow_forward_ios" />
    </div>
  </div>
</crew-ui-card>

<div
  *ngIf="showMessage"
  class="bg-cirrus dark:bg-carbon fixed bottom-0 left-0 right-0 top-0 dark:text-white">
  <div class="flex items-center justify-between px-4 pb-2 pt-4">
    <button
      (click)="showMessage = !showMessage"
      class="dark:text-troposphere text-stratosphere">
      Close
    </button>
    <button
      (click)="acknowledge()"
      class="dark:text-nickel text-steel"
      [class.cursor-default]="!acknowledgeable"
      [class.text-steel]="!acknowledgeable"
      [class.dark:text-steel]="!acknowledgeable"
      [class.text-stratosphere]="acknowledgeable"
      [class.dark:text-troposphere]="acknowledgeable">
      Acknowledge
    </button>
  </div>
  <hr class="dark:border-nickel border-steel mx-4 my-1" />
  <div class="flex items-center justify-between px-4 py-2">
    <span class="dark:text-white">{{ message.messageSignature }}</span>
    <span class="dark:text-nickel text-steel">{{
      showDateTime(message.effectiveDate)
    }}</span>
  </div>
  <hr class="dark:border-nickel border-steel mx-4 my-1" />
  <div class="flex items-center justify-between px-4 py-2">
    <span class="text-xl font-bold dark:text-white">
      {{ message.messageTitle }}
    </span>
  </div>
  <hr class="dark:border-nickel border-steel mx-4 my-1" />
  <div
    #messageBody
    class="message-body h-full overflow-y-auto px-4 py-4"
    (scroll)="onScroll($event)">
    {{ message.body }}
  </div>
</div>
