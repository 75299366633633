import {
  CrewUIButtonComponent,
  CrewUICardComponent,
  CrewUIDividerComponent,
  CrewUIIconComponent,
  CrewUIInputComponent,
  CrewUILogoComponent,
  CrewUIModalComponent,
  CrewUIOverlayComponent,
  CrewUIPillboxComponent,
  CrewUISearchBarComponent,
  CrewUISelectComponent,
  CrewUISpinnerComponent,
  CrewUISwitchComponent,
  CrewUIToastComponent,
} from '@cci-web/crew-ui';
import { CalendarEventTitlePipe } from './pipes/calendarEventTitle.pipe';
import { FlightTimePipe } from './pipes/flightTime.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { CommonModule } from '@angular/common';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders as HttpInterceptorProviders } from './interceptors/shared.interceptor';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SettingsButtonComponent } from './components/settings-button/settings-button.component';
import { SettingsModalComponent } from './components/settings-modal/settings-modal.component';
import { SabreDocumentComponent } from './components/sabre-document/sabre-document.component';
import { ForwardMessageComponent } from './components/forwardMessage/forward-message.component';
import { SafeHtmlComponent } from './components/safe-html/safe-html.component';
import { DialogConfirmModalComponent } from './components/dialog-confirm-modal/dialog-confirm-modal.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
const COMPONENTS = [
  ErrorPageComponent,
  SettingsModalComponent,
  SettingsButtonComponent,
  SabreDocumentComponent,
  ForwardMessageComponent,
  SafeHtmlComponent,
  AlertModalComponent,
  DialogConfirmModalComponent,
  PdfViewerComponent,
];

const CREW_UI_COMPONENTS = [
  CrewUIButtonComponent,
  CrewUICardComponent,
  CrewUIDividerComponent,
  CrewUIIconComponent,
  CrewUIInputComponent,
  CrewUILogoComponent,
  CrewUIModalComponent,
  CrewUIOverlayComponent,
  CrewUIPillboxComponent,
  CrewUISearchBarComponent,
  CrewUISelectComponent,
  CrewUISpinnerComponent,
  CrewUISwitchComponent,
  CrewUIToastComponent,
];

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  RouterModule,
];

const PIPES = [
  CalendarEventTitlePipe,
  FlightTimePipe,
  SafeHtmlPipe,
  OrderByPipe,
];

const PROVIDERS = [HttpInterceptorProviders];

@NgModule({
  declarations: [COMPONENTS, PIPES],
  imports: [MODULES, CREW_UI_COMPONENTS, NgxExtendedPdfViewerModule],
  exports: [
    MODULES,
    COMPONENTS,
    CREW_UI_COMPONENTS,
    PIPES,
    NgxExtendedPdfViewerModule,
  ],
  providers: [PROVIDERS],
})
export class AppSharedModule {}
