<crew-ui-overlay>
  <crew-ui-card
    class="gt-xs:max-h-[66%] h-full w-full sm:w-2/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
    <div class="dark:bg-carbon h-full w-full overflow-auto rounded-2xl">
      <div
        class="dark:bg-charcoal bg-cirrus sticky top-0 w-full rounded-t-2xl p-4">
        <div class="flex w-full items-center justify-between">
          <button
            class="dark:text-troposphere text-stratosphere"
            (click)="close($event)">
            Close
          </button>
          <h2 class="text-center font-bold dark:text-white"> Notifications </h2>
          <button (click)="refresh()">
            <crew-ui-icon
              name="refresh"
              [color]="isDark ? colors.Troposphere : colors.Stratosphere" />
          </button>
        </div>
      </div>
      <div
        *ngIf="hasDisabledNotifications"
        class="flex flex-col justify-center pt-2">
        <p class="text-steel dark:text-nickel text-center">
          Notifications are disabled.
        </p>
        <p class="text-steel dark:text-nickel text-center">
          Enable notifications in the app settings menu.
        </p>
      </div>
      <div
        *ngIf="!hasNotifications && !hasDisabledNotifications"
        class="flex justify-center pt-2">
        <span class="text-steel dark:text-nickel text-center">
          You do not have any notifications.
        </span>
      </div>
      <div
        *ngIf="hasNotifications && !hasDisabledNotifications"
        class="gt-sm:px-4 px-2">
        <crew-ui-card *ngFor="let notification of notifications">
          <crew-web-overview-notification
            [notification]="notification"
            (dismiss)="close()" />
        </crew-ui-card>
      </div>
    </div>
  </crew-ui-card>
</crew-ui-overlay>
