import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CrewUIToastService } from './crew-ui-toast.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CrewUIIconComponent } from '../crew-ui-icon/crew-ui-icon.component';

@Component({
  selector: 'crew-ui-toast',
  standalone: true,
  imports: [CommonModule, CrewUIIconComponent],
  templateUrl: './crew-ui-toast.component.html',
  styleUrls: ['./crew-ui-toast.component.css'],
  animations: [
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(300)),
    ]),
  ],
})
export class CrewUIToastComponent {
  get toastServiceOptions() {
    return this.toastService.options();
  }

  get isVisible() {
    return this.toastService.isVisible();
  }

  constructor(private toastService: CrewUIToastService) {}

  close() {
    this.toastService.hideToast();
  }
}
