<crew-ui-overlay *ngIf="isVisible">
  <crew-ui-card class="lt-sm:w-full lt-sm:h-full">
    <div class="roundex-t-xl h-full overflow-hidden px-4 pb-4">
      <div
        class="gt-lg:px-6 -mx-4 flex items-center justify-between rounded-t-xl px-4 py-4">
        <span
          (click)="close()"
          class="text-stratosphere dark:text-troposphere cursor-pointer">
          Close
        </span>
        <span
          [class.cursor-pointer]="sendable"
          [class.dark:text-troposphere]="sendable"
          [class.text-stratosphere]="sendable"
          [class.cursor-none]="!sendable || sending"
          [class.dark:text-steel]="!sendable || sending"
          [class.text-aluminum]="!sendable || sending"
          (click)="send()">
          Send
        </span>
      </div>
      <div class="dark:border-steel border-cirrus border-y py-2">
        <p class="gt-lg:ml-4 mb-2 text-lg font-bold">HISEND</p>
        <p class="gt-lg:ml-4">
          <span class="text-nickel">To: </span>
          <span>{{ title }}</span>
        </p>
      </div>
      <div class="gt-lg:mt-4 gt-lg:px-4 lt-sm:w-full h-[70vh] w-[70vw]">
        <textarea
          [disabled]="sending"
          [(ngModel)]="message"
          autofocus="true"
          class="dark:bg-charcoal h-full w-full border-none dark:text-white"></textarea>
      </div>
    </div>
  </crew-ui-card>
</crew-ui-overlay>
