<div
  class="bg-cirrus dark:bg-charcoal h-full overflow-y-scroll p-8 dark:text-white">
  <h1 class="flex items-center justify-center text-3xl font-bold">
    Installation Guide
  </h1>
  <div class="my-4">
    <crew-ui-divider [kind]="'secondary'" />
  </div>
  <div class="pl-2">
    <!-- ! ANDROID -->
    <h1
      (click)="copy('android')"
      id="android"
      class="cursor-pointer text-2xl font-bold hover:underline">
      Android (Chrome)
    </h1>
    <ol class="pl-2">
      <li class="my-2 list-decimal">Expand the settings menu:</li>
      <img class="max-w-xs" src="assets/images/android/install-guide-0.jpg" />
      <li class="my-2 list-decimal">Tap Install app:</li>
      <img class="max-w-xs" src="assets/images/android/install-guide-1.jpg" />
      <li class="my-2 list-decimal">Tap the Install button:</li>
      <img class="max-w-xs" src="assets/images/android/install-guide-2.jpg" />
      <li class="my-2 list-decimal">
        You will receive a notification when the app has installed and it will
        be available in your applications.
      </li>
      <div class="gt-xs:flex-row flex flex-col">
        <img
          class="gt-xs:mr-1 xs:my-2 max-w-xs"
          src="assets/images/android/install-guide-3.jpg" />
        <img
          class="gt-xs:ml-1 xs:my-2 max-w-xs"
          src="assets/images/android/install-guide-4.jpg" />
      </div>
      <li class="my-2 list-decimal">
        After launching the application you will be presented with a native
        window.
      </li>
      <img class="max-w-xs" src="assets/images/android/install-guide-5.jpg" />
    </ol>
    <!-- ! IOS -->
    <div class="my-4">
      <crew-ui-divider [kind]="'secondary'" />
    </div>
    <h1
      (click)="copy('ios')"
      id="ios"
      class="cursor-pointer text-2xl font-bold hover:underline">
      IOS (Safari)
    </h1>
    <ol class="pl-2">
      <li class="my-2 list-decimal">This is only supported in Safari.</li>
      <li class="my-2 list-decimal">
        You must install the PWA to enable push notifications.
      </li>
      <li class="my-2 list-decimal">Tap the share button:</li>
      <img class="min-w-full" src="assets/images/ios/install-guide-0.jpg" />
      <li class="my-2 list-decimal">Tap add to home screen:</li>
      <img class="min-w-full" src="assets/images/ios/install-guide-1.jpg" />
      <li class="my-2 list-decimal">
        Optionally label your application before continuing:
      </li>
      <img class="min-w-full" src="assets/images/ios/install-guide-2.jpg" />
      <li class="my-2 list-decimal">
        You will see the application listed in your apps:
      </li>
      <img class="min-w-full" src="assets/images/ios/install-guide-3.jpg" />
      <li class="my-2 list-decimal">
        After launching the application you will be presented with a native
        window.
      </li>
      <img class="min-w-full" src="assets/images/ios/install-guide-4.jpg" />
    </ol>
    <!-- ! DESKTOP -->
    <div class="my-4">
      <crew-ui-divider [kind]="'secondary'" />
    </div>
    <h1
      (click)="copy('desktop')"
      id="desktop"
      class="cursor-pointer text-2xl font-bold hover:underline">
      Desktop (Chrome / Island)
    </h1>
    <ol class="pl-2">
      <li class="my-2 list-decimal">Tap install button in address bar:</li>
      <img class="min-w-full" src="assets/images/desktop/install-guide-0.jpg" />
      <li class="my-2 list-decimal">Tap the Install button:</li>
      <img class="min-w-full" src="assets/images/desktop/install-guide-1.jpg" />
      <li class="my-2 list-decimal">
        You will receive a notification when the app has installed and it will
        be available in your applications.
      </li>
      <img class="min-w-full" src="assets/images/desktop/install-guide-2.jpg" />
      <li class="my-2 list-decimal">
        After launching the application you will be presented with a native
        window.
      </li>
      <img
        class="mt-2 min-w-full"
        src="assets/images/desktop/install-guide-3.jpg" />
    </ol>
  </div>
</div>
