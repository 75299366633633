<div class="my-4 flex items-center justify-center">
  <crew-ui-icon
    name="account_circle"
    (click)="showSettingsModal()"
    class="text-stratosphere dark:text-troposphere cursor-pointer" />
</div>
<ul class="text-center text-xs">
  <li
    class="my-8 cursor-pointer"
    (click)="closeHSS()"
    [routerLink]="[getCrewPath(routes.crew.overview)]">
    <a>
      <crew-ui-icon name="where_to_vote" [color]="buildIconColor()" />
      <p
        class="text-base"
        [class.text-stratosphere]="isActiveRoute(routes.crew.overview)"
        [class.dark:text-troposphere]="isActiveRoute(routes.crew.overview)"
        [class.text-nickel]="!isActiveRoute(routes.crew.overview)">
        Overview
      </p>
    </a>
  </li>
  <li
    class="my-8 cursor-pointer"
    (click)="closeHSS()"
    [routerLink]="[getCrewPath(routes.crew.calendar)]">
    <a>
      <crew-ui-icon name="calendar_month" [color]="buildIconColor()" />
      <p
        class="text-base"
        [class.text-stratosphere]="isActiveRoute(routes.crew.calendar)"
        [class.dark:text-troposphere]="isActiveRoute(routes.crew.calendar)"
        [class.text-nickel]="!isActiveRoute(routes.crew.calendar)">
        Schedule
      </p>
    </a>
  </li>
  <li
    class="my-8 cursor-pointer"
    (click)="closeHSS()"
    [routerLink]="[getCrewPath(routes.crew.message)]">
    <a>
      <div class="relative">
        <crew-ui-icon name="markunread" [color]="buildIconColor()" />
        <div
          class="bg-afterburner absolute -top-2 right-2.5 flex h-5 w-5 items-center justify-center rounded-full text-[10px] text-white"
          *ngIf="messageCount + hi6Count > 0">
          {{ messageCount + hi6Count > 99 ? '99+' : messageCount + hi6Count }}
        </div>
      </div>
      <p
        class="text-base"
        [class.text-stratosphere]="isActiveRoute(routes.crew.message)"
        [class.dark:text-troposphere]="isActiveRoute(routes.crew.message)"
        [class.text-nickel]="!isActiveRoute(routes.crew.message)">
        Messages
      </p>
    </a>
  </li>
  <li
    class="my-8 cursor-pointer"
    (click)="closeHSS()"
    [routerLink]="[getCrewPath(routes.crew.search)]">
    <a [class.text-stratosphere]="isActiveRoute(routes.crew.search)">
      <crew-ui-icon name="more_horiz" [color]="buildIconColor()" />
      <p
        class="text-base"
        [class.text-stratosphere]="isActiveRoute(routes.crew.search)"
        [class.dark:text-troposphere]="isActiveRoute(routes.crew.search)"
        [class.text-nickel]="!isActiveRoute(routes.crew.search)">
        More
      </p>
    </a>
  </li>
</ul>
<div class="my-4 flex items-center justify-center">
  <img
    height="24"
    width="24"
    src="assets/images/logo-120x120.png"
    alt="image"
    class="inline-block" />
</div>
