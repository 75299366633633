import { Component, OnInit } from '@angular/core';
import { SabreDocumentService } from '../../../../shared/components/sabre-document/sabre-document.service';
import { CalendarService } from './calendar.service';
import { InsightsService } from '../../../../shared/services/azure/insights.service';
import { UserService } from '../../../../shared/services/user.service';
@Component({
  selector: 'crew-web-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  get hasSabreResponse() {
    return this.sabreDocumentService.hasSabreResponse();
  }

  constructor(
    private calendarService: CalendarService,
    private insightsService: InsightsService,
    private sabreDocumentService: SabreDocumentService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.insightsService.trackPageView({
      name: 'Calendar',
    });
    this.calendarService.loadCalendar();
  }
}
