import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CrewUILogoComponent, CrewUISpinnerComponent } from '@cci-web/crew-ui';
import { MessageService } from '../crew/outlets/message/message.service';
import { MessageIdbService } from '../../shared/services/idb/message.idb.service';
import { MessageCardComponent } from './message-card/message-card.component';
import { SignInCardComponent } from './sign-in-card/sign-in-card.component';
import { UserService } from '../../shared/services/user.service';
import { ReadAndAcknowledgeService } from './read-and-acknowledge.service';
import { AppSharedModule } from '../../shared/app.shared.module';
import { SabreDocumentService } from '../../shared/components/sabre-document/sabre-document.service';
import { V3TripSignInData } from '../../shared/services/azure/core.service.types';
@Component({
  selector: 'crew-web-read-and-acknowledge',
  templateUrl: './read-and-acknowledge.component.html',
  styleUrls: ['./read-and-acknowledge.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CrewUILogoComponent,
    CrewUISpinnerComponent,
    MessageCardComponent,
    SignInCardComponent,
    AppSharedModule,
  ],
})
export class ReadAndAcknowledgeComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private idbService: MessageIdbService,
    private userService: UserService,
    private readAndAcknowledgeService: ReadAndAcknowledgeService,
    private sabreDocumentService: SabreDocumentService,
    private router: Router,
  ) {
    this.userService
      .load()
      .then(() =>
        this.idbService.refreshMessages().then(() => (this.loaded = true)),
      );
  }

  ngOnInit(): void {
    this.idbService.refreshMessages().then(() => (this.loaded = true));
    this.readAndAcknowledgeService.callGetTripSignInInfoV3();
    setTimeout(() => {
      if (this.readAndAcknowledgeMessages === undefined) {
        this.router.navigate(['/']);
      }
    }, 5000);
  }

  loaded = false;

  get readAndAcknowledgeMessages() {
    return this.messageService
      .readAcknowledgePriorityMessages()
      ?.filter((m) => m.acknowledgementRequired);
  }

  get tripSignInInfoV3Response() {
    return this.readAndAcknowledgeService.tripSignInInfoV3Response();
  }

  set tripSignInInfoV3Response(data: V3TripSignInData) {
    this.readAndAcknowledgeService.setTripSignInInfoV3Response(data);
  }

  /**
   * Holds the trip sign in response data
   */
  get hasSabreResponse() {
    return this.sabreDocumentService.hasSabreResponse();
  }

  onCloseSabreDocument(event: boolean): void {
    if (event) {
      const tripSignIn = this.tripSignInInfoV3Response;
      if (tripSignIn.signInEligible && this.hasSabreResponse) {
        tripSignIn.signedIn = true;
      }
      this.tripSignInInfoV3Response = tripSignIn;
      this.router.navigate(['/']);
    }
  }
}
