import { BusinessUnit } from '../../types';
import { CheckListItemParameterType } from './../../types';

export enum CardTitle {
  IR = 'Input Required',
  HIC = 'HI Commands',
  WEBSITES = 'Websites',
  FN = 'By flight number',
  CKPILOT = 'Check Pilot',
  CKPILOTIR = 'Check Pilot Input Required',
}

export type CallSearchFlight = {
  arrStation: string;
  depStation: string;
  fltDepDate: string;
  fltNumber: number;
};

export type UserBidStatus = {
  contractMonth: string;
  crewBase: string;
  crewDivision: string;
  crewEquipment: string;
  crewSeat: string;
  checkAirman: boolean;
  fsmSupervisorNumber: string;
  checkAirmanType: string;
  depatureStations?: string[];
  inactiveSupervisor: boolean;
};

export type CheckAirmanMenu = {
  title: string;
  isOpen: boolean;
  checkAirmanData: CheckListItem[];
};

export type MenuList = {
  title: string;
  order: number;
};

export type CoreServiceBaseResponse = {
  businessUnit: BusinessUnit;
  empIdLogin: number;
  errorInfo: {
    businessContext: string;
    errorCode: string;
    errorMessage: string;
    errorReference: string;
    httpStatusCode: string;
    severity: string;
    success: boolean;
    validationFailureMessages: string[];
  };
  errorMessage: string[];
  messageAction: string;
  success: boolean;
};

export type CheckListItemParameterArray = {
  commandParameter: CheckListItemParameterType;
  commandValue: string;
  commandParameterDesc: string;
};

export type CheckListItemParameter = {
  action: string;
  buCode: string;
  commandParameterDesc: string;
  commandId: number;
  commandParameter: CheckListItemParameterType;
  commandParameterId: number;
  displayOrder: number;
  updDate: string;
  updEmpId: number;
};

// export enum CheckListItemParameterType {
//   CabinClass = '[CABIN_CLASS]',
//   DepatureDateDay = '[DEPARTUREDATE-DAY]',
//   DepatureTimeActual = '[DEPTIME-ACTUAL]',
//   FlightOriginationDate = '[FLTORIGINDATE]',
//   PlannedTakeoffWay = '[PLANTKOFFRWAY]',
//   PlannedOffWeight = '[PLANTKOFFWEIGHT]',
//   FlapSettings = '[FLAPSETTINGS]',
//   NumberOfDays = '[NUMBER_OF_DAYS]',
//   TailNumber = '[TAILNUMBER]',
//   WeatherRegion = '[WEATHER REGION]',
//   WeatherType = '[WEATHER TYPE]',
//   AircraftType = '[AC_TYPE]',
//   EmployeeNumber = '[EMPNUMBER]',
//   DepartureDate = '[DEPARTUREDATE]',
//   FlightNumber = '[FLIGHTNUMBER]',
//   CrewSeat = '[CREW_SEAT]',
//   CrewBase = '[CREW_BASE]',
//   CrewDivision = '[CREW_DIV]',
//   DepartureStation = '[DEPARTURESTATION]',
//   ArrivalStation = '[ARRIVALSTATION]',
//   CrewEquipment = '[CREW_EQP]',
//   SequenceNumber = '[SEQ_NUMBER]',
//   SequenceDate = '[SEQ_DATE]',
//   StartDate = '[START DATE]',
//   Date = '[DATE]',
//   EndDate = '[END DATE]',
//   Space = '[SPACE]',
//   Gap = ' ',
//   ForwardSlash = '/',
//   FT = 'FT',
//   Asterisk = '*',
//   C = 'C',
// }

export type CheckListItem = {
  requiredCKAInd: boolean;
  chklistShowCmdF3PLC: string;
  action: string;
  buCode: string;
  checklistId: number;
  commandDescription: string;
  commandDisplayOrder: number;
  commandId: number;
  commandKeyword: string;
  commandKeywordDisplay: string;
  commandTypeCode: string;
  parametersArray: CheckListItemParameter[];
  showCmdF3PLC: string;
  systemName: string;
  updDate: string;
  updEmpId: number;
  active: boolean;
  f3P: boolean;
  internetInd: boolean;
  otherPrintAllLegs: boolean;
  otherPrintLayoverLegs: boolean;
  print: boolean;
  printAllLegs: boolean;
  printLayoverLegs: boolean;
  view: boolean;
  favorite: boolean;
};

export type CoreServiceBaseRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit?: BusinessUnit | undefined;
  deviceToken?: string;
  empIdLogin?: number; // ! EMULATED USER ID
  siteMinderEmpId: number; // ! ACTUAL USER ID
  uniqueValue?: string;
};

export type CaptureTripSignInOptInTimestampRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken?: string;
  effectiveEndDate?: string;
  empIdLogin: number;
  optIn: boolean;
  optInTime: string;
  siteMinderEmpId: number;
  uniqueValue?: string;
};

export type CaptureTripSignInOptInTimestampResponse =
  CoreServiceBaseResponse & {
    optInTime: string;
    tripSignOptInMessage: string;
  };

export type DelProbyEmpBySurveyIdRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken: string;
  emailAddress: string;
  empIdLogin: number;
  endDate: string;
  isOnlyPoor: boolean;
  isOnlySubmitted: boolean;
  probySearchEmployeeNumber: number;
  siteMinderEmpId: number;
  startDate: string;
  surveyId: number;
  updateProbyDelFlag: number;
};

export type GetChecklistItemRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit | undefined;
  empIdLogin: number;
  appSessionId?: string;
  siteMinderEmpId: number;
  deviceToken?: string;
  uniqueValue?: string;
  checklistId: string;
};

export type GetChecklistItemResponse = CoreServiceBaseResponse & {
  commandMappingArray: {
    action: string;
    buCode: string;
    checklistItemF3P: {
      requiredCKAInd: boolean;
      chklistShowCmdF3PLC: string;
      action: string;
      buCode: string;
      checklistId: number;
      commandDescription: string;
      commandDisplayOrder: number;
      commandId: number;
      commandKeyword: string;
      commandKeywordDisplay: string;
      commandTypeCode: string;
      parametersArray: {
        action: string;
        buCode: string;
        commandParameterDesc: string;
        commandId: number;
        commandParameter: string;
        commandParameterId: number;
        displayOrder: number;
        updDate: string;
        updEmpId: number;
      }[];
      showCmdF3PLC: string;
      systemName: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
      f3P: boolean;
      internetInd: boolean;
      otherPrintAllLegs: boolean;
      otherPrintLayoverLegs: boolean;
      print: boolean;
      printAllLegs: boolean;
      printLayoverLegs: boolean;
      view: boolean;
      favorite: boolean;
    };
    checklistItemLC: {
      requiredCKAInd: boolean;
      chklistShowCmdF3PLC: string;
      action: string;
      buCode: string;
      checklistId: number;
      commandDescription: string;
      commandDisplayOrder: number;
      commandId: number;
      commandKeyword: string;
      commandKeywordDisplay: string;
      commandTypeCode: string;
      parametersArray: {
        action: string;
        buCode: string;
        commandParameterDesc: string;
        commandId: number;
        commandParameter: string;
        commandParameterId: number;
        displayOrder: number;
        updDate: string;
        updEmpId: number;
      }[];
      showCmdF3PLC: string;
      systemName: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
      f3P: boolean;
      internetInd: boolean;
      otherPrintAllLegs: boolean;
      otherPrintLayoverLegs: boolean;
      print: boolean;
      printAllLegs: boolean;
      printLayoverLegs: boolean;
      view: boolean;
      favorite: boolean;
    };
    commandIdF3P: number;
    commandIdLC: number;
    updDate: string;
    updEmpId: number;
  }[];
  baseArray: {
    baseOrdinal: number;
    action: string;
    baseCode: string;
    baseName: string;
    baseParentCode: string;
    baseRegionCode: string;
    buCode: string;
    updDate: string;
    updEmpId: number;
    active: boolean;
    satelliteBase: boolean;
  }[];
  buArray: {
    action: string;
    airlineCode: string;
    baseArray: {
      baseOrdinal: number;
      action: string;
      baseCode: string;
      baseName: string;
      baseParentCode: string;
      baseRegionCode: string;
      buCode: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
      satelliteBase: boolean;
    }[];
    buCode: string;
    buDataArray: {
      action: string;
      buCode: string;
      buDataCode: string;
      buDataCodeOld: string;
      buDataDescription: string;
      codeTypeCode: string;
      sortOrder: number;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    buDescription: string;
    commandMappingArray: {
      action: string;
      buCode: string;
      checklistItemF3P: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      checklistItemLC: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      commandIdF3P: number;
      commandIdLC: number;
      updDate: string;
      updEmpId: number;
    }[];
    equipmentArray: {
      action: string;
      airlineCode: string;
      buCode: string;
      equipmentAltCode: string;
      equipmentCapacity: string;
      equipmentCode: string;
      equipmentDescription: string;
      equipmentTypeCode: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    navArray: {
      internet: boolean;
      intranet: boolean;
      action: string;
      effectiveDate: string;
      endDate: string;
      navlinkDataId: number;
      navlinkName: string;
      sortOrder: number;
      updDate: string;
      updEmpId: number;
      urlName: string;
    }[];
    seatArray: {
      action: string;
      buCode: string;
      seatAltCode: string;
      seatCode: string;
      seatDescription: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    updDate: string;
    updEmpId: number;
    active: boolean;
  }[];
  buDataArray: {
    action: string;
    buCode: string;
    buDataCode: string;
    buDataCodeOld: string;
    buDataDescription: string;
    codeTypeCode: string;
    sortOrder: number;
    updDate: string;
    updEmpId: number;
    active: boolean;
  }[];
  checklistArray: {
    action: string;
    buCode: string;
    checklistDescription: string;
    checklistId: number;
    checklistItemArray: CheckListItem[];
    showCmdF3PLC: string;
    updDate: string;
    updEmpId: number;
    active: boolean;
  }[];
  checklistItemArray: CheckListItem[];
  codeTypeArray: {
    action: string;
    buCode: string;
    codeTypeCd: string;
    codeTypeDescription: string;
    tableName: string;
    updDate: string;
    updEmpId: number;
  }[];
  equipmentArray: {
    action: string;
    airlineCode: string;
    buCode: string;
    equipmentAltCode: string;
    equipmentCapacity: string;
    equipmentCode: string;
    equipmentDescription: string;
    equipmentTypeCode: string;
    updDate: string;
    updEmpId: number;
    active: boolean;
  }[];
  fileListingArray: {
    action: string;
    buCode: string;
    delDate: string;
    delEmpFName: string;
    delEmpId: number;
    delEmpLName: string;
    fileCategory: string;
    fileId: number;
    fileName: string;
    fileSize: string;
    fileTitle: string;
    updDate: string;
    updEmpFName: string;
    updEmpId: number;
    updEmpLName: string;
    uplDate: string;
    uplEmpFName: string;
    uplEmpId: number;
    uplEmpLName: string;
    deleted: boolean;
  }[];
  navLinkArray: {
    internet: boolean;
    intranet: boolean;
    action: string;
    effectiveDate: string;
    endDate: string;
    navlinkDataId: number;
    navlinkName: string;
    sortOrder: number;
    updDate: string;
    updEmpId: number;
    urlName: string;
  }[];
  screenBuConfigDataArray: {
    action: string;
    datafieldCode: string;
    datafieldDesc: string;
    datafieldToolTip: string;
    screenDatafieldConfigId: number;
    screenDatagridCode: string;
    sortOrder: number;
    updDate: string;
    updEmpId: number;
    required: boolean;
    visible: boolean;
  }[];
  screenDatafieldConfigArray: {
    action: string;
    datafieldCode: string;
    datafieldDesc: string;
    screenDatafieldConfigId: number;
    screenDatafieldToolTip: string;
    screenDatagridCode: string;
    updDate: string;
    updEmpId: number;
    required: boolean;
  }[];
  seatArray: {
    action: string;
    buCode: string;
    seatAltCode: string;
    seatCode: string;
    seatDescription: string;
    updDate: string;
    updEmpId: number;
    active: boolean;
  }[];
  stationChecklistArray: {
    action: string;
    airlineCode: string;
    buCode: string;
    checklistDescription: string;
    checklistId: number;
    equipCode: string;
    showCmdF3PLC: string;
    station: string;
    stationChecklistId: number;
    updDate: string;
    updEmpId: number;
  }[];
  sysDataArray: {
    action: string;
    buCode: string;
    codeTypeCode: string;
    systemDataCode: string;
    systemDataDescription: string;
    updDate: string;
    updEmpId: number;
    active: boolean;
  }[];
};

export type GetCrewMemberV6Request = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken?: string;
  empIdLogin: number;
  siteMinderEmpId: number;
  uniqueValue?: string;
};

export type GetCrewMemberV6Response = CoreServiceBaseResponse & {
  crewType: string;
  bidStatuses: {
    actualPayProjection: number;
    aggressive: boolean;
    asgSequenceClickCount: number;
    asgStandByClickCount: number;
    checkAirman: boolean;
    contractMonth: string;
    contractMonthEndDate: string;
    contractMonthStartDate: string;
    contractMonthType: string;
    currentBase: string;
    currentDivision: string;
    currentEquipment: string;
    currentPosition: string;
    etbNet: number;
    faBaseGuarantee: number;
    fsmSupervisorNumber: string;
    greaterTimetoDate: number;
    inactiveCrewMember: boolean;
    inactiveSupervisor: boolean;
    involuntaryCount: number;
    ipMax: number;
    maxHours: number;
    monthlyMax: number;
    mtdBlockPay: number;
    mtdBlockTime: number;
    nextBase: string;
    nextDivision: string;
    nextEquipment: string;
    nextPosition: string;
    otSum: number;
    payProjection: number;
    projectionActuals: number;
    scheduledBlockTime: number;
    scheduledPay: number;
    scheduledProjection: number;
    seatCategory: string;
    selectionType: string;
    seniorityNumber: number;
    standbyCount: number;
    totalETB: number;
    totalETBPNCAdj: number;
    totalETBTripHours: number;
    totalPNC: number;
    voluntaryCount: number;
    volunteer: boolean;
    waiverInd: boolean;
  }[];
  departureFlightsInfo: {
    sequenceNumber: number;
    sequenceOriginationDate: string;
    flightNumber: number;
    flightOriginationDate: string;
    departureStation: string;
    arrivalStation: string;
    isDeadHead: boolean;
    isFutureFlight: boolean;
    localDepartureTime: string;
    localArrivalTime: string;
    contractMonth: string;
    gmtDepartureTime: string;
    gmtArrivalTime: string;
    cancelled: boolean;
    removed: boolean;
  }[];
  calendarResponse: {
    days: {
      date: string;
      daysEvents: {
        id: number;
        start: string;
        end: string;
        startInGMT: string;
        endInGMT: string;
        title: string;
        styles: string;
        span: number;
        indicatorLeftPadding: number;
        indicatorWidth: number;
        indicatorRightPadding: number;
        beginsToday: boolean;
        endsToday: boolean;
        continuesToday: boolean;
        sequenceActivity: {
          addCode: string;
          airlineCode: string;
          base: string;
          cabinType: string;
          contractMonth: string;
          creditNextMonth: number;
          creditThisMonth: number;
          division: string;
          durationInDays: number;
          employeeID: number;
          equipmentGroup: string;
          equipmentQuals: {
            equipmentGroup: string;
            equipmentNumber: string;
            equipmentType: string;
          }[];
          failsContinuity: boolean;
          firstLegDeadHeadIndicator: boolean;
          firstLegDepartureAirport: string;
          firstLegDepartureTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
          flightDutyPeriods: {
            baseIndicator: string;
            crewMeal: boolean;
            duration: number;
            dutyPeriodNumber: number;
            endDateTime: {
              baseTime: string;
              gmt: string;
              localTime: string;
            };
            flightLegs: {
              actual: {
                arrivalAirport: string;
                arrivalDateTime: {
                  baseTime: string;
                  gmt: string;
                  localTime: string;
                };
                departureAirport: string;
                departureDateTime: {
                  baseTime: string;
                  gmt: string;
                  localTime: string;
                };
              };
              airlineCode: string;
              arrivalDuplicateCode: number;
              baseIndicator: string;
              blockTime: number;
              crewHotel: {
                departureDate: string;
                departureStation: string;
                departureStationDupe: number;
                dutyPeriodNumber: number;
                flightNumber: string;
                index: number;
                name: string;
                phone: string;
                positions: string[];
              };
              deadHead: boolean;
              deadHeadAirlineCode: string;
              departureDuplicateCode: number;
              departureStation: string;
              equipmentQuals: {
                equipmentGroup: string;
                equipmentNumber: string;
                equipmentType: string;
              };
              flightNumber: number;
              flightOriginationDate: string;
              groundTime: number;
              legIndex: number;
              legType: string;
              limoDetails: {
                departureDate: string;
                departureStation: string;
                departureStationDupe: number;
                dutyPeriod: number;
                flightNumber: string;
                inboundLimoCompany: {
                  name: string;
                  phone: string;
                };
                index: number;
                outboundLimoCompany: {
                  name: string;
                  phone: string;
                };
                positionsInLimo: string[];
              };
              noShowDHD: boolean;
              reScheduled: {
                arrivalAirport: string;
                arrivalDateTime: {
                  baseTime: string;
                  gmt: string;
                  localTime: string;
                };
                departureAirport: string;
                departureDateTime: {
                  baseTime: string;
                  gmt: string;
                  localTime: string;
                };
              };
              scheduled: {
                arrivalAirport: string;
                arrivalDateTime: {
                  baseTime: string;
                  gmt: string;
                  localTime: string;
                };
                departureAirport: string;
                departureDateTime: {
                  baseTime: string;
                  gmt: string;
                  localTime: string;
                };
              };
              status: string;
              visaCountry: string;
              visaIndicator: string;
              arrivalStation: string;
              endOfDutyPeriod: boolean;
              cancelled: boolean;
              removed: boolean;
              endOfSequence: boolean;
              active: boolean;
            }[];
            layOverAirport: string;
            layoverInMinutes: number;
            layoverStation: string;
            numberOfLegs: number;
            odMinutes: number;
            payCredit: {
              actualCredit: number;
              actualFlight: number;
              actualTotalCredit: number;
              deadheadCredit: number;
              greaterTime: number;
              scheduledCredit: number;
              scheduledFlight: number;
              scheduledTotalCredit: number;
            };
            startDateTime: {
              baseTime: string;
              gmt: string;
              localTime: string;
            };
          }[];
          galleyPosition: boolean;
          hotelInfo: [
            {
              errors: {
                businessContext: string;
                compensationInfo: string;
                errorCode: string;
                errorMessage: string;
                errorReference: string;
                httpStatusCode: string;
                severity: string;
                success: boolean;
              }[];
              name: string;
              phone: string;
              positionCode: string;
              positionsInHotel: string[];
              sequenceNumber: number;
              sequenceOriginationDate: string;
              success: boolean;
            },
          ];
          international: boolean;
          isCoterminal: boolean;
          isIPD: boolean;
          isODAN: boolean;
          isRedEye: boolean;
          isRedFlag: boolean;
          isSatellite: boolean;
          isTrainingSequence: boolean;
          lastLegDeadHeadIndicator: boolean;
          layoverStations: string;
          legsPerDutyPeriod: string;
          numberOfLegs: number;
          multipleEquipments: boolean;
          positionCode: string;
          positionSequenceInfo1: string;
          positionSequenceInfo1Binary: string;
          positionSequenceInfo2: string;
          quals: string;
          removalCode: string;
          rigs: {
            rigDay: number;
            rigNight: number;
            rigType: string;
          }[];
          ronCities: string[];
          scheduledFlight: number;
          sequenceBlockTime: number;
          sequenceDutyTime: string;
          sequenceEndDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
          sequenceNumber: number;
          sequenceOriginDate: string;
          sequencePayCredit: {
            actualCredit: number;
            actualFlight: number;
            actualSeqCredit: number;
            actualTotalCredit: number;
            deadheadCredit: number;
            greaterTime: number;
            scheduledCredit: number;
            scheduledFlight: number;
            scheduledSeqCredit: number;
            scheduledTotalCredit: number;
          };
          sequencePayTime: number;
          sequenceStartDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
          serviceQuals: {
            description: string;
            number: number;
          }[];
          speakerRequirement: {
            posLangQual: string;
            posSpeakerRestricted: boolean;
            seqLangQuals: string[];
            totalMissingForCabin: number;
            totalMissingForSeq: number;
            totalRequiredForCabin: number;
            totalRequiredForSeq: number;
          };
          timeAwayFromBase: number;
          timeOpened: string;
          totalLegCount: number;
          signedIn: boolean;
          sequenceStatus: string;
          signInTimeInGMT: string;
          ttotday: {
            seqDate: string;
            seqDropType: string;
            seqNumber: number;
            seqPosted: string;
            seqStatus: string;
          };
        };
        hasPreviousEvent: boolean;
        order: number;
        eventType: string;
        eventCode: string;
        standbyGate: string;
        nonCollidingOrder: number;
        wholeDay: boolean;
      }[];
      displayDate: string;
      styles: string;
      startTime: string;
      endTime: string;
      endDate: string;
      showBeginTime: boolean;
      showEndTime: boolean;
      eventType: string;
      showLayover: boolean;
      showTurn: boolean;
      layover: string;
      turn: string;
      numberOfLegs: number;
      firstDayOfWeek: boolean;
      today: boolean;
      past: boolean;
      pastEvent: boolean;
    }[];
    displayName: string;
    calendarStartDate: string;
    contractMonth: {
      contractMonthStartDate: string;
      contractMonthEndDate: string;
      contractMonthType: string;
      contractMonthBase: string;
    };
  }[];
  currentEventIds: number[];
};

export type GetEmployeeByBURequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  employeeCount: number;
}[];

export type GetEmployeeByBUResponse = object; // ! THIS IS THE ONLY DEFINITION IN SWAGGER / NEEDS TESTING

export type GetEmployeeFavCommandsResponse = CoreServiceBaseResponse & {
  empFavCmds: {
    empFavCommands: {
      commandId: number;
      commandOrder: number;
    }[];
  };
};

export type GetEmployeeIdsRequest = {
  businessUnit: BusinessUnit;
  employeeCount: number;
  airlineCode: string;
};

export type GetEmployeeIdsResponse = string[];

export type GetEmployeePrintPreferenceRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  buCode: string;
  empGrpPintPrefIdsList: number[];
};

export type GetEmployeePrintPreferenceResponse = CoreServiceBaseResponse & {
  empGrpPrintPrefArray: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    printPreferenceGroupIds: number[];
    action: string;
    buCode: string;
    empPrintPrefArray: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: [
          {
            action: string;
            buCode: string;
            commandParameterDesc: string;
            commandId: number;
            commandParameter: string;
            commandParameterId: number;
            displayOrder: number;
            updDate: string;
            updEmpId: number;
          },
        ];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
    empPrintPrefGrpId: number;
    groupName: string;
    showCmdF3PLC: string;
    updDate: string;
    defaultInd: boolean;
    groupOrder: number;
    empPrintPrefList: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
  }[];
};

export type GetEmployeeProbyDetailsRequest = CoreServiceBaseRequest;

export type GetEmployeeProbyDetailsResponse = {
  success: boolean; // ? This is an outlier where the rest of CoreServiceBaseResponse properties is missing
  probyList: {
    surveyId: number;
    probyDisplayInformation: string;
    captainFirstName: string;
    captainLastName: string;
    captainEmpNumber: number;
    probyFirstName: string;
    probyLastName: string;
    reviewDate: string;
    startDate: string;
    probyEmpNumber: number;
    probyLinkValidUntil: string;
    probyURL: string;
    probyDeleteFlag: number;
  }[];
};

export type GetEmpPrintPreferenceRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  buCode: string;
  empGrpPintPrefIdsList: number[];
};

export type GetEmpPrintPreferenceResponse = CoreServiceBaseResponse & {
  empGrpPrintPrefArray: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    printPreferenceGroupIds: number[];
    action: string;
    buCode: string;
    empPrintPrefArray: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: [
          {
            action: string;
            buCode: string;
            commandParameterDesc: string;
            commandId: number;
            commandParameter: string;
            commandParameterId: number;
            displayOrder: number;
            updDate: string;
            updEmpId: number;
          },
        ];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
    empPrintPrefGrpId: number;
    groupName: string;
    showCmdF3PLC: string;
    updDate: string;
    defaultInd: boolean;
    groupOrder: number;
    empPrintPrefList: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: [
          {
            action: string;
            buCode: string;
            commandParameterDesc: string;
            commandId: number;
            commandParameter: string;
            commandParameterId: number;
            displayOrder: number;
            updDate: string;
            updEmpId: number;
          },
        ];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
  }[];
};

export type GetFlightInfoV3Request = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  cockpitListNeeded: boolean;
  deviceToken?: string;
  empIdLogin: number;
  flightSequenceInfo: {
    sequenceNumber: number;
    sequenceOriginationDate: string;
    sequencePosition: string;
    sequenceContractMonth: string;
  } | null;
  flightsRqArray: {
    depDupCode: string;
    depStation: string;
    fltIndex: number;
    fltNumber: number;
    fltOrgDate: string;
    position: string;
    arrivalStation: string;
    arrivalDate: string;
  }[];
  needRecalculate: boolean;
  paxLoadNeeded: boolean;
  sabrePrinterNeeded: boolean;
  siteMinderEmpId: number;
  uniqueValue?: string;
};

export type GetFlightInfoV3Response = CoreServiceBaseResponse & {
  flightsResultArray: {
    inboundFltInfo: string;
    flightPlanLocked: boolean;
    flightPlanLockedMsg: string;
    flightPlanSignStatus: string;
    ffdSignStatus: string;
    cockpitCrewMemberArray: {
      crewBase: string;
      crewType: string;
      empFName: string;
      empLName: string;
      empNo: string;
      flightType: string;
      position: string;
      seqDate: string;
      seqNumber: string;
    }[];
    cabinCrewMemberArray: {
      crewBase: string;
      crewType: string;
      empFName: string;
      empLName: string;
      empNo: string;
      flightType: string;
      position: string;
      seqDate: string;
      seqNumber: string;
    }[];
    age60Array: string[];
    arrGate: string;
    crewMemberData: {
      crewBase: string;
      crewType: string;
      empFName: string;
      empLName: string;
      empNo: string;
      flightType: string;
      position: string;
      seqDate: string;
      seqNumber: string;
    };
    depGate: string;
    depStatus: string;
    fltIndex: number;
    fltLegStatus: string;
    fltNumber: number;
    flyingTime: string;
    gmtArrDateTimeCal: string;
    gmtDepDateTimeCal: string;
    localLTA: string;
    localLTD: string;
    localSTA: string;
    localSTD: string;
    paxLoad: string;
    flightClass: string;
    pilotinCommand: number;
    tailNumber: string;
    notificationArray: {
      messageBody: string;
      ackRequired: boolean;
      title: string;
      messageId: string;
    }[];
    kickOffFlt: string;
    flightKeysFlight: boolean;
    flightKeysFPVersion: string;
    sabrePrinterArray: {
      station: string;
      gate: string;
      printerAddress: string;
      description: string;
    }[];
    walkAroundIndicator: string;
    gndtime: string;
  }[];
  tripSignInData: {
    tripSignInMessage: string;
    signedIn: boolean;
    signInEligible: boolean;
    signInAirport: string;
    baseCode: string;
    signInLocalTime: string;
    standByGate: string;
    goodDutyPeriod: number;
    goodLeg: number;
    signInLeg: boolean;
    firstGoodLegSignedIn: boolean;
    sequenceNumber: string;
    sequenceOriginationDate: string;
    standBy: boolean;
  };
};

export type GetFlightInfoV4Request = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken?: string;
  uniqueValue?: string;
  sabrePrinterNeeded: boolean;
  cockpitListNeeded: boolean;
  flightsRqArray: [
    {
      depDupCode: string;
      depStation: string;
      fltIndex: number;
      fltNumber: number;
      fltOrgDate: string;
      position: string;
      arrivalStation: string;
      arrivalDate: string;
    },
  ];
  flightSequenceInfo: {
    sequenceNumber: number;
    sequenceOriginationDate: string;
    sequencePosition: string;
    sequenceContractMonth: string;
  } | null;
  needRecalculate: boolean;
  paxLoadNeeded: boolean;
};

export type GetFlightInfoV4Response = CoreServiceBaseResponse & {
  flightsResultArray: {
    inboundFltInfo: string;
    flightPlanLocked: boolean;
    flightPlanLockedMsg: string;
    flightPlanSignStatus: string;
    ffdSignStatus: string;
    cockpitCrewMemberArray: {
      crewBase: string;
      crewType: string;
      empFName: string;
      empLName: string;
      empNo: string;
      flightType: string;
      position: string;
      seqDate: string;
      seqNumber: string;
    }[];
    cabinCrewMemberArray: {
      crewBase: string;
      crewType: string;
      empFName: string;
      empLName: string;
      empNo: string;
      flightType: string;
      position: string;
      seqDate: string;
      seqNumber: string;
    }[];
    age60Array: string[];
    arrGate: string;
    crewMemberData: {
      crewBase: string;
      crewType: string;
      empFName: string;
      empLName: string;
      empNo: string;
      flightType: string;
      position: string;
      seqDate: string;
      seqNumber: string;
    };
    depGate: string;
    depStatus: string;
    fltIndex: number;
    fltLegStatus: string;
    fltNumber: number;
    flyingTime: string;
    gmtArrDateTimeCal: string;
    gmtDepDateTimeCal: string;
    localLTA: string;
    localLTD: string;
    localSTA: string;
    localSTD: string;
    paxLoad: string;
    flightClass: string;
    pilotinCommand: number;
    tailNumber: string;
    notificationArray: {
      messageBody: string;
      ackRequired: boolean;
      title: string;
      messageId: string;
    }[];
    kickOffFlt: string;
    flightKeysFlight: boolean;
    flightKeysFPVersion: string;
    sabrePrinterArray: {
      station: string;
      gate: string;
      printerAddress: string;
      description: string;
    }[];
    walkAroundIndicator: string;
    gndtime: string;
  }[];
  tripSignInData: {
    tripSignInMessage: string;
    signedIn: boolean;
    signInEligible: boolean;
    signInAirport: string;
    baseCode: string;
    signInLocalTime: string;
    standByGate: string;
    goodDutyPeriod: number;
    goodLeg: number;
    signInLeg: boolean;
    firstGoodLegSignedIn: boolean;
    sequenceNumber: string;
    sequenceOriginationDate: string;
    standBy: boolean;
  };
};

export type GetHotelLimoInfoRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken: string;
  dupDepIndicator: string;
  empIdLogin: number;
  fltArrDate: string;
  fltArrSta: string;
  fltDepSta: string;
  fltNumber: string;
  fltOrigDate: string;
  fltScheduledArrivalDate: string;
  legPosition: string;
  sequenceContractMonth: string;
  sequencePosition: string;
  siteMinderEmpId: number;
  travellianceEnabledDate: string;
  uniqueValue: string;
};

export type GetHotelLimoInfoResponse = {
  hotelInfo: {
    name: string;
    phone: string;
  };
  limoInfo: {
    limoCompany: string;
    limoPhoneNumber: string;
    limoInstructions: string;
  };
  success: boolean;
  errorMessage: string;
};

export type GetProbyReportRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken: string;
  emailAddress: string;
  empIdLogin: number;
  endDate: string;
  isOnlyPoor: boolean;
  isOnlySubmitted: boolean;
  probySearchEmployeeNumber: number;
  siteMinderEmpId: number;
  startDate: string;
  surveyId: number;
  updateProbyDelFlag: number;
};

export type GetProbyReportResponse = CoreServiceBaseResponse & {
  probyReportList: {
    captainEmployeeNumber: number;
    captainSequenceNumber: number;
    captainSequenceOrigDate: string;
    chiefPhoneNumber: number;
    isPoor: boolean;
    isProbyDeleteFlag: boolean;
    probyEmployeeNumber: number;
    probyReviewDate: string;
    probyReviewType: string;
    probySequenceNumber: number;
    probySequenceOrigDate: string;
    surveyId: number;
    surveySubmittedTimeStamp: string;
  }[];
};

export type GetProbySurveyRequest = CoreServiceBaseRequest;

export type GetProbySurveyResponse = CoreServiceBaseResponse & {
  reviewType: string[];
  probyCategories: {
    name: string;
    title: string;
    description: string;
    probySurvey: {
      title: string;
      questionId: number;
      description: string;
      order: number;
      probyOptions: {
        optionName: string;
        optionDescription: string;
        needComments: boolean;
      }[];
    }[];
  }[];
};

export type GetProbySurveyDetailsRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken: string;
  emailAddress: string;
  empIdLogin: number;
  endDate: string;
  isOnlyPoor: boolean;
  isOnlySubmitted: boolean;
  probySearchEmployeeNumber: number;
  siteMinderEmpId: number;
  startDate: string;
  surveyId: number;
  updateProbyDelFlag: number;
};

export type GetProbySurveyDetailsResponse = string;

export type GetProbySurveyResponsesAsPdfRequest = {
  // ! NOT IMPLEMENTED / NEED TO TEST SERVICE TO SEE WHAT RESPONSE TYPE IS WHEN THIS IS NEEDED
};

export type GetProbySurveyResponsesAsPdfResponse = {
  // ! NOT IMPLEMENTED / NEED TO TEST SERVICE TO SEE WHAT RESPONSE TYPE IS WHEN THIS IS NEEDED
};

export type GetSequenceInfoRequest = {
  airlineCode: string;
  appSessionId: string;
  businessUnit: BusinessUnit;
  deviceToken: string;
  empIdLogin: number;
  flightSequenceInfo: {
    sequenceNumber: number;
    sequenceOriginationDate: string;
    sequencePosition: string;
    sequenceContractMonth: string;
  };
  siteMinderEmpId: number;
  uniqueValue: string;
};

export type GetSequenceInfoResponse = CoreServiceBaseResponse & {
  sequenceInfo: {
    addCode: string;
    airlineCode: string;
    base: string;
    cabinType: string;
    contractMonth: string;
    creditNextMonth: number;
    creditThisMonth: number;
    division: string;
    durationInDays: number;
    employeeID: number;
    equipmentGroup: string;
    equipmentQuals: {
      equipmentGroup: string;
      equipmentNumber: string;
      equipmentType: string;
    }[];
    failsContinuity: boolean;
    firstLegDeadHeadIndicator: boolean;
    firstLegDepartureAirport: string;
    firstLegDepartureTime: {
      baseTime: string;
      gmt: string;
      localTime: string;
    };
    flightDutyPeriods: {
      baseIndicator: string;
      crewMeal: boolean;
      duration: number;
      dutyPeriodNumber: number;
      endDateTime: {
        baseTime: string;
        gmt: string;
        localTime: string;
      };
      flightLegs: {
        actual: {
          arrivalAirport: string;
          arrivalDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
          departureAirport: string;
          departureDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
        };
        airlineCode: string;
        arrivalDuplicateCode: number;
        baseIndicator: string;
        blockTime: number;
        crewHotel: {
          departureDate: string;
          departureStation: string;
          departureStationDupe: number;
          dutyPeriodNumber: number;
          flightNumber: string;
          index: number;
          name: string;
          phone: string;
          positions: string[];
        };
        deadHead: boolean;
        deadHeadAirlineCode: string;
        departureDuplicateCode: number;
        departureStation: string;
        equipmentQuals: {
          equipmentGroup: string;
          equipmentNumber: string;
          equipmentType: string;
        };
        flightNumber: number;
        flightOriginationDate: string;
        groundTime: number;
        legIndex: number;
        legType: string;
        limoDetails: {
          departureDate: string;
          departureStation: string;
          departureStationDupe: number;
          dutyPeriod: number;
          flightNumber: string;
          inboundLimoCompany: {
            name: string;
            phone: string;
          };
          index: number;
          outboundLimoCompany: {
            name: string;
            phone: string;
          };
          positionsInLimo: string[];
        };
        noShowDHD: boolean;
        reScheduled: {
          arrivalAirport: string;
          arrivalDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
          departureAirport: string;
          departureDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
        };
        scheduled: {
          arrivalAirport: string;
          arrivalDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
          departureAirport: string;
          departureDateTime: {
            baseTime: string;
            gmt: string;
            localTime: string;
          };
        };
        status: string;
        visaCountry: string;
        visaIndicator: string;
        arrivalStation: string;
        endOfDutyPeriod: boolean;
        cancelled: boolean;
        removed: boolean;
        endOfSequence: boolean;
        active: boolean;
      }[];
      layOverAirport: string;
      layoverInMinutes: number;
      layoverStation: string;
      numberOfLegs: number;
      odMinutes: number;
      payCredit: {
        actualCredit: number;
        actualFlight: number;
        actualTotalCredit: number;
        deadheadCredit: number;
        greaterTime: number;
        scheduledCredit: number;
        scheduledFlight: number;
        scheduledTotalCredit: number;
      };
      startDateTime: {
        baseTime: string;
        gmt: string;
        localTime: string;
      };
    }[];
    galleyPosition: boolean;
    hotelInfo: {
      errors: {
        businessContext: string;
        compensationInfo: string;
        errorCode: string;
        errorMessage: string;
        errorReference: string;
        httpStatusCode: string;
        severity: string;
        success: boolean;
      }[];
      name: string;
      phone: string;
      positionCode: string;
      positionsInHotel: string[];
      sequenceNumber: number;
      sequenceOriginationDate: string;
      success: boolean;
    }[];
    international: boolean;
    isCoterminal: boolean;
    isIPD: boolean;
    isODAN: boolean;
    isRedEye: boolean;
    isRedFlag: boolean;
    isSatellite: boolean;
    isTrainingSequence: boolean;
    lastLegDeadHeadIndicator: boolean;
    layoverStations: string;
    legsPerDutyPeriod: string;
    numberOfLegs: number;
    multipleEquipments: boolean;
    positionCode: string;
    positionSequenceInfo1: string;
    positionSequenceInfo1Binary: string;
    positionSequenceInfo2: string;
    quals: string;
    removalCode: string;
    rigs: {
      rigDay: number;
      rigNight: number;
      rigType: string;
    }[];
    ronCities: string[];
    scheduledFlight: number;
    sequenceBlockTime: number;
    sequenceDutyTime: string;
    sequenceEndDateTime: {
      baseTime: string;
      gmt: string;
      localTime: string;
    };
    sequenceNumber: number;
    sequenceOriginDate: string;
    sequencePayCredit: {
      actualCredit: number;
      actualFlight: number;
      actualSeqCredit: number;
      actualTotalCredit: number;
      deadheadCredit: number;
      greaterTime: number;
      scheduledCredit: number;
      scheduledFlight: number;
      scheduledSeqCredit: number;
      scheduledTotalCredit: number;
    };
    sequencePayTime: number;
    sequenceStartDateTime: {
      baseTime: string;
      gmt: string;
      localTime: string;
    };
    serviceQuals: {
      description: string;
      number: number;
    }[];
    speakerRequirement: {
      posLangQual: string;
      posSpeakerRestricted: boolean;
      seqLangQuals: string[];
      totalMissingForCabin: number;
      totalMissingForSeq: number;
      totalRequiredForCabin: number;
      totalRequiredForSeq: number;
    };
    timeAwayFromBase: number;
    timeOpened: string;
    totalLegCount: number;
    signedIn: boolean;
    sequenceStatus: string;
    signInTimeInGMT: string;
    ttotday: {
      seqDate: string;
      seqDropType: string;
      seqNumber: number;
      seqPosted: string;
      seqStatus: string;
    };
  }[];
};

export type GetStartSessionRequest = {
  clientId: string;
  clientType: string;
  clientVersion: number;
  deviceOSType: string;
  deviceToken: string;
  isFCM: boolean;
  sessionRefresh: boolean;
  smEmpId: number;
  smPwd: string;
};

export type GetStartSessionResponse = CoreServiceBaseResponse & {
  statusCode: number;
  smSessionData: string;
  mobileVersion: {
    downloadedUrlLocation: string;
    upgradeAction: number;
  };
  buArray: {
    active: boolean;
    admin: boolean;
    airlineCode: string;
    betaUser: boolean;
    bu: string;
    calendarAdjustmentEnabled: boolean;
    emulate: boolean;
    flightKeysFlightEnabled: boolean;
    mailBox: string;
    masterAdmin: boolean;
    readAndSignEnabled: boolean;
    swsenabled: boolean;
    travellianceEnabledDate: string;
    ttotEnabled: boolean;
    defaultBU: boolean;
  }[];
};

export type GetStartSessionV2Request = {
  // ! these are typed this way on purpose to prevent you from misusing the request
  clientId: 'WEB';
  clientType: '';
  clientVersion: '';
  deviceOSType: '';
  fcm: true;
  sessionRefresh: false;
  //*
  deviceToken: string;
  empIdLogin: number;
};

export type GetStartSessionV2Response = CoreServiceBaseResponse & {
  statusCode: number;
  smSessionData: string;
  mobileVersion: {
    downloadedUrlLocation: string;
    upgradeAction: number;
  };
  buArray: {
    active: boolean;
    admin: boolean;
    airlineCode: string;
    betaUser: boolean;
    bu: string;
    calendarAdjustmentEnabled: boolean;
    defaultBU: boolean;
    emulate: boolean;
    flightKeysFlightEnabled: boolean;
    mailBox: string;
    masterAdmin: boolean;
    readAndSignEnabled: boolean;
    swsenabled: boolean;
    travellianceEnabledDate: string;
    ttotEnabled: boolean;
  }[];
};

export type GetSurveyDetailsBySurveyIdRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  surveyId: number;
};

export type GetSurveyDetailsBySurveyIdResponse = CoreServiceBaseResponse & {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  surveyId: number;
  reviewType: string;
  chiefPhoneNumber: number;
  surveyResponseRequest: {
    questionId: number;
    responseOption: string;
    comments: string;
  }[];
  captainEmpNumber: number;
  probyEmpNumber: number;
  captainSeqNumber: number;
  probySeqNumber: number;
  captainSeqOrigDate: string;
  probySeqOrigDate: string;
  reviewDate: string;
  createdBy: number;
  createdDateTime: string;
  surveySubmittedTimestamp: string;
  surveyResponseRequestCategories: {
    categoryId: number;
    categoryTitle: string;
    surveyResponseRequest: {
      questionId: number;
      responseOption: string;
      comments: string;
      questionTitle: string;
      questionOrder: number;
    }[];
  }[];
};

export type GetSWSLogoutSessionRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  commonRequestArray: {
    flightRequestFlightData: {
      depDupCode: string;
      depStation: string;
      fltIndex: number;
      fltNumber: number;
      fltOrgDate: string;
      position: string;
      arrivalStation: string;
      arrivalDate: string;
    };
    commandId: number;
    keyword: string;
    keywordDisplay: string;
    parameters: string[];
    printOrder: number;
    systemName: string;
    deleteHI6Message: boolean;
    firstDeleteHI6Message: boolean;
    print: boolean;
    view: boolean;
    flightNumber: string;
    departureStation: string;
  }[];
  printerLNIATA: string;
  enableDeepLinking: boolean;
};

export type GetSWSLogoutSessionResponse = CoreServiceBaseResponse;

export type GetTripSignInInfoV3Request = CoreServiceBaseRequest;

export type GetTripSignInInfoV3Response = CoreServiceBaseResponse & {
  tripSignInData: V3TripSignInData;
};

export type V3TripSignInData = {
  authorizedDesktopMachine: boolean;
  baseCode: string;
  departureStation: string;
  flightNumber: string;
  flightOriginDate: string;
  sequenceNumber: string;
  sequenceOriginationDate: string;
  signedIn: boolean;
  signInAirport: string;
  signInEligible: boolean;
  signInLink: boolean;
  signInLocalTime?: string;
  standBy: boolean;
  standByGate: string;
  tripSignInMessage: string;
};

export type GetUserDetailsByBuRequest = CoreServiceBaseRequest;

export type GetUserDetailsByBuResponse = {
  employeeID: number;
  siteMinderID: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  businessUnit: {
    bu: BusinessUnit;
    airlineCode: string;
    emulate: boolean;
    betaUser: boolean;
    mailBox: string;
    ttotEnabled: boolean;
    readAndSignEnabled: boolean;
    calendarAdjustmentEnabled: boolean;
    travellianceEnabledDate: string;
    active: boolean;
    admin: boolean;
    swsenabled: boolean;
    flightKeysFlightEnabled: boolean;
    masterAdmin: boolean;
    defaultBU: boolean;
  };
  optInTimeStamp: string;
  websites: CoreServiceWebsite[];
  // other properties of CoreServiceBaseResponse are not included here.
  success: boolean;
  errorMessage: string;
};

export type CoreServiceWebsite = {
  id: number;
  name: string;
  url: string;
  effDate: string;
  endTime: string;
  sortOrder: number;
  showInCompanyDevice: boolean;
  intranet: boolean;
  internet: boolean;
};
export type GetUserDetailsForHomeV2Request = {
  airlineCode?: string;
  businessUnit?: BusinessUnit;
  empIdLogin?: string;
  appSessionId?: string;
  siteMinderEmpId?: number;
  deviceToken?: string;
  uniqueValue?: string;
  hi6MessageRequest?: {
    airlineCode?: string;
    businessUnit?: BusinessUnit;
    empIdLogin?: number;
    appSessionId?: string;
    siteMinderEmpId?: number;
    deviceToken?: string;
    uniqueValue?: string;
    commonRequestMsg?: {
      flightRequestFlightData?: {
        depDupCode?: string;
        depStation?: string;
        fltIndex?: number;
        fltNumber?: number;
        fltOrgDate?: string;
        position?: string;
        arrivalStation?: string;
        arrivalDate?: string;
      };
      commandId?: number;
      keyword?: string;
      keywordDisplay?: string;
      parameters?: string[];
      printOrder?: number;
      systemName?: string;
      deleteHI6Message?: boolean;
      firstDeleteHI6Message?: boolean;
      print?: boolean;
      view?: boolean;
      flightNumber?: string;
      departureStation?: string;
    };
    printerLNIATA?: string;
  };
  employeeMessageRequest?: {
    airlineCode?: string;
    businessUnit?: BusinessUnit;
    empIdLogin?: number;
    appSessionId?: string;
    siteMinderEmpId?: number;
    deviceToken?: string;
    uniqueValue?: string;
    contractMonth?: string;
    crewBase?: string;
    crewDivision?: string;
    crewEquipment?: string;
    crewSeat?: string;
    checkAirman?: boolean;
    fsmSupervisorNumber?: string;
    admin?: boolean;
    mailBox?: string;
    checkAirmanType?: string;
    includeBody?: boolean;
    departureStations?: string[];
    depatureStations?: object[];
  };
};

export type GetUserDetailsForHomeV2Response = {
  msgCount: number;
  empMessages: {
    businessUnit: BusinessUnit;
    empIdLogin: number;
    errorMessage: string[];
    messageAction: string;
    success: boolean;
    errorInfo: {
      success: boolean;
      errorCode: string;
      errorMessage: string;
      httpStatusCode: string;
      severity: string;
      businessContext: string;
      errorReference: string;
      validationFailureMessages: string[];
    };
    totalMessageCount: number;
    unreadMessageCount: number;
    unreadReadandSignCount: number;
    messageDataArray: {
      acknowledged: boolean;
      acknowledgeRequired: number;
      action: string;
      activityDate: string;
      activityEvent: string;
      alertMsgPosition: string;
      buCode: string;
      delDate: string;
      delEmpId: number;
      effectiveDate: string;
      empId: number;
      endDate: string;
      insDate: string;
      insEmpId: number;
      messageBody: string;
      messageId: number;
      messageRead: number;
      messageReadDate: string;
      messageReadUnique: number;
      messageTab: string;
      messageTargetArray: {
        buCode: string;
        messageId: number;
        targetCode: string;
        targetType: string;
        updDate: string;
        updEmpId: number;
      }[];
      messageTitle: string;
      msgFirstReadDate: string;
      msgLastReadDate: string;
      priorityNumber: number;
      siteMinderEmpId: number;
      updDate: string;
      updEmpId: number;
      emulated: boolean;
      messageDeleted: boolean;
      messageExpired: boolean;
      messageSignature: string;
      acknowledgementRequired: boolean;
    }[];
    crewMsgSearchData: {
      depStationList: string[];
      mailbox: string;
      fsmSupervisorNum: number;
      contractMonth: string;
      admin: boolean;
      checkAirman: boolean;
      crewType: string;
      crewBase: string;
      seat: string;
      div: string;
      equip: string;
      checkAirmanType: string;
    };
  };
  probyResponse: {
    success: boolean;
    probyList: {
      surveyId: number;
      probyDisplayInformation: string;
      captainFirstName: string;
      captainLastName: string;
      captainEmpNumber: number;
      probyFirstName: string;
      probyLastName: string;
      reviewDate: string;
      startDate: string;
      probyEmpNumber: number;
      probyLinkValidUntil: string;
      probyURL: string;
      probyDeleteFlag: number;
    }[];
  };
};

export type JpyServiceRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  flightSequenceInfo: {
    sequenceNumber: number;
    sequenceOriginationDate: string;
    sequencePosition: string;
    sequenceContractMonth: string;
  };
  flightIndex: number;
  commonRequestMsg: {
    flightRequestFlightData: {
      depDupCode: string;
      depStation: string;
      fltIndex: number;
      fltNumber: number;
      fltOrgDate: string;
      position: string;
      arrivalStation: string;
      arrivalDate: string;
    };
    commandId: number;
    keyword: string;
    keywordDisplay: string;
    parameters: string[];
    printOrder: number;
    systemName: string;
    deleteHI6Message: boolean;
    firstDeleteHI6Message: boolean;
    print: boolean;
    view: boolean;
    flightNumber: string;
    departureStation: string;
  };
};

export type JpyServiceResponse = CoreServiceBaseResponse & {
  commonResult: {
    commandId: number;
    departureStation: string;
    errorMsg: string;
    flightNumber: string;
    keyword: string;
    keywordDisplay: string;
    kvaMessages: string[];
    kvaMsgCount: number;
    kvarReturnData: string;
    print: boolean;
    printOrder: number;
    returnData: string;
    sequenceNumber: string;
    success: boolean;
    view: boolean;
  };
  flightPlanSignedIn: boolean;
  validEmp: boolean;
};

export type LogDeviceDetailRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  appVersion: string;
  errorDetail: string;
  responseReferred: string;
};

export type LogDeviceDetailResponse = {
  entity: object;
  status: number;
  metadata: object;
};

export type ManageEmployeeFavCommandsRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  empFavCmds: {
    commandId: number;
    commandOrder: number;
  }[];
};

export type ManageEmployeeFavCommandsResponse = CoreServiceBaseResponse & {
  empFavCmds: {
    empFavCommands: {
      commandId: number;
      commandOrder: number;
    }[];
  };
};

export type ManageEmpPrintPreferencesRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  empGrpPrintPrefData: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    printPreferenceGroupIds: number[];
    action: string;
    buCode: string;
    empPrintPrefArray: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
    empPrintPrefGrpId: number;
    groupName: string;
    showCmdF3PLC: string;
    updDate: string;
    defaultInd: boolean;
    groupOrder: number;
    empPrintPrefList: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
  };
  groupOrderList: {
    empPrintPrefGrpId: number;
    groupOrder: number;
  }[];
};

export type ManageEmpPrintPreferencesResponse = CoreServiceBaseResponse & {
  empGrpPrintPrefData: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    printPreferenceGroupIds: number[];
    action: string;
    buCode: string;
    empPrintPrefArray: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
    empPrintPrefGrpId: number;
    groupName: string;
    showCmdF3PLC: string;
    updDate: string;
    defaultInd: boolean;
    groupOrder: number;
    empPrintPrefList: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
  };
  groupOrderList: [
    {
      empPrintPrefGrpId: number;
      groupOrder: number;
    },
  ];
};

export type MobileGetMessageRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  crewMsgSearchData: {
    depStationList: string[];
    mailbox: string;
    fsmSupervisorNum: number;
    contractMonth: string;
    admin: boolean;
    checkAirman: boolean;
    crewType: string;
    crewBase: string;
    seat: string;
    div: string;
    equip: string;
    checkAirmanType: string;
  };
};

export type MobileGetMessageResponse = CoreServiceBaseResponse & {
  totalMessageCount: number;
  unreadMessageCount: number;
  unreadReadandSignCount: number;
  messageDataArray: {
    acknowledged: boolean;
    acknowledgeRequired: number;
    action: string;
    activityDate: string;
    activityEvent: string;
    alertMsgPosition: string;
    buCode: string;
    delDate: string;
    delEmpId: number;
    effectiveDate: string;
    empId: number;
    endDate: string;
    insDate: string;
    insEmpId: number;
    messageBody: string;
    messageId: number;
    messageRead: number;
    messageReadDate: string;
    messageReadUnique: number;
    messageTab: string;
    messageTargetArray: {
      buCode: string;
      messageId: number;
      targetCode: string;
      targetType: string;
      updDate: string;
      updEmpId: number;
    }[];
    messageTitle: string;
    msgFirstReadDate: string;
    msgLastReadDate: string;
    priorityNumber: number;
    siteMinderEmpId: number;
    updDate: string;
    updEmpId: number;
    emulated: boolean;
    messageDeleted: boolean;
    messageExpired: boolean;
    messageSignature: string;
    acknowledgementRequired: boolean;
  }[];
  crewMsgSearchData: {
    depStationList: string[];
    mailbox: string;
    fsmSupervisorNum: number;
    contractMonth: string;
    admin: boolean;
    checkAirman: boolean;
    crewType: string;
    crewBase: string;
    seat: string;
    div: string;
    equip: string;
    checkAirmanType: string;
  };
};

export type RefreshAppInfoRequest = {
  smEmpId: number;
  smPwd: string;
  clientType: string;
  clientVersion: number;
  deviceToken: string;
  clientId: string;
  isFCM: boolean;
  sessionRefresh: boolean;
  deviceOSType: string;
};

export type RefreshAppInfoResponse = {
  versionNumber: string;
  success: boolean;
  errorMessage: boolean;
};

export type ReorderEmpPrintPreferencesV1Request = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  empGrpPrintPrefData: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    printPreferenceGroupIds: number[];
    action: string;
    buCode: string;
    empPrintPrefArray: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
    empPrintPrefGrpId: number;
    groupName: string;
    showCmdF3PLC: string;
    updDate: string;
    defaultInd: boolean;
    groupOrder: number;
    empPrintPrefList: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
  };
  groupOrderList: {
    empPrintPrefGrpId: number;
    groupOrder: number;
  }[];
};

export type ReorderEmpPrintPreferencesV1Response = CoreServiceBaseResponse & {
  empGrpPrintPrefData: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    printPreferenceGroupIds: number[];
    action: string;
    buCode: string;
    empPrintPrefArray: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
    empPrintPrefGrpId: number;
    groupName: string;
    showCmdF3PLC: string;
    updDate: string;
    defaultInd: boolean;
    groupOrder: number;
    empPrintPrefList: {
      commandId: number;
      printOrder: number;
      printAll: boolean;
      action: string;
      checklistItem: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      empId: number;
      empPrintPrefGrpId: number;
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
    }[];
  };
  groupOrderList: {
    empPrintPrefGrpId: number;
    groupOrder: number;
  }[];
};

export type RetrieveProbyInformationRequest = CoreServiceBaseRequest;

export type RetrieveProbyInformationResponse = {
  success: boolean;
  probyList: {
    surveyId: number;
    probyDisplayInformation: string;
    captainFirstName: string;
    captainLastName: string;
    captainEmpNumber: number;
    probyFirstName: string;
    probyLastName: string;
    reviewDate: string;
    startDate: string;
    probyEmpNumber: number;
    probyLinkValidUntil: string;
    probyURL: string;
    probyDeleteFlag: number;
  }[];
};

export type SearchFlightsRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit | undefined;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken?: string;
  uniqueValue?: string;
  airline: string;
  arrStation: string;
  depStation: string;
  emp: number;
  fltDepDate: string;
  fltNumber: number;
  crew: boolean;
  paxLoadNeeded: boolean;
};

export type SearchFlightsResponse = {
  empIdLogin: number;
  success: boolean;
  legDetailArray: {
    acType: string;
    arrGate: string;
    arrStation: string;
    depDupCode: string;
    depGate: string;
    depStation: string;
    depStatus: string;
    eqpType: string;
    fltNumber: number;
    fltOrigDate: string;
    flyingTime: string;
    gmtLTA: string;
    gmtLTD: string;
    legStatus: string;
    localLTA: string;
    localLTD: string;
    localSTA: string;
    localSTD: string;
    partition: string;
    paxLoad: string;
    flightClass: string;
    pilotinCommand: number;
    tailNumber: string;
    timeZoneDiff: string;
    kickOffFlt: string;
  }[];
};

export type SendFeedbackEmailRequest = {
  appVersion: string;
  buCode: string;
  deviceOS: string;
  emailBody: string;
  employeeNumber: number;
  osVersion: string;
};

export type SendFeedbackEmailResponse = {
  success: boolean;
  errorCode: number;
  errorMessage: string;
};

export type SendFeedbackEmailV2Request = {
  employeeNumber: number;
  buCode?: string;
  appVersion: string;
  deviceOS: string;
  osVersion: string;
  emailBody: string;
  isFeedback: boolean;
  feedback: boolean;
  subject: string;
  // ! THESE ARE MISSPELLED API SIDE
  toRecepients: string[];
  ccRecepients: string[];
};

export type SendFeedbackEmailV2Response = {
  success: boolean;
  errorCode: number;
  errorMessage: string;
};

export type ServiceGetDBFunctionsRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  printerLocationRQ: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    printerAddress: string;
    station: string;
    area: string;
    gate: string;
  };
  buQuals: {
    airlineCode: string;
    businessUnit: BusinessUnit;
    empIdLogin: number;
    appSessionId: string;
    siteMinderEmpId: number;
    deviceToken: string;
    uniqueValue: string;
    buCode: string;
    qualCode: string;
    qualCodeArray: string[];
    qualType: string;
    qualTypeArray: string[];
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  mobileVersion_clientType: string;
  screenConfigData: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    bu_screenCode: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dataField_screenCode: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_screenCode: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    bu_dataGridConfig: boolean;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    user_dataGridConfig: boolean;
  };
  whichDBfunctions: string[];
};

export type ServiceGetDBFunctionsResponse = CoreServiceBaseResponse & {
  printerLocationArray: {
    action: string;
    setLastUsage: boolean;
    printerAddress: string;
    station: string;
    gate: string;
    area: string;
    description: string;
    entryType: string;
    printerLocationId: number;
    usageCount: number;
    updDate: string;
    updEmpId: number;
    lastUsedDate: string;
    lastUsedEmpId: number;
    lastUsedEntry: string;
    crewType: string;
  }[];
  businessDataRS: {
    businessUnit: BusinessUnit;
    empIdLogin: number;
    errorMessage: string[];
    messageAction: string;
    success: boolean;
    errorInfo: {
      success: boolean;
      errorCode: string;
      errorMessage: string;
      httpStatusCode: string;
      severity: string;
      businessContext: string;
      errorReference: string;
      validationFailureMessages: string[];
    };
    commandMappingArray: {
      action: string;
      buCode: string;
      checklistItemF3P: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      checklistItemLC: {
        requiredCKAInd: boolean;
        chklistShowCmdF3PLC: string;
        action: string;
        buCode: string;
        checklistId: number;
        commandDescription: string;
        commandDisplayOrder: number;
        commandId: number;
        commandKeyword: string;
        commandKeywordDisplay: string;
        commandTypeCode: string;
        parametersArray: {
          action: string;
          buCode: string;
          commandParameterDesc: string;
          commandId: number;
          commandParameter: string;
          commandParameterId: number;
          displayOrder: number;
          updDate: string;
          updEmpId: number;
        }[];
        showCmdF3PLC: string;
        systemName: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        f3P: boolean;
        internetInd: boolean;
        otherPrintAllLegs: boolean;
        otherPrintLayoverLegs: boolean;
        print: boolean;
        printAllLegs: boolean;
        printLayoverLegs: boolean;
        view: boolean;
        favorite: boolean;
      };
      commandIdF3P: number;
      commandIdLC: number;
      updDate: string;
      updEmpId: number;
    }[];
    baseArray: {
      baseOrdinal: number;
      action: string;
      baseCode: string;
      baseName: string;
      baseParentCode: string;
      baseRegionCode: string;
      buCode: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
      satelliteBase: boolean;
    }[];
    buArray: {
      action: string;
      airlineCode: string;
      baseArray: {
        baseOrdinal: number;
        action: string;
        baseCode: string;
        baseName: string;
        baseParentCode: string;
        baseRegionCode: string;
        buCode: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
        satelliteBase: boolean;
      }[];
      buCode: string;
      buDataArray: {
        action: string;
        buCode: string;
        buDataCode: string;
        buDataCodeOld: string;
        buDataDescription: string;
        codeTypeCode: string;
        sortOrder: number;
        updDate: string;
        updEmpId: number;
        active: boolean;
      }[];
      buDescription: string;
      commandMappingArray: {
        action: string;
        buCode: string;
        checklistItemF3P: {
          requiredCKAInd: boolean;
          chklistShowCmdF3PLC: string;
          action: string;
          buCode: string;
          checklistId: number;
          commandDescription: string;
          commandDisplayOrder: number;
          commandId: number;
          commandKeyword: string;
          commandKeywordDisplay: string;
          commandTypeCode: string;
          parametersArray: {
            action: string;
            buCode: string;
            commandParameterDesc: string;
            commandId: number;
            commandParameter: string;
            commandParameterId: number;
            displayOrder: number;
            updDate: string;
            updEmpId: number;
          }[];
          showCmdF3PLC: string;
          systemName: string;
          updDate: string;
          updEmpId: number;
          active: boolean;
          f3P: boolean;
          internetInd: boolean;
          otherPrintAllLegs: boolean;
          otherPrintLayoverLegs: boolean;
          print: boolean;
          printAllLegs: boolean;
          printLayoverLegs: boolean;
          view: boolean;
          favorite: boolean;
        };
        checklistItemLC: {
          requiredCKAInd: boolean;
          chklistShowCmdF3PLC: string;
          action: string;
          buCode: string;
          checklistId: number;
          commandDescription: string;
          commandDisplayOrder: number;
          commandId: number;
          commandKeyword: string;
          commandKeywordDisplay: string;
          commandTypeCode: string;
          parametersArray: {
            action: string;
            buCode: string;
            commandParameterDesc: string;
            commandId: number;
            commandParameter: string;
            commandParameterId: number;
            displayOrder: number;
            updDate: string;
            updEmpId: number;
          }[];
          showCmdF3PLC: string;
          systemName: string;
          updDate: string;
          updEmpId: number;
          active: boolean;
          f3P: boolean;
          internetInd: boolean;
          otherPrintAllLegs: boolean;
          otherPrintLayoverLegs: boolean;
          print: boolean;
          printAllLegs: boolean;
          printLayoverLegs: boolean;
          view: boolean;
          favorite: boolean;
        };
        commandIdF3P: number;
        commandIdLC: number;
        updDate: string;
        updEmpId: number;
      }[];
      equipmentArray: {
        action: string;
        airlineCode: string;
        buCode: string;
        equipmentAltCode: string;
        equipmentCapacity: string;
        equipmentCode: string;
        equipmentDescription: string;
        equipmentTypeCode: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
      }[];
      navArray: {
        internet: boolean;
        intranet: boolean;
        action: string;
        effectiveDate: string;
        endDate: string;
        navlinkDataId: number;
        navlinkName: string;
        sortOrder: number;
        updDate: string;
        updEmpId: number;
        urlName: string;
      }[];
      seatArray: {
        action: string;
        buCode: string;
        seatAltCode: string;
        seatCode: string;
        seatDescription: string;
        updDate: string;
        updEmpId: number;
        active: boolean;
      }[];
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    buDataArray: {
      action: string;
      buCode: string;
      buDataCode: string;
      buDataCodeOld: string;
      buDataDescription: string;
      codeTypeCode: string;
      sortOrder: number;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    checklistArray: {
      action: string;
      buCode: string;
      checklistDescription: string;
      checklistId: number;
      checklistItemArray: CheckListItem[];
      showCmdF3PLC: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    checklistItemArray: CheckListItem[];
    codeTypeArray: {
      action: string;
      buCode: string;
      codeTypeCd: string;
      codeTypeDescription: string;
      tableName: string;
      updDate: string;
      updEmpId: number;
    }[];
    equipmentArray: {
      action: string;
      airlineCode: string;
      buCode: string;
      equipmentAltCode: string;
      equipmentCapacity: string;
      equipmentCode: string;
      equipmentDescription: string;
      equipmentTypeCode: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    fileListingArray: {
      action: string;
      buCode: string;
      delDate: string;
      delEmpFName: string;
      delEmpId: number;
      delEmpLName: string;
      fileCategory: string;
      fileId: number;
      fileName: string;
      fileSize: string;
      fileTitle: string;
      updDate: string;
      updEmpFName: string;
      updEmpId: number;
      updEmpLName: string;
      uplDate: string;
      uplEmpFName: string;
      uplEmpId: number;
      uplEmpLName: string;
      deleted: boolean;
    }[];
    navLinkArray: {
      internet: boolean;
      intranet: boolean;
      action: string;
      effectiveDate: string;
      endDate: string;
      navlinkDataId: number;
      navlinkName: string;
      sortOrder: number;
      updDate: string;
      updEmpId: number;
      urlName: string;
    }[];
    screenBuConfigDataArray: {
      action: string;
      datafieldCode: string;
      datafieldDesc: string;
      datafieldToolTip: string;
      screenDatafieldConfigId: number;
      screenDatagridCode: string;
      sortOrder: number;
      updDate: string;
      updEmpId: number;
      required: boolean;
      visible: boolean;
    }[];
    screenDatafieldConfigArray: {
      action: string;
      datafieldCode: string;
      datafieldDesc: string;
      screenDatafieldConfigId: number;
      screenDatafieldToolTip: string;
      screenDatagridCode: string;
      updDate: string;
      updEmpId: number;
      required: boolean;
    }[];
    seatArray: {
      action: string;
      buCode: string;
      seatAltCode: string;
      seatCode: string;
      seatDescription: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
    stationChecklistArray: {
      action: string;
      airlineCode: string;
      buCode: string;
      checklistDescription: string;
      checklistId: number;
      equipCode: string;
      showCmdF3PLC: string;
      station: string;
      stationChecklistId: number;
      updDate: string;
      updEmpId: number;
    }[];
    sysDataArray: {
      action: string;
      buCode: string;
      codeTypeCode: string;
      systemDataCode: string;
      systemDataDescription: string;
      updDate: string;
      updEmpId: number;
      active: boolean;
    }[];
  };
  screenBuConfigDataArray: {
    action: string;
    datafieldCode: string;
    datafieldDesc: string;
    datafieldToolTip: string;
    screenDatafieldConfigId: number;
    screenDatagridCode: string;
    sortOrder: number;
    updDate: string;
    updEmpId: number;
    required: boolean;
    visible: boolean;
  }[];
  screenDatafieldConfigArray: {
    action: string;
    datafieldCode: string;
    datafieldDesc: string;
    screenDatafieldConfigId: number;
    screenDatafieldToolTip: string;
    screenDatagridCode: string;
    updDate: string;
    updEmpId: number;
    required: boolean;
  }[];
  employeeScreenUserConfigDataArray: {
    action: string;
    datafieldCode: string;
    datafieldDesc: string;
    datafieldToolTip: string;
    screenDatafieldConfigId: number;
    screenDatagridCode: string;
    sortOrder: number;
    updDate: string;
    updEmpId: number;
    required: boolean;
  }[];
  mobileVersionArray: {
    deplDate: string;
    updDate: string;
    versionNumberFloat: number;
    updEmpId: number;
    action: string;
    clientType: string;
    urlLocation: string;
    versionNumber: number;
    upgradeAction: number;
  }[];
};

export type SubmitProbySurveyRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  surveyId: number;
  reviewType: string;
  chiefPhoneNumber: number;
  surveyResponseRequest: {
    questionId: number;
    responseOption: string;
    comments: string;
  }[];
  captainEmpNumber: number;
  probyEmpNumber: number;
  captainSeqNumber: number;
  probySeqNumber: number;
  captainSeqOrigDate: string;
  probySeqOrigDate: string;
  reviewDate: string;
  createdBy: number;
  createdDateTime: string;
  surveySubmittedTimestamp: string;
};

export type SubmitProbySurveyResponse = CoreServiceBaseResponse;

export type SubmitToIPPrintRequest = {
  airlineCode: string;
  businessUnit: BusinessUnit;
  empIdLogin: number;
  appSessionId: string;
  siteMinderEmpId: number;
  deviceToken: string;
  uniqueValue: string;
  msgId: number;
  printBuffer: string;
  printerIata: string;
  htmlMsg: boolean;
  printTransaction: string;
};

export type SubmitToIPPrintResponse = CoreServiceBaseResponse & {
  printMsgStatus: string;
};

export type SendEmailsV1Request = {
  employeeNumber: number;
  buCode: string;
  appVersion: string;
  deviceOS: string;
  osVersion: string;
  emailBody: string;
};

export type SendEmailsV1Response = {
  success: boolean;
  errorCode: number;
  errorMessage: string;
};
