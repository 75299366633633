import { Injectable, computed, signal, WritableSignal } from '@angular/core';
import {
  Calendar,
  Days,
  DaysEvent,
  EventType,
  EventCode,
  GetScheduleDetailsRequest,
  GetScheduleDetailsResponse,
  FlightDutyPeriod,
} from '../../../../shared/services/azure/calendar.service.types';
import { UserService } from '../../../../shared/services/user.service';
import { CalendarService as HttpService } from '../../../../shared/services/azure/calendar.service';
import {
  getDates,
  formatDate,
  isDateInBetween,
  getMonthInterval,
  addMonth,
  addDays,
  getNextMonthLastDay,
  getPrevMonthFirstDay,
  subtractDays,
} from '../../../../shared/utils/date.utils';
import { toggleButtonOptions, CalendarCode } from './calendar.constant';
import {
  ToggleEvents,
  ToggleButtonOption,
  EventTypeColor,
  SequenceInfo,
  CalendarEvent,
} from './calendar.types';

export const calendarMonthIndex = {
  past: 0,
  present: 1,
  future: 2,
};
@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private localStorageKey = 'toggle-event-data';

  private response: WritableSignal<GetScheduleDetailsResponse> = signal(
    {} as GetScheduleDetailsResponse,
  );

  monthIndex = signal(calendarMonthIndex.present);

  selectedEvent: WritableSignal<CalendarEvent> = signal({} as CalendarEvent);

  selectedToggleEvents: WritableSignal<ToggleEvents[]> = signal(
    this.loadLocalStoragePreference(),
  );

  calendarSelectedDate: WritableSignal<Date> = signal(new Date() as Date);

  selectedDate: WritableSignal<Date> = signal(new Date() as Date);

  selectedDay = computed(() => this.selectedDate().getDay());

  selectedMonth = computed(() => this.selectedDate().getMonth());

  selectedYear = computed(() => this.selectedDate().getFullYear());

  calendar = computed(() => this.filterCalendarDays(this.response()));

  bidStatuses = computed(() => this.response()?.bidStatuses);

  isPreviousMonthValid: WritableSignal<boolean> = signal(false);

  isNextMonthValid: WritableSignal<boolean> = signal(false);

  isPreviousDayValid: WritableSignal<boolean> = signal(false);

  isNextDayValid: WritableSignal<boolean> = signal(false);

  daysInMonth = computed(() =>
    getDates(this.selectedYear(), this.selectedMonth()),
  );

  calendarDataBeginDate = computed(() =>
    getPrevMonthFirstDay(new Date().toString()),
  );

  calendarDataEndDate = computed(() =>
    getNextMonthLastDay(new Date().toString()),
  );

  calendarMonthStartDates = computed(() => {
    const startDates = [];
    const calendarStartDate = getPrevMonthFirstDay(new Date().toString());
    startDates.push(calendarStartDate);
    startDates.push(addMonth(calendarStartDate, 1));
    startDates.push(addMonth(calendarStartDate, 2));
    return startDates;
  });

  calendarMonthEndDates = computed(() => {
    const endDates = [];
    const calendarEndDate = getNextMonthLastDay(new Date().toString());
    endDates.push(addMonth(calendarEndDate, -2));
    endDates.push(addMonth(calendarEndDate, -1));
    endDates.push(calendarEndDate);
    return endDates;
  });

  /**
   * On select today, previousday, nextday
   * @param value - today, previousday, nextday
   */
  onSelectDay(value: CalendarCode): void {
    let date = new Date();
    this.isPreviousDayValid.set(false);
    this.isNextDayValid.set(false);

    if (value === CalendarCode.TODAY) {
      this.monthIndex.set(calendarMonthIndex.present);
      this.selectedDate.set(date);
    }

    if (value === CalendarCode.PREVIOUSDAY) {
      date = this.onSelectPreviousDay(this.calendarSelectedDate(), value);
    }

    if (value === CalendarCode.NEXTDAY) {
      date = this.onSelectNextDay(this.calendarSelectedDate(), value);
    }

    this.setSelectedEvents(date);
  }

  /**
   * On change previous/next display the calendar
   */
  onChangeCalendarMonth(monthIndex: number, type: null | string): void {
    this.monthIndex.set(monthIndex);
    this.selectedDate.set(this.getDateByMonthIndex(monthIndex));
    this.setCalendarMonthsAsValid(type);
  }

  /**
   * ! Please don not remove this code - we need to use this code
   * ! If leg starts previous day and ends current day - to show the data enable this code
   */
  /* getOverlappingLegs(
    calendar: Calendar,
    day: Days,
    index: number,
    selectedDate: Date,
  ): Days {
    const previousDayData =
      calendar && calendar.days[index - 1]
        ? calendar.days[index - 1]
        : ({} as Days);
    let previousDayEventLastLeg = {} as FlightLeg;
    if (
      formatDate(day.date, 'YYYY-MM-DD') ===
      formatDate(selectedDate, 'YYYY-MM-DD')
    ) {
      day.daysEvents.filter((event: DaysEvent) => {
        if (
          event.eventType === 'S' &&
          event.lastFlightEndTime &&
          formatDate(event.lastFlightEndTime, 'YYYY-MM-DD') ===
            formatDate(selectedDate, 'YYYY-MM-DD') &&
          event.lastFlightEndsToday
        ) {
          event.sequenceActivity?.flightDutyPeriods.filter(
            (dutyPeriod: FlightDutyPeriod) => {
              if (
                dutyPeriod.flightLegs.length === 0 &&
                Object.keys(previousDayData)
              ) {
                previousDayData.daysEvents.filter((dayEvent: DaysEvent) => {
                  if (
                    dayEvent.eventType === 'S' &&
                    dayEvent.sequenceActivity &&
                    dayEvent?.sequenceActivity.sequenceNumber ===
                      event?.sequenceActivity?.sequenceNumber &&
                    dayEvent.sequenceActivity.flightDutyPeriods[0]?.flightLegs
                      ?.length > 0
                  ) {
                    previousDayEventLastLeg =
                      dayEvent.sequenceActivity.flightDutyPeriods[0]
                        ?.flightLegs[
                        dayEvent.sequenceActivity.flightDutyPeriods[0]
                          ?.flightLegs.length - 1
                      ];
                  }
                });
                dutyPeriod.flightLegs.unshift(previousDayEventLastLeg);
              }
            },
          );
        }
      });
    }
    return day;
  } */

  /**
   * ! Please don not remove the commented code
   * @param selectedDate
   */
  setSelectedEvents(selectedDate: Date): void {
    this.calendarSelectedDate.set(selectedDate);
    const calendar = this.calendar() as Calendar[];
    let daysEvents = [] as DaysEvent[];
    const sequenceData: CalendarEvent = {
      day: formatDate(selectedDate, 'YYYY-MM-DD'),
      eventData: [] as SequenceInfo[],
    };
    if (calendar && calendar.length > 0) {
      const selectedEvents = calendar[this.monthIndex()].days.find(
        (day: Days) => {
          /* this.getOverlappingLegs(
            calendar[this.monthIndex()],
            day,
            index,
            selectedDate,
          ); */
          return (
            formatDate(day.date, 'YYYY-MM-DD') ===
            formatDate(selectedDate, 'YYYY-MM-DD')
          );
        },
      );
      daysEvents = selectedEvents?.daysEvents ?? daysEvents;
      if (daysEvents.length > 0) {
        sequenceData.eventData = this.formatSelectedEventData(
          daysEvents,
          selectedDate,
        );
      }
    }
    this.selectedEvent.set(sequenceData);
  }

  /**
   * * On change toggle event visibility
   * * Called in calendar-menu.component.ts
   */
  onChangeToggleEvents($event: ToggleButtonOption): void {
    const toggleEvents = this.selectedToggleEvents() as ToggleEvents[];
    const index: number = toggleEvents.findIndex(
      (e: ToggleEvents) =>
        e.type === $event.type && e.code.toString() === $event.code.toString(),
    );
    if (index >= 0) {
      toggleEvents.splice(index, 1);
    } else if (index === -1) {
      toggleEvents.push({
        type: $event.type,
        code: $event.code,
      });
    }
    this.setLocalStoragePreference(toggleEvents);
    this.filterCalendarDays(this.response());
  }

  /**
   * * UI list the toggle event visibility
   * * Called in calendar-menu.component.ts
   */
  getToggleButtonOptions(): ToggleButtonOption[] {
    if (this.selectedToggleEvents()) {
      if (this.selectedToggleEvents().length > 0) {
        toggleButtonOptions.filter((option: ToggleButtonOption) => {
          option.checked = !!this.selectedToggleEvents().some(
            (event: ToggleEvents) =>
              event.type === option.type &&
              event.code.toString() === option.code.toString(),
          );
        });
      } else {
        toggleButtonOptions.map((option: ToggleButtonOption) => {
          option.checked = false;
        });
      }
    }
    return toggleButtonOptions;
  }

  /**
   * * show/hide the calendar events based on the toggle event visibility
   * * Training = eventType - A, eventCode - ['2R', 'TI']
   * * if this.selectedToggleEvents === undefined || null, we set all events = true
   */
  showEventBasedOnToggleSelection(event: DaysEvent) {
    event.showEvent = false;
    if (this.selectedToggleEvents()) {
      event.showEvent = false;
      if (this.selectedToggleEvents().length > 0) {
        event.showEvent = !!this.selectedToggleEvents().find(
          (selectedEvent: ToggleEvents) => {
            if (
              selectedEvent.type === EventType.A &&
              (event.eventCode === EventCode.TwoR ||
                event.eventCode === EventCode.TI)
            ) {
              return selectedEvent.code.includes(event.eventCode);
            } else if (
              selectedEvent.type === EventType.A &&
              event.eventCode !== EventCode.TwoR &&
              event.eventCode !== EventCode.TI
            ) {
              return (
                selectedEvent.type === event.eventType &&
                selectedEvent.code.length === 0
              );
            } else if (
              selectedEvent.type === EventType.S &&
              event.eventType === EventType.STANDBY
            ) {
              return selectedEvent;
            } else {
              return selectedEvent.type === event.eventType;
            }
          },
        );
      }
    }
    return event;
  }

  /**
   * Get event color based on the event type
   * @param event
   * @param day
   * @returns
   */
  getEventColor(event: DaysEvent, day: Date): EventTypeColor {
    const eventColor = {} as EventTypeColor;
    const eventType =
      event.eventType === EventType.A &&
      (event.eventCode === EventCode.TwoR || event.eventCode === EventCode.TI)
        ? EventType.TR
        : event.eventType;
    const eventStyle = this.getCalendarColor().filter(
      (style: EventTypeColor) => style.type === eventType,
    );
    if (eventStyle && eventStyle.length > 0) {
      eventColor.type = eventStyle[0].type;
      eventColor.bgColor = eventStyle[0].bgColor;
      eventColor.textColor = eventStyle[0].textColor;
      eventColor.borderColor = eventStyle[0].borderColor;
      if (
        eventStyle[0].type === EventType.S &&
        day < new Date() &&
        !isDateInBetween(
          event.start,
          event.end,
          formatDate(new Date(), 'YYYY-MM-DD'),
        )
      ) {
        eventColor.bgColor = eventStyle[0].bgColorPast ?? eventColor.bgColor;
        eventColor.textColor =
          eventStyle[0].textColorPast ?? eventColor.textColor;
        eventColor.borderColor =
          eventStyle[0].borderColorPast ?? eventStyle[0].borderColor;
      }
    }
    return eventColor;
  }

  constructor(
    private userService: UserService,
    private httpService: HttpService,
  ) {}

  private changeCalendarMonth(
    calendarMonth: Array<string>,
    date: Date,
    code: string,
  ): void {
    let monthIndex = this.monthIndex();
    const isValidMonthIndex =
      this.monthIndex() >= calendarMonthIndex.past &&
      this.monthIndex() <= calendarMonthIndex.future;
    const isdateValid = calendarMonth.some(
      (day: string) => day === formatDate(date, 'YYYY-MM-DD'),
    );
    if (isdateValid && isValidMonthIndex) {
      monthIndex = calendarMonth.findIndex(
        (day: string) => day === formatDate(date, 'YYYY-MM-DD'),
      );
      if (code === CalendarCode.PREVIOUSDAY) {
        this.onChangeCalendarMonth(monthIndex, CalendarCode.PREVIOUSMONTH);
      }
      if (code === CalendarCode.NEXTDAY) {
        this.onChangeCalendarMonth(monthIndex, CalendarCode.NEXTMONTH);
      }
    }
  }

  private getDateByMonthIndex(monthIndex: number): Date {
    let date = new Date();
    if (monthIndex === 2) {
      date = new Date(addMonth(new Date().toString(), +1));
    } else if (monthIndex === 0) {
      date = new Date(addMonth(new Date().toString(), -1));
    }
    return date;
  }

  private onSelectPreviousDay(date: Date, value: CalendarCode): Date {
    date = subtractDays(date, 1);
    this.changeCalendarMonth(this.calendarMonthEndDates(), date, value);
    if (
      formatDate(date, 'YYYY-MM-DD') ===
      formatDate(this.calendarDataBeginDate(), 'YYYY-MM-DD')
    ) {
      this.isPreviousDayValid.set(true);
    }
    return date;
  }

  private onSelectNextDay(date: Date, value: CalendarCode): Date {
    date = addDays(date, 1);
    this.changeCalendarMonth(this.calendarMonthStartDates(), date, value);
    if (
      formatDate(date, 'YYYY-MM-DD') ===
      formatDate(this.calendarDataEndDate(), 'YYYY-MM-DD')
    ) {
      this.isNextDayValid.set(true);
    }
    return date;
  }

  private setCalendarMonthsAsValid(type: null | string): void {
    const interval = getMonthInterval(new Date(), this.selectedDate());
    let prevMonthValid = false;
    let nextMonthValid = false;
    if (type === CalendarCode.PREVIOUSMONTH) {
      prevMonthValid = interval <= -1;
      nextMonthValid = false;
    } else if (type === CalendarCode.NEXTMONTH) {
      prevMonthValid = false;
      nextMonthValid = interval >= 1;
    }
    this.isPreviousMonthValid.set(prevMonthValid);
    this.isNextMonthValid.set(nextMonthValid);
  }

  private setLocalStoragePreference(selectedEvents: ToggleEvents[]): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(selectedEvents));
  }

  private getLocalStoragePreference(): ToggleEvents[] | null {
    const toggleEvents = localStorage.getItem(this.localStorageKey);
    return toggleEvents ? JSON.parse(toggleEvents) : null;
  }

  private loadLocalStoragePreference(): ToggleEvents[] {
    let events = this.getLocalStoragePreference();
    if (events === null) {
      const selectedToggleEvents = this.getAllCalendarToggleEvents();
      this.setLocalStoragePreference(selectedToggleEvents);
      events = selectedToggleEvents;
    }
    return events;
  }

  /**
   * * Filter the calendar data to match start date
   * * UI month start date == API response month start date.
   */
  private filterCalendarDays(response: GetScheduleDetailsResponse | undefined) {
    const hasCalendarResponse =
      response &&
      response.calendarResponse &&
      response.calendarResponse.length > 0;
    if (hasCalendarResponse) {
      response.calendarResponse[this.monthIndex()].days =
        response.calendarResponse[this.monthIndex()].days.filter(
          (day: Days) => {
            day.daysEvents.filter((daysEvents: DaysEvent) => {
              this.showEventBasedOnToggleSelection(daysEvents);
            });
            return (
              formatDate(day.date, 'YYYY-MM-DD') >=
              formatDate(this.daysInMonth()[0], 'YYYY-MM-DD')
            );
          },
        );
    }
    return response?.calendarResponse;
  }

  /**
   *  * Only for localStorage we use this function.
   *  * While implement API call we need to remove this function.
   */
  private getAllCalendarToggleEvents(): ToggleEvents[] {
    const eventCode = [] as ToggleEvents[];
    toggleButtonOptions.filter((option: ToggleButtonOption, index: number) => {
      if (toggleButtonOptions.indexOf(option) === index) {
        eventCode.push({
          type: option.type,
          code: option.code,
        });
      }
    });
    return eventCode;
  }

  private formatSelectedEventData(
    daysEvents: DaysEvent[],
    selectedDate: Date,
  ): SequenceInfo[] {
    const sequenceInfo: SequenceInfo[] = [];
    daysEvents.filter((event: DaysEvent) => {
      sequenceInfo.push({
        color: this.getEventColor(event, selectedDate),
        daysEvent: event as DaysEvent,
        legsCount: this.getFlightLegCount(event),
      });
    });
    return sequenceInfo;
  }

  private getFlightDutyPeriod = (
    flightDutyPeriods: FlightDutyPeriod[] | undefined,
    beginsToday: boolean,
  ): FlightDutyPeriod[] => {
    let response = [] as FlightDutyPeriod[];
    if (flightDutyPeriods && flightDutyPeriods.length > 0) {
      if (beginsToday) {
        response.push(flightDutyPeriods[0]);
      }
      if (!beginsToday) {
        response = flightDutyPeriods;
      }
    }
    return response;
  };

  private getFlightLegCount(event: DaysEvent): number {
    let flightLegCount = 0;
    if (event.eventType === EventType.S) {
      const flightDutyPeriod = this.getFlightDutyPeriod(
        event.sequenceActivity?.flightDutyPeriods,
        event.beginsToday,
      );
      if (flightDutyPeriod.length > 0) {
        flightDutyPeriod.filter((dutyPeriod: FlightDutyPeriod) => {
          if (dutyPeriod.flightLegs.length > 0) {
            flightLegCount += dutyPeriod.flightLegs.length;
          }
        });
      }
    }
    return flightLegCount;
  }

  /**
   * ! Note: We can add dark and light theme colors here
   * Used this in the `calendar-info.component.ts`
   * Response from the `/v1/getScheduleDetails` API
   * EventTypeColor are filtered with `eventType` = `S | D | VC | TR | A | C | RAP | T | SB`
   */
  private getCalendarColor(): EventTypeColor[] {
    const seqColor = {
      bgColor: `dark:bg-aluminum bg-midnight`,
      textColor: `dark:text-carbon text-white`,
      borderColor: `dark:border-aluminum border-midnight`,
      bgColorPast: `dark:bg-steel bg-aluminum`,
      textColorPast: `dark:text-aluminum text-steel`,
      borderColorPast: `dark:border-steel border-aluminum`,
    };
    return [
      {
        type: EventType.S,
        bgColor: seqColor.bgColor,
        textColor: seqColor.textColor,
        borderColor: seqColor.borderColor,
        bgColorPast: seqColor.bgColorPast,
        textColorPast: seqColor.textColorPast,
        borderColorPast: seqColor.borderColorPast,
      },
      {
        type: EventType.STANDBY,
        bgColor: seqColor.bgColor,
        textColor: seqColor.textColor,
        borderColor: seqColor.borderColor,
        bgColorPast: seqColor.bgColorPast,
        textColorPast: seqColor.textColorPast,
        borderColorPast: seqColor.borderColorPast,
      },
      {
        type: EventType.D,
        bgColor: `dark:bg-tangerine bg-nectarine`,
        textColor: `dark:text-carbon text-white`,
        borderColor: `dark:border-tangerine border-nectarine`,
      },
      {
        type: EventType.VC,
        bgColor: `bg-monarch`,
        textColor: `dark:text-carbon text-white`,
        borderColor: `dark:border-monarch border-monarch`,
      },
      {
        type: EventType.TR,
        bgColor: `dark:bg-calathea bg-zanzibar`,
        textColor: `dark:text-carbon text-white`,
        borderColor: `dark:border-calathea border-zanzibar`,
      },
      {
        type: EventType.A,
        bgColor: `dark:bg-sushi bg-afterburner`,
        textColor: `dark:text-carbon text-white`,
        borderColor: `dark:border-sushi border-afterburner`,
      },
      {
        type: EventType.T,
        bgColor: `dark:bg-sushi bg-afterburner`,
        textColor: `dark:text-carbon text-white`,
        borderColor: `dark:border-sushi border-afterburner`,
      },
      {
        type: EventType.C,
        bgColor: `dark:bg-sushi bg-afterburner`,
        textColor: `dark:text-carbon text-white`,
        borderColor: `dark:border-sushi border-afterburner`,
      },
      {
        type: EventType.RAP,
        bgColor: `dark:bg-troposphere bg-stratosphere`,
        textColor: `dark:text-carbon text-white`,
        borderColor: `dark:border-troposphere border-stratosphere`,
      },
    ];
  }

  loadCalendar(): void {
    const payload: GetScheduleDetailsRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      siteMinderEmployeeId: this.userService.employeeNumber(),
    };

    this.httpService.getScheduleDetails(payload).subscribe({
      next: (response: GetScheduleDetailsResponse) => {
        this.response.set(response);
        this.onSelectDay(CalendarCode.TODAY);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
