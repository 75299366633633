/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TokenService } from './token.service';
export type HeaderOptions = {
  Authorization: string;
  'ngsw-bypass': string;
  'Content-Type': string;
};
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  isLoading = signal(false);

  get headers() {
    return {
      Authorization: `Bearer ${this.tokenService.tokens()?.accessTokenValue}`,
      'ngsw-bypass': 'true',
    } as HeaderOptions;
  }

  get options() {
    return {
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      } as HeaderOptions,
    };
  }

  get optionsMultipart() {
    return {
      headers: {
        ...this.headers,
        'Content-Type': 'multipart/form-data',
      } as HeaderOptions,
    };
  }

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  get<T>(url: string, bypassLoader = false): Observable<T> {
    if (bypassLoader) {
      url = 'bypass://' + url;
    }
    return this.http
      .get<T>(url, this.options)
      .pipe(
        timeout(environment.azure.services.timeOut),
        retry(environment.azure.services.retryCount),
      );
  }

  getFile(url: string, bypassLoader = false): Observable<ArrayBuffer> {
    if (bypassLoader) {
      url = 'bypass://' + url;
    }
    return this.http
      .get(url, {
        ...this.options,
        responseType: 'arraybuffer',
      })
      .pipe(
        timeout(environment.azure.services.timeOut),
        retry(environment.azure.services.retryCount),
      );
  }

  postFormData<REQUEST, RESPONSE>(
    url: string,
    body: REQUEST,
    bypassLoader = false,
  ): Observable<RESPONSE> {
    if (bypassLoader) {
      url = 'bypass://' + url;
    }
    return this.http
      .post<RESPONSE>(url, body, this.optionsMultipart)
      .pipe(
        timeout(environment.azure.services.timeOut),
        retry(environment.azure.services.retryCount),
      );
  }

  post<REQUEST, RESPONSE>(
    url: string,
    body: REQUEST,
    bypassLoader = false,
  ): Observable<RESPONSE> {
    if (bypassLoader) {
      url = 'bypass://' + url;
    }
    return this.http
      .post<RESPONSE>(url, body, this.options)
      .pipe(
        timeout(environment.azure.services.timeOut),
        retry(environment.azure.services.retryCount),
      );
  }

  put<REQUEST, RESPONSE>(
    url: string,
    body: REQUEST,
    bypassLoader = false,
  ): Observable<RESPONSE> {
    if (bypassLoader) {
      url = 'bypass://' + url;
    }
    return this.http
      .put<RESPONSE>(url, body, this.options)
      .pipe(
        timeout(environment.azure.services.timeOut),
        retry(environment.azure.services.retryCount),
      );
  }
}
