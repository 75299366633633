<div class="h-full w-full" *ngIf="isUserServiceLoading">
  <crew-ui-overlay>
    <div class="flex flex-col items-center justify-center">
      <div class="mb-4 h-24 w-24">
        <crew-ui-logo [full]="false"></crew-ui-logo>
      </div>
      <crew-ui-spinner [size]="'sm'"></crew-ui-spinner>
    </div>
  </crew-ui-overlay>
</div>

<!-- Desktop Navigation -->
<div class="flex h-full w-full" *ngIf="!isUserServiceLoading">
  <aside
    class="dark:bg-carbon dark:border-charcoal border-cirrus lt-md:hidden sticky top-0 border-r border-solid px-2"
    #crewWebSideNav>
    <crew-web-side-nav
      [routerUrl]="routerUrl"
      [getCrewPath]="getCrewPath"
      [isActiveRoute]="isActiveRoute"
      (showModal)="showSettingsModal()" />
  </aside>
  <main class="dark:bg-carbon bg-cirrus h-full w-full">
    <router-outlet />
    <!-- Tablet, Mobile Navigation -->
    <div class="gt-sm:hidden xs:visible absolute bottom-0 w-full">
      <crew-web-bottom-nav
        [routerUrl]="routerUrl"
        [getCrewPath]="getCrewPath"
        [isActiveRoute]="isActiveRoute" />
    </div>
  </main>
</div>
