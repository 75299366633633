import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullMessageData } from '../../../shared/services/azure/message.service.types';
import {
  Colors,
  CrewUICardComponent,
  CrewUIDividerComponent,
  CrewUIIconComponent,
} from '@cci-web/crew-ui';
import { TimeService } from '../../../shared/services/time.service';
import { MessageIdbService } from '../../../shared/services/idb/message.idb.service';
import { Router } from '@angular/router';
type HTMLDivElementExtended = HTMLDivElement & {
  nativeElement: HTMLDivElement;
};

@Component({
  selector: 'crew-web-message-card',
  standalone: true,
  imports: [
    CommonModule,
    CrewUICardComponent,
    CrewUIIconComponent,
    CrewUIDividerComponent,
  ],
  templateUrl: './message-card.component.html',
  styleUrls: [
    './message-card.component.scss',
    '../../crew/outlets/message/message-detail-view/message-detail-view.component.scss',
  ],
})
export class MessageCardComponent implements OnInit {
  constructor(
    private timeService: TimeService,
    private messagesIdbService: MessageIdbService,
    private router: Router,
  ) {}

  @Input({ required: true })
  message!: FullMessageData;

  @ViewChild('messageBody', { static: false })
  messageBody?: HTMLDivElementExtended;

  colors = Colors;
  showMessage = false;
  acknowledgeable = false;

  ngOnInit(): void {
    this.messagesIdbService.populateMessageBody(this.message);

    setTimeout(() => {
      const interval = setInterval(() => {
        if (!this.messageBody) return;
        const overflowing = this.isOverflowY(this.messageBody.nativeElement);
        if (!overflowing) {
          this.acknowledgeable = true;
          clearInterval(interval);
        }
      }, 500);
    }, 5000);
  }

  acknowledge() {
    if (!this.acknowledgeable) return;
    this.messagesIdbService
      .acknowledgeMessage(this.message)
      .subscribe(() => this.router.navigate(['/']));
  }

  getDate(): string {
    return this.timeService.getFancyFormat(
      new Date(this.message.effectiveDate),
    );
  }

  showDateTime(date: string): string {
    return this.timeService.getMessageDateTimeLong(date);
  }

  @HostListener('scroll', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.acknowledgeable = true;
    }
  }

  private isOverflowY(element: HTMLElement) {
    return (
      element.scrollHeight !==
      Math.max(element.offsetHeight, element.clientHeight)
    );
  }
}
