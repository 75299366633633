import { Component, Input } from '@angular/core';
import { Colors, CrewUIDarkModeService } from '@cci-web/crew-ui';
import { MessageService, MessageTitles } from '../message.service';
import { MessagePriority } from '../../../../../shared/services/azure/message.service.types';

@Component({
  selector: 'crew-web-message-menu-list-item',
  templateUrl: './message-menu-list-item.component.html',
  styleUrls: [
    './message-menu-list-item.component.scss',
    './../../../../../shared/styles/scroll-bar.scss',
  ],
})
export class MessageMenuListItemComponent {
  @Input()
  priority?: MessagePriority;

  @Input()
  title?: MessageTitles;

  @Input()
  color = Colors.Black;

  @Input({ required: true })
  icon!: string;

  @Input()
  isModal = false;

  @Input()
  modalTitle?: string;

  @Input()
  messageCount?: number;

  colors = Colors;

  get selectedPriority() {
    return this.messageService.selectedMenu();
  }

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  constructor(
    private darkModeService: CrewUIDarkModeService,
    private messageService: MessageService,
  ) {}

  // count(): number {
  //   return this.messageService.getUnreadCount(this.priority) || 0;
  // }

  showMessageMenu() {
    if (this.isModal) this.showMessageModal();
    else this.messageService.selectedMenu.set(this.priority);
  }

  showMessageModal() {
    if (!this.modalTitle) return;
    this.messageService.selectedModalTitle.set(this.modalTitle);
    this.messageService.isMessageModalVisible.set(
      !this.messageService.isMessageModalVisible(),
    );
  }
}
