<div class="flex justify-between font-bold">
  <div class="dark:bg-charcoal w-full justify-between bg-white p-2">
    <div class="flex items-center px-2 dark:text-white">
      <span class="gt-md:hidden">
        <crew-web-settings-button></crew-web-settings-button>
      </span>
      <div class="ml-auto flex justify-between">
        <button
          title="Refresh schedule"
          type="button"
          (click)="onClickList($event, 'refreshCalendar')"
          class="px-2">
          <crew-ui-icon
            name="refresh"
            [color]="
              isDark ? colors.Troposphere : colors.Stratosphere
            "></crew-ui-icon>
        </button>
        <button
          title="Filter"
          type="button"
          (click)="onClickList($event, 'filter')"
          class="px-2">
          <crew-ui-icon
            name="tune"
            [color]="
              isDark ? colors.Troposphere : colors.Stratosphere
            "></crew-ui-icon>
        </button>
        <button
          title="More"
          type="button"
          class="px-2"
          (click)="onToggleMore($event)">
          <crew-ui-icon
            name="more_horiz"
            [color]="
              isDark ? colors.Troposphere : colors.Stratosphere
            "></crew-ui-icon>
        </button>
      </div>
    </div>
    <div class="flex items-center px-4 py-2 dark:text-white">
      <span class="text-[34px] font-extrabold leading-[40px]">{{
        formatDate(currentDate)
      }}</span>
      <div class="ml-auto flex justify-between">
        <button
          type="button"
          class="px-2 outline-none focus:ring-transparent focus-visible:outline-none"
          [disabled]="isPreviousMonthValid"
          (click)="onClickPreviousMonth(!isPreviousMonthValid)">
          <crew-ui-icon
            name="chevron_left"
            [color]="iconColor(isPreviousMonthValid)"></crew-ui-icon>
        </button>
        <button
          type="button"
          class="px-2 outline-none focus:ring-transparent focus-visible:outline-none"
          [disabled]="isNextMonthValid"
          (click)="onClickNextMonth(!isNextMonthValid)">
          <crew-ui-icon
            name="chevron_right"
            [color]="iconColor(isNextMonthValid)"></crew-ui-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Dropdown menu - Filter -->
<crew-ui-overlay
  *ngIf="isFilterVisible"
  (click)="onClickList($event, 'filter')">
  <div
    class="gt-xs:top-12 text-md lt-sm:bottom-0 lt-sm:left-0 lt-sm:p-2 lt-sm:my-2 lt-sm:w-full dark:bg-carbon absolute right-16 z-10 w-56 rounded-lg bg-white shadow-lg">
    <ul class="py-2 text-sm">
      <li *ngFor="let option of getToggleButtonOptions">
        <button
          type="button"
          [title]="option.name"
          (click)="stopEventPropagation($event)"
          class="hover:bg-cirrus dark:hover:bg-charcoal hover:text-carbon text-carbon flex w-full cursor-pointer items-center justify-between px-3 dark:text-white dark:hover:text-white">
          <div class="h-6 w-7 rounded-md" [ngClass]="option.color"></div>
          <div class="flex w-full items-center">
            <div class="mx-3">
              <p
                class="block text-left text-[15px] font-normal leading-[18px] dark:text-white"
                >{{ option.name }}</p
              >
            </div>
            <div class="ml-auto mt-4">
              <crew-ui-switch
                [checked]="option.checked"
                [size]="size"
                (selected)="applySwitchSettings(option)"></crew-ui-switch>
            </div>
          </div>
        </button>
      </li>
    </ul>
  </div>
</crew-ui-overlay>

<!-- Dropdown menu - More -->
<crew-ui-overlay *ngIf="toggleMore" (click)="onToggleMore($event)">
  <div
    class="gt-xs:top-12 text-md lt-sm:bottom-0 lt-sm:left-0 lt-sm:p-2 lt-sm:my-2 lt-sm:w-full dark:bg-carbon absolute right-4 z-10 w-56 rounded-lg bg-white shadow-lg">
    <ul class="py-2 text-sm">
      <li>
        <button
          type="button"
          title="HI1"
          (click)="onClickList($event, sabreKeywordDisplay.HI1)"
          class="hover:bg-cirrus dark:hover:bg-charcoal hover:text-carbon text-carbon flex w-full cursor-pointer items-center justify-between p-3 dark:text-white dark:hover:text-white">
          <div class="flex w-full items-center">
            <div class="px-1">
              <crew-ui-icon
                name="description"
                [color]="isDark ? colors.White : colors.Carbon"></crew-ui-icon>
            </div>
            <div class="ml-4 flex w-full flex-col text-left">
              <div
                class="text-carbon text-[15px] font-medium leading-[24px] dark:text-white"
                >HI1</div
              >
              <div
                class="text-nickel dark:text-steel text-left text-[12px] font-normal leading-[16px]"
                >Current month schedule</div
              >
            </div>
          </div>
        </button>
      </li>
      <li class="text-carbon dark:text-white">
        <button
          type="button"
          (click)="stopEventPropagation($event)"
          title="Dark mode"
          class="hover:bg-cirrus dark:hover:bg-charcoal hover:text-carbon flex w-full cursor-pointer items-center justify-between p-3 dark:hover:text-white">
          <div class="flex w-full items-center">
            <div class="px-1">
              <crew-ui-icon
                name="dark_mode"
                [color]="isDark ? colors.White : colors.Carbon"></crew-ui-icon>
            </div>
            <div class="ml-4 flex w-full flex-col text-left">
              <div
                class="text-carbon text-[15px] font-medium leading-[24px] dark:text-white"
                >Dark mode</div
              >
              <div
                class="text-nickel dark:text-steel text-left text-[12px] font-normal leading-[16px]"
                >{{ isDark ? 'On' : 'Off' }}</div
              >
            </div>
            <div class="dark-mode-switch px-1">
              <crew-ui-switch
                [checked]="isDark"
                (selected)="toggleDarkMode($event)"
                [size]="size"></crew-ui-switch>
            </div>
          </div>
        </button>
      </li>
    </ul>
  </div>
</crew-ui-overlay>
