import { Component, Input } from '@angular/core';
//import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
//pdfDefaultOptions.assetsFolder = 'bleeding-edge';
@Component({
  selector: 'crew-web-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @Input() fileName = `` as string;

  @Input()
  pdfString = `` as string | undefined;

  /*downloadAsPdf() {
    const linkSource = `data:application/pdf;base64,${this.pdfString}`;
    const downloadLink = document.createElement('a');
    const fileName = `${this.fileName || 'eflightplan'}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }*/

  getFileName(): string {
    return `${this.fileName || 'eflightplan'}.pdf`;
  }
}
