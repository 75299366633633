<section
  *ngIf="(sequenceInfo | json) !== '{}' && day"
  class="bg-cirrus flex flex-col px-2 dark:bg-black">
  <ng-container
    *ngIf="!sequenceInfo.daysEvent.sequenceActivity && sequenceInfo.color">
    <div class="py-4" *ngIf="sequenceInfo.color.borderColor">
      <div
        [ngClass]="sequenceInfo.color.borderColor"
        class="dark:bg-charcoal text-carbon flex rounded border-l-4 bg-white px-4 py-3 dark:text-white">
        <span class="text-[15px] font-medium leading-5">{{
          sequenceInfo.daysEvent.title | calendarEventTitle
        }}</span>
        <div
          class="ml-auto flex items-center justify-between text-[13px] font-normal leading-[18px]">
          {{
            displaySequenceTime(
              sequenceInfo.daysEvent.start,
              sequenceInfo.daysEvent.end,
              day
            )
          }}
        </div>
      </div>
    </div>
  </ng-container>
</section>
