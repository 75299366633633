import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
} from '@angular/common/http';
import { Type } from '@angular/compiler';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

/** Pass untouched request through to the next request handler. */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  activeRequests = 0;

  setHeader() {
    const pingFederateToken = localStorage.getItem('pingFederateToken');
    let headers = new HttpHeaders();
    if (pingFederateToken) {
      headers = headers.append('Authorization', 'Bearer ' + pingFederateToken);
    }
    // headers = headers.append('Content-Type', 'application/json');
    return headers;
  }

  intercept(
    req: HttpRequest<Type>,
    next: HttpHandler,
  ): Observable<HttpEvent<Type>> {
    const request = req.clone({
      headers: this.setHeader(),
    });

    // Show loading symbol when there are active requests. All the API calls will go through this interceptor.
    this.activeRequests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
      }),
    );
  }
}
