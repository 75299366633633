import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TripSignInAlert } from '../../types';
@Component({
  selector: 'crew-web-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {
  @Input() alertMessage = {} as TripSignInAlert;

  @Output() clickClose: EventEmitter<Event> = new EventEmitter();

  closeAlert(event: Event) {
    this.clickClose.emit(event);
  }
}
