import { Injectable, signal, WritableSignal } from '@angular/core';
import { SabreService as HttpService } from '../../services/azure/sabre.service';
import {
  GetSabreCommandsRequest,
  GetSabreCommandsResponse,
  CommonResults,
  CommonRequestArray,
  FlightRequestFlightData,
  CrewTripSignInRequest,
  CrewTripSignInResponse,
  CrewTripSignIn,
  SendFlightPlanSignResponseV2,
  SendFlightPlanSignRequestV2,
  FlightSequenceInfoV2,
} from '../../services/azure/sabre.service.types';
import { Colors, CrewUIToastService } from '@cci-web/crew-ui';
import { FlightLeg as CalendarFlightLeg } from '../../services/azure/calendar.service.types';
import {
  FlightLeg as ScheduleFlightLeg,
  Events,
  SequenceEvent,
  FlightDutyPeriods,
} from '../../services/azure/schedule.service.types';
import { UserService } from '../../services/user.service';
import {
  SabreDocumentSystemName,
  SabreDocumentKeyword,
  SabreDocumentKeywordDisplay,
  TripSignInMessage,
  SIGNATURE_REMARK,
  FLIGHT_PLAN_VERSION,
  SabreCommandParameter,
  ApiCallTypes,
  FlightPlanSignature,
} from './sabre-document.constant';
import { formatDate } from '../../utils/date.utils';
import { environment } from './../../../../environments/environment';
import {
  TripSignInButtonTitle,
  FlightPosition,
  TripSignInAlert,
} from '../../types';
import { LocationService } from '../../services/location.service';
import { InsightsService } from '../../services/azure/insights.service';
import { ITraceTelemetry } from '@microsoft/applicationinsights-web';
import { getFlightDataFromParameter } from '../../utils/flightLeg.utils';
export type GenerateSabreData = {
  fosReturnData: string;
  keyword: string;
  payload:
    | GetSabreCommandsRequest
    | CrewTripSignInRequest
    | SendFlightPlanSignRequestV2;
  showAlert: boolean;
  sequenceEvent: Events;
  apiCallType: string;
};
@Injectable({
  providedIn: 'root',
})
export class SabreDocumentService {
  constructor(
    private httpService: HttpService,
    private insights: InsightsService,
    private locationService: LocationService,
    private toastService: CrewUIToastService,
    private userService: UserService,
  ) {}

  sabreResponse: WritableSignal<CommonResults | undefined> = signal(undefined);

  tripSignInResponse: WritableSignal<CrewTripSignInResponse | undefined> =
    signal(undefined);

  tripSignInAlert: WritableSignal<TripSignInAlert> = signal({
    type: '',
    message: '',
  } as TripSignInAlert);

  signInFlightPlanEvent: WritableSignal<Events> = signal({} as Events);

  /**
   * Overview page show `Sign Flight plan` button in the sabre document html
   * @param event
   */
  signInFlightPlan(event: Events, showAlert = false) {
    const { sequence } = event;
    const activeFlightLeg =
      sequence?.activeFlightLeg || ({} as ScheduleFlightLeg);
    let payload = {} as GetSabreCommandsRequest;
    const fltKeysOwns = this.checkFlightKeysFlight(activeFlightLeg);
    const keyword = this.getEFlightPlanTitle(fltKeysOwns);
    payload = this.generateSabrePayload(keyword, activeFlightLeg);
    this.loadSabreCommand(payload, showAlert);
    this.signInFlightPlanEvent.set(event);
  }

  generateSabrePayload(
    keywordDisplay: string,
    flightLeg: CalendarFlightLeg | ScheduleFlightLeg,
    parameters = '',
  ) {
    const payload: GetSabreCommandsRequest = {
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      siteMinderEmpId: this.userService.employeeNumber(),
      employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      print: false,
      printerLNIATA: '',
      view: true,
      commonRequestArray: this.getCommonRequestArray(
        keywordDisplay,
        parameters,
        flightLeg,
      ),
    };
    if (Object.keys(flightLeg).length !== 0) {
      payload.flightRequestFlightData =
        this.generateFlightRequestFlightData(flightLeg);
    }
    return payload;
  }

  doSabreCall(
    keywordDisplay: string,
    flightLeg: CalendarFlightLeg | ScheduleFlightLeg,
    parameters = '',
  ) {
    const payload = this.generateSabrePayload(
      keywordDisplay,
      flightLeg,
      parameters,
    );
    this.signInFlightPlanEvent.set({} as Events);
    this.loadSabreCommand(payload);
  }

  doSabreCommandCall(commonRequestArray: CommonRequestArray[], view: boolean) {
    const payload: GetSabreCommandsRequest = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      siteMinderEmpId: this.userService.employeeNumber(),
      appSessionId: this.userService.appSession(),
      commonRequestArray: commonRequestArray,
      view: view,
      printerLNIATA: 'NONE',
      print: false,
    };
    this.signInFlightPlanEvent.set({} as Events);
    this.loadSabreCommand(payload);
  }

  reloadSabreCall(response: CommonResults) {
    if (
      typeof response?.payload === 'object' &&
      response?.payload?.airlineCode
    ) {
      if (response?.apiCallType === ApiCallTypes.SabreCall) {
        this.loadSabreCommand(response?.payload, response?.showAlert);
      } else if (response?.apiCallType === ApiCallTypes.TripSignIn) {
        this.callCrewTripSignIn(
          response?.payload,
          response?.sequenceEvent,
          response?.showAlert,
        );
      } else if (response?.apiCallType === ApiCallTypes.V2SignFlightPlan) {
        this.callV2SignFlightPlan(response?.sequenceEvent);
      }
    }
  }

  hasSabreResponse() {
    if (!this.sabreResponse()) return false;
    return true;
  }

  clearSabreResponse() {
    this.sabreResponse.set(undefined);
    this.tripSignInResponse.set(undefined);
    this.setTripSignInAlertMessage(``);
    // this.signInFlightPlanEvent.set({} as Events);
  }

  /**
   * ! View or Sign in flight plan for CA and Pilot
   * @param event
   * @param type
   * @returns
   */
  viewOrSignInFlightPlan(event: Events, type = ''): boolean | string {
    let buttonText = ``;
    let responseAsBoolean = false;
    let style = `bg-afterburner dark:bg-sushi`;
    let showIcon = false;
    const { sequence = {} as SequenceEvent } = event;
    const { activeFlightLeg = {} as ScheduleFlightLeg, positionCode } =
      sequence;
    const { keywordDisplay = '' } =
      this.sabreResponse() || ({} as CommonResults);
    if (
      (this.hasSabreResponse() &&
        keywordDisplay &&
        keywordDisplay === SabreDocumentKeywordDisplay.EFLIGHTPLAN) ||
      keywordDisplay === SabreDocumentKeywordDisplay.FLIGHTKEYSEFLIGHTPLAN
    ) {
      buttonText = TripSignInButtonTitle.SignInTrip;
      if (activeFlightLeg?.legIndex !== 1) {
        buttonText = TripSignInButtonTitle.SignFltPlan;
      }
      responseAsBoolean = true;
      showIcon = true;
    }
    if (positionCode && positionCode === FlightPosition.CA) {
      if (activeFlightLeg?.fltPlanSignature?.signedFlightPlan) {
        buttonText = TripSignInButtonTitle.ViewFltPlan;
        responseAsBoolean = true;
        style = `bg-stratosphere dark:bg-troposphere`;
        showIcon = false;
      } else if (activeFlightLeg?.fltPlanSignature?.confirmedFFD) {
        buttonText = TripSignInButtonTitle.SignFltPlan;
        responseAsBoolean = true;
        style = `bg-afterburner dark:bg-sushi`;
      } else if (
        activeFlightLeg?.fltPlanSignature &&
        !activeFlightLeg?.fltPlanSignature?.confirmedFFD &&
        !this.hasSabreResponse()
      ) {
        // If signed-in, but not confirmed FFD or signed flight plan, show sign flight plan + confirm FFD
        style = `bg-afterburner dark:bg-sushi`;
        buttonText = TripSignInButtonTitle.SignFltPlanConfirmFFD;
        responseAsBoolean = true;
        showIcon = false;
      }
    } else if (this.userService.isAAPIMQPI()) {
      // If signed in, but not sign flight plan eligible show confirm FFD
      if (activeFlightLeg?.fltPlanSignature?.confirmedFFD) {
        buttonText = TripSignInButtonTitle.ViewFltPlan;
        style = `bg-stratosphere dark:bg-troposphere`;
        responseAsBoolean = true;
        showIcon = false;
      } else if (
        activeFlightLeg?.fltPlanSignature &&
        !activeFlightLeg?.fltPlanSignature?.confirmedFFD
      ) {
        style = `bg-afterburner dark:bg-sushi`;
        buttonText = TripSignInButtonTitle.ConfirmFFD;
        responseAsBoolean = true;
        showIcon = false;
      }
    }

    // return the content based on the logic
    if (type === 'title' && responseAsBoolean) {
      return buttonText;
    } else if (type === 'style' && responseAsBoolean) {
      return style;
    } else if (type === 'icon' && responseAsBoolean) {
      return showIcon;
    } else {
      return responseAsBoolean;
    }
  }

  /**
   * Has logic FlightPlanSignature.ConfirmFFD = FO - second leg cinfirmation
   * Has logic signedFlightPlan
   * @param flightDutyPeriods
   * @param activeFlightLeg
   * @param type
   */
  onSuccessFlightPlanSignV2(
    flightDutyPeriods: FlightDutyPeriods[],
    activeFlightLeg: ScheduleFlightLeg,
    type: string,
  ) {
    if (
      activeFlightLeg &&
      activeFlightLeg.fltPlanSignature &&
      typeof activeFlightLeg.fltPlanSignature === 'object' &&
      activeFlightLeg.fltPlanSignature !== null
    ) {
      if (type === FlightPlanSignature.ConfirmFFD) {
        activeFlightLeg.fltPlanSignature.confirmedFFD = true;
      } else {
        activeFlightLeg.fltPlanSignature.signedFlightPlan = true;
      }
    }

    flightDutyPeriods.forEach((flight: FlightDutyPeriods) => {
      if (flight.flightLegs && flight.flightLegs.length > 0) {
        flight.flightLegs.forEach((leg: ScheduleFlightLeg) => {
          if (
            leg.flightNumber === activeFlightLeg.flightNumber &&
            typeof leg.fltPlanSignature === 'object' &&
            leg.fltPlanSignature !== null
          ) {
            leg.isExpandOpen = true;
            if (type === FlightPlanSignature.ConfirmFFD) {
              leg.fltPlanSignature.confirmedFFD = true;
            } else {
              leg.fltPlanSignature.signedFlightPlan = true;
              leg.isExpandOpen = true;
            }
          }
        });
      }
    });
  }

  /**
   * ! After `signedIn` first leg
   * ! on click `ViewFltPlan` `ConfirmFFD` `SignFltPlanConfirmFFD` `SignFltPlan`
   * @param event
   */
  onClickViewOrSignInFlightPlan(event: Events): void {
    const title = this.viewOrSignInFlightPlan(event, 'title');
    if (title && event) {
      const { sequence, sequencePosition } = event;
      const { activeFlightLeg = {} as ScheduleFlightLeg } =
        sequence || ({} as SequenceEvent);
      const { keywordDisplay = '' } =
        this.sabreResponse() || ({} as CommonResults);
      if (title === TripSignInButtonTitle.ViewFltPlan) {
        const fltKeysOwns = this.checkFlightKeysFlight(activeFlightLeg);
        const keyword = this.getEFlightPlanTitle(fltKeysOwns);
        this.doSabreCall(keyword, activeFlightLeg);
      } else if (
        title === TripSignInButtonTitle.ConfirmFFD ||
        title === TripSignInButtonTitle.SignFltPlanConfirmFFD
      ) {
        if (
          this.userService.isAAPIMQPI() &&
          title === TripSignInButtonTitle.ConfirmFFD &&
          FlightPosition &&
          sequencePosition === FlightPosition.FO &&
          activeFlightLeg &&
          activeFlightLeg?.legIndex > 1
        ) {
          this.callV2SignFlightPlan(event);
        } else {
          this.signInFlightPlan(event);
        }
        //this.callV2SignFlightPlan(event);
      } else if (title === TripSignInButtonTitle.SignFltPlan) {
        if (
          this.hasSabreResponse() &&
          keywordDisplay &&
          (keywordDisplay === SabreDocumentKeywordDisplay.EFLIGHTPLAN ||
            keywordDisplay ===
              SabreDocumentKeywordDisplay.FLIGHTKEYSEFLIGHTPLAN)
        ) {
          this.callV2SignFlightPlan(event);
        } else {
          this.signInFlightPlan(event);
        }
      }
    }
  }

  clearTripSignInAlertMessage(event: Event): void {
    event.stopPropagation();
    // this.tripSignInResponse.set(undefined);
    this.setTripSignInAlertMessage(``);
  }

  setTripSignInAlertMessage(message = ``, type = 'Success') {
    this.tripSignInAlert.set({
      type: type,
      message: message,
    });
  }

  /**
   * SignIn+ConfirmFFD
   * ! if success = true && tripSignedIn = true => proper success case
   * ! else if  withinAirport = false => error message from response key withinAirportMessage
   * ! else if tripSignedIn = false => show message response key from tripSignedInMessage
   * ! else errorMessage from response key -- if errorMessage is null then "We couldn’t sign you in for this trip. Please try again or contact the CCI team for support."
   * @param requestPayload
   */
  callCrewTripSignIn(
    requestPayload: CrewTripSignIn,
    sequenceEvent: Events,
    showAlert = true,
  ) {
    // TODO
    // ! Kevin
    // ! Pressed for time now, but in the future we need to call getTripSignInInfoV3.
    // ! This helps us determine if the user is on an authorized desktop machine.
    // ! After some research I found out this is what the old hybrid application does.
    // ! This is especially important for people that are signing in on a desktop device.

    this.locationService
      .loadLocation()
      .then(() => {
        let payload: CrewTripSignInRequest = {
          airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
          siteMinderEmpId: this.userService.emulatedOrDefaultEmployeeNumber(),
          businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
          empIdLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
          employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
          appSessionId: this.userService.appSession(),
          deviceLongtitude: this.locationService
            .locationSignal()
            .coords.longitude.toString(),
          deviceLatitude: this.locationService
            .locationSignal()
            .coords.latitude.toString(),
          // ! See todo comment above to understand why this is hardcoded
          deviceRadiusError: this.locationService.isDesktopDevice()
            ? '100000.0'
            : '100.0',
          cciAppVersion: environment.version,
          deviceType: 'Desktop',
        };
        payload = { ...payload, ...requestPayload };
        this.httpService.crewTripSignIn(payload).subscribe({
          next: (response: CrewTripSignInResponse) => {
            this.doLogicWithCrewTripSignInResponse(
              response,
              payload,
              sequenceEvent,
              showAlert,
              ApiCallTypes.TripSignIn,
            );
            this.doLogicWithCrewTripSignInRequest(sequenceEvent, response);
            this.tripSignInResponse.set(response);

            const trace: ITraceTelemetry = {
              message: 'Crew Trip Sign In',
              severityLevel: 1,
            };

            const traceProperties = {
              sequenceEvent,
              payload,
              response,
              geolocationEnabled: navigator.geolocation ? 'true' : 'false',
            };

            this.insights.trackTrace(trace, traceProperties);
          },
          error: (e) => {
            this.tripSignInResponse.set(undefined);
            console.error(e);
          },
        });
      })
      .catch((message) => {
        this.toastService.showToast({
          message,
          textColor: Colors.White,
          backgroundColor: Colors.Afterburner,
        });
      });
  }

  callV2SignFlightPlan(event: Events) {
    const { sequence, sequencePosition } = event;
    const {
      activeFlightLeg = {} as ScheduleFlightLeg,
      flightDutyPeriods = [] as FlightDutyPeriods[],
    } = sequence || ({} as SequenceEvent);
    let flightRequestFlightData =
      this.generateFlightRequestFlightData(activeFlightLeg);
    flightRequestFlightData = {
      ...flightRequestFlightData,
      ...{ fltIndex: activeFlightLeg.legIndex },
    };
    const flightKeysFlight = this.checkFlightKeysFlight(activeFlightLeg);
    const payload = {
      airlineCode: this.userService.emulatedOrDefaultAirlineCode(),
      siteMinderEmpId: this.userService.employeeNumber(),
      businessUnit: this.userService.emulatedOrDefaultBusinessUnit(),
      employeeLogin: this.userService.emulatedOrDefaultEmployeeNumber(),
      appSessionId: this.userService.appSession(),
      employeeFirstName: this.userService.firstName(),
      employeeLastName: this.userService.lastName(),
      flightKeysFlight: flightKeysFlight,
      flightPlanVersion: flightKeysFlight ? FLIGHT_PLAN_VERSION : '',
      employeeFunction: sequencePosition,
      flightRequestFlightData: flightRequestFlightData,
      flightSequenceInfo: this.v2FlightSequenceInfoData(event),
      signatureRemark: SIGNATURE_REMARK,
    } as SendFlightPlanSignRequestV2;
    this.httpService.sendFlightPlanSignV2(payload).subscribe({
      next: (response: SendFlightPlanSignResponseV2) => {
        if (response && response.success && response.ffdFlightPlanSuccessful) {
          if (
            this.userService.isAAPIMQPI() &&
            FlightPosition &&
            sequencePosition === FlightPosition.FO &&
            activeFlightLeg &&
            activeFlightLeg?.legIndex > 1
          ) {
            this.onSuccessFlightPlanSignV2(
              flightDutyPeriods,
              activeFlightLeg,
              FlightPlanSignature.ConfirmFFD,
            );
            this.setTripSignInAlertMessage(
              TripSignInMessage.V2ConfirmFFDSuccess,
            );
          } else {
            this.signInFlightPlanEvent.set({} as Events);
            this.onSuccessFlightPlanSignV2(
              flightDutyPeriods,
              activeFlightLeg,
              FlightPlanSignature.SignedFlightPlan,
            );
            this.setTripSignInAlertMessage(
              TripSignInMessage.V2SignFlightPlanSuccess,
            );
          }
        } else {
          this.signInFlightPlanEvent.set(event);
          this.setTripSignInAlertMessage(
            TripSignInMessage.V2SignFlightPlanFailure,
            'Something went wrong',
          );
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  getEFlightPlanPdfFileName(
    payload: GetSabreCommandsRequest | undefined,
  ): string {
    let fileName = ``;
    if (
      payload &&
      payload?.commonRequestArray?.length > 0 &&
      payload?.commonRequestArray[0]?.keywordDisplay &&
      payload?.commonRequestArray[0]?.keywordDisplay ===
        SabreDocumentKeywordDisplay.FLIGHTKEYSEFLIGHTPLAN
    ) {
      fileName = payload.commonRequestArray[0].parameters.split(' ')[0];
      let flightKeyName =
        SabreDocumentKeywordDisplay.FLIGHTKEYSEFLIGHTPLAN.toString();
      flightKeyName = flightKeyName.split(' ').join('-');
      fileName = fileName.split('WBD*').join(flightKeyName + '-');
      fileName = fileName.split('/').join('-');
    }
    return fileName;
  }

  /**
   * Call getSabreCommands API
   * @param payload - GetSabreCommandsRequest
   * @returns - GetSabreCommandsResponse
   */
  getSabreCommandRawData(payload: GetSabreCommandsRequest) {
    if (
      payload?.commonRequestArray?.length > 0 &&
      payload?.commonRequestArray[0]?.systemName === SabreDocumentSystemName.RES
    ) {
      return this.httpService.getResSabreCommands(payload);
    } else {
      return this.httpService.getSabreCommands(payload);
    }
  }

  /**
   * Generate HI6 payload for the first and next messages.
   * @param keyword - VPQ, VPR, VPDH
   * @param showNextMessage - true (HI6 Get, HI6 Delete, HI6 Count), false (HI6 Get)
   * @returns - GetSabreCommandsRequest
   */
  getHi6MessagePayload(keyword: string, showNextMessage = false) {
    const payload: GetSabreCommandsRequest = this.generateSabrePayload(
      keyword,
      {} as CalendarFlightLeg | ScheduleFlightLeg,
    );
    if (showNextMessage) {
      const commonRequestArrayHi6Delete = this.getCommonRequestArray(
        SabreDocumentKeywordDisplay.HI6DELETE,
        ``,
        {} as CalendarFlightLeg | ScheduleFlightLeg,
      );
      payload.commonRequestArray.push(commonRequestArrayHi6Delete[0]);
      const commonRequestArrayHi6Count = this.getCommonRequestArray(
        SabreDocumentKeywordDisplay.HI6COUNT,
        ``,
        {} as CalendarFlightLeg | ScheduleFlightLeg,
      );
      payload.commonRequestArray.push(commonRequestArrayHi6Count[0]);
    }
    return payload;
  }

  private getEFlightPlanTitle(fltKeysOwns: boolean): string {
    return fltKeysOwns
      ? SabreDocumentKeywordDisplay.FLIGHTKEYSEFLIGHTPLAN
      : SabreDocumentKeywordDisplay.EFLIGHTPLAN;
  }

  private checkFlightKeysFlight(activeFlightLeg: ScheduleFlightLeg): boolean {
    let response = false;
    if (
      typeof activeFlightLeg?.infoIndicators === 'object' &&
      activeFlightLeg?.infoIndicators !== null
    ) {
      response = activeFlightLeg?.infoIndicators?.fltKeysOwns ?? response;
    }
    return response;
  }

  private generateFlightRequestFlightData(
    flightLeg: CalendarFlightLeg | ScheduleFlightLeg,
  ): FlightRequestFlightData {
    const flightRequestFlightData: FlightRequestFlightData = {
      depDupCode: 0,
      depStation: flightLeg.departureStation,
      arrStation: flightLeg.arrivalStation,
      fltNumber: flightLeg.flightNumber,
      fltOrgDate: flightLeg.flightOriginationDate,
    };
    return flightRequestFlightData;
  }

  private getCommonRequestArray(
    keywordDisplay: string,
    parameters: string,
    flightLeg: CalendarFlightLeg | ScheduleFlightLeg,
  ) {
    const commonRequestArray = this.commonRequestList().filter(
      (c: CommonRequestArray) => c.keywordDisplay === keywordDisplay,
    );
    commonRequestArray[0].parameters = parameters
      ? parameters
      : getFlightDataFromParameter(
          commonRequestArray[0].parameters,
          flightLeg,
          this.userService.emulatedOrDefaultEmployeeNumber()?.toString(),
        );
    return commonRequestArray;
  }

  private commonRequestList(): CommonRequestArray[] {
    return [
      {
        commandId: '965',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.HSS,
        keywordDisplay: SabreDocumentKeywordDisplay.HSS,
        parameters: SabreCommandParameter.HSS,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '2943',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.NSV,
        keywordDisplay: SabreDocumentKeywordDisplay.CREWHOTELLIMOINFO,
        parameters: SabreCommandParameter.CREWHOTELLIMOINFO,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '393',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.VPQ,
        keywordDisplay: SabreDocumentKeywordDisplay.HI6GET,
        parameters: SabreCommandParameter.HI6,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '58',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.VPR,
        keywordDisplay: SabreDocumentKeywordDisplay.HI6COUNT,
        parameters: SabreCommandParameter.HI6,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '1426',
        deleteHI6Message: true,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.VPDH,
        keywordDisplay: SabreDocumentKeywordDisplay.HI6DELETE,
        parameters: SabreCommandParameter.HI6,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '55',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.NAP,
        keywordDisplay: SabreDocumentKeywordDisplay.HI2,
        parameters: SabreCommandParameter.HI2,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '26',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.NAP,
        keywordDisplay: SabreDocumentKeywordDisplay.HI1,
        parameters: SabreCommandParameter.HI1,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '1324',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.NSNST,
        keywordDisplay: SabreDocumentKeywordDisplay.NSNST,
        parameters: SabreCommandParameter.NSNST,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '1366',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.CDI,
        keywordDisplay: SabreDocumentKeywordDisplay.CDI,
        parameters: SabreCommandParameter.CDI,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '307',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.RGT,
        keywordDisplay: SabreDocumentKeywordDisplay.RGT,
        parameters: SabreCommandParameter.RGT,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '3186',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.EFLIGHTPLAN,
        keywordDisplay: SabreDocumentKeywordDisplay.EFLIGHTPLAN,
        parameters: SabreCommandParameter.EFLIGHTPLAN,
        systemName: SabreDocumentSystemName.FOS,
      },
      {
        commandId: '4666',
        deleteHI6Message: false,
        firstDeleteHI6Message: false,
        keyword: SabreDocumentKeyword.FLIGHTKEYSEFLIGHTPLAN,
        keywordDisplay: SabreDocumentKeywordDisplay.FLIGHTKEYSEFLIGHTPLAN,
        parameters: SabreCommandParameter.FLIGHTKEYSEFLIGHTPLAN,
        systemName: SabreDocumentSystemName.FOS,
      },
    ];
  }

  private doLogicWithCrewTripSignInRequest(
    event: Events,
    response: CrewTripSignInResponse,
  ) {
    if (event) {
      const { sequence } = event;
      if (response !== undefined && response.success && response.tripSignedIn) {
        event.signedIn = true;
        if (
          sequence &&
          sequence.activeFlightLeg &&
          sequence.activeFlightLeg.fltPlanSignature &&
          'confirmedFFD' in sequence.activeFlightLeg.fltPlanSignature
        ) {
          sequence.activeFlightLeg.fltPlanSignature.confirmedFFD = true;
        }
        if (
          sequence &&
          typeof sequence?.tripSignInInfo === 'object' &&
          !sequence?.tripSignInInfo?.signedIn &&
          this.userService.isAAFAMQFA()
        ) {
          sequence.tripSignInInfo.signedIn = true;
        }
      }
    }
  }

  private doLogicWithCrewTripSignInResponse(
    response: CrewTripSignInResponse,
    payload: CrewTripSignInRequest,
    sequenceEvent: Events,
    showAlert: boolean,
    apiCallType: string,
  ) {
    if (response && (!response.success || !response.tripSignedIn)) {
      let message = String(TripSignInMessage.CrewTripSignInFailure);
      let title = ``;
      if (!response.withinAirport) {
        message = response.withinAirportMessage;
        title = `Too far away`;
      } else if (!response.tripSignedIn) {
        message = response.tripSignedInMessage;
        title = ``;
      }
      this.setTripSignInAlertMessage(message, title);
    } else {
      const sabreData: GenerateSabreData = {
        fosReturnData: response?.fosReturnData,
        keyword: TripSignInButtonTitle.TripSignIn,
        payload: payload,
        showAlert: showAlert,
        sequenceEvent: sequenceEvent,
        apiCallType: apiCallType,
      };
      this.sabreResponse.set(this.generateSabreData(sabreData));
      const successMessage = this.userService.isAAFAMQFA()
        ? TripSignInMessage.CrewTripSignInSuccessFo
        : TripSignInMessage.CrewTripSignInSuccessPilot;
      this.setTripSignInAlertMessage(successMessage);
    }
  }

  private generateSabreData(sabreData: GenerateSabreData) {
    const {
      keyword,
      fosReturnData,
      payload,
      showAlert,
      sequenceEvent = {} as Events,
      apiCallType,
    } = sabreData;
    return {
      commandId: 0,
      errorMsg: '',
      keyword: keyword,
      keywordDisplay: keyword,
      kvaMessages: '',
      kvaMsgCount: 0,
      kvarReturnData: '',
      returnData: fosReturnData,
      flightNumber: '',
      sequenceNumber: '',
      departureStation: '',
      systemName: '',
      pdfOutput: '',
      pdfResponse: '',
      dateTime: formatDate(new Date(), 'YYYY-MM-DD HH:mm'),
      payload: payload,
      showAlert: showAlert,
      sequenceEvent: sequenceEvent,
      apiCallType: apiCallType,
    } as CommonResults;
  }

  /**
   * Display Eflight signed message.
   */
  private showSabreResponseMessage() {
    let message = ``;
    let type = 'error';
    if (
      this.signInFlightPlanEvent() &&
      typeof this.signInFlightPlanEvent() === 'object' &&
      this.sabreResponse()?.returnData
    ) {
      if (
        this.sabreResponse()?.returnData !== 'TRIP NOT UPDATED' &&
        this.sabreResponse()?.pdfOutput === null
      ) {
        message = TripSignInMessage.EFlightPlanSuccess;
        type = 'Success';
      } else {
        message = TripSignInMessage.EFlightPlanFailure;
        type = 'Something went wrong';
      }
    }
    this.setTripSignInAlertMessage(message, type);
  }

  private loadSabreCommand(
    payload: GetSabreCommandsRequest,
    showAlert = false,
  ): void | CommonResults {
    if (
      payload?.commonRequestArray?.length > 0 &&
      payload?.commonRequestArray[0]?.systemName === SabreDocumentSystemName.RES
    ) {
      this.callGetResSabreCommands(payload, showAlert);
    } else {
      this.callGetSabreCommands(payload, showAlert);
    }
  }

  /**
   * RES call
   * @param payload
   * @param showAlert
   */
  private callGetResSabreCommands(
    payload: GetSabreCommandsRequest,
    showAlert = false,
  ) {
    this.httpService.getResSabreCommands(payload).subscribe({
      next: (response: GetSabreCommandsResponse) => {
        this.setSabreResponseSuccessScenario(response, payload, showAlert);
      },
      error: (e) => {
        this.setSabreResponseFailureScenario(payload, showAlert);
        console.error(e);
      },
    });
  }

  /**
   * FOS call
   * @param payload
   * @param showAlert
   */
  private callGetSabreCommands(
    payload: GetSabreCommandsRequest,
    showAlert = false,
  ) {
    this.httpService.getSabreCommands(payload).subscribe({
      next: (response: GetSabreCommandsResponse) => {
        this.setSabreResponseSuccessScenario(response, payload, showAlert);
      },
      error: (e) => {
        this.setSabreResponseFailureScenario(payload, showAlert);
        console.error(e);
      },
    });
  }

  private v2FlightSequenceInfoData(event: Events): FlightSequenceInfoV2 {
    const {
      sequencePosition,
      sequenceContractMonth,
      sequenceNumber,
      activityStartTime,
    } = event;
    return {
      sequenceContractMonth: sequenceContractMonth,
      sequenceNumber: sequenceNumber,
      sequenceOriginationDate: formatDate(activityStartTime),
      sequencePosition: sequencePosition,
    } as FlightSequenceInfoV2;
  }

  private setSabreResponseSuccessScenario(
    response: GetSabreCommandsResponse,
    payload: GetSabreCommandsRequest,
    showAlert: boolean,
  ) {
    if (
      response.success &&
      response.commonResults &&
      response.commonResults.length > 0
    ) {
      response.commonResults[0].dateTime = formatDate(
        new Date(),
        'YYYY-MM-DD HH:mm',
      );
      response.commonResults[0].apiCallType = ApiCallTypes.SabreCall;
      response.commonResults[0].payload = payload;
      response.commonResults[0].showAlert = showAlert;
      this.sabreResponse.set(response.commonResults[0]);
      if (showAlert) {
        this.showSabreResponseMessage();
      }
    }
  }

  private setSabreResponseFailureScenario(
    payload: GetSabreCommandsRequest,
    showAlert: boolean,
  ) {
    const sabreData: GenerateSabreData = {
      fosReturnData: ``,
      keyword: '',
      payload: payload,
      showAlert: showAlert,
      sequenceEvent: {} as Events,
      apiCallType: ApiCallTypes.SabreCall,
    };
    this.sabreResponse.set(this.generateSabreData(sabreData));
    this.clearSabreResponse();
  }
}
