import { Component, Input } from '@angular/core';
import { CREW_ROUTES } from '../../routes/routes';
import { Colors, CrewUIDarkModeService } from '@cci-web/crew-ui';
import { SettingsModalService } from '../../shared/components/settings-modal/settings-modal.service';
import { SabreDocumentService } from '../../shared/components/sabre-document/sabre-document.service';
import { MessageService } from '../../routes/crew/outlets/message/message.service';

@Component({
  selector: 'crew-web-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  constructor(
    private darkModeService: CrewUIDarkModeService,
    private settingsModalService: SettingsModalService,
    private sabreDocumentService: SabreDocumentService,
    private messageService: MessageService,
  ) {}

  @Input()
  routerUrl = '';

  @Input()
  getCrewPath!: (args: string) => void;

  @Input()
  isActiveRoute!: (args: string) => boolean;

  routes = CREW_ROUTES;
  colors = Colors;

  get darkMode() {
    return this.darkModeService.isDarkMode();
  }

  get messageCount() {
    return this.messageService.lowAndMediumUnreadMessageCount();
  }

  get hi6Count() {
    return this.messageService.hi6Count();
  }

  showSettingsModal() {
    this.settingsModalService.show();
  }

  buildIconColor(): Colors {
    if (!this.isActiveRoute) return Colors.Nickel;

    if (this.darkMode) return Colors.Troposphere;

    return Colors.Stratosphere;
  }

  closeHSS() {
    this.sabreDocumentService.clearSabreResponse();
  }
}
