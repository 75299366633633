import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CREW_ROUTES } from '../routes';
import { SettingsModalService } from '../../shared/components/settings-modal/settings-modal.service';
import { UserService } from '../../shared/services/user.service';
import { MessageService } from './outlets/message/message.service';
import { FirebaseIdbService } from '../../shared/services/idb/firebase.idb.service';
import { environment } from '../../../environments/environment';
import { MessageIdbService } from '../../shared/services/idb/message.idb.service';

// Defining these in a global scope so they are singleton.
// Otherwise they will be redefined every time the component is initialized.
// This would cause the app to send out way too many requests.
// ? maybe in the future this could be moved to an interval service ?

// This interval ensures the user is navigated to the RA page when a RA message comes in.
let readAckMessageRefreshInterval: NodeJS.Timeout | undefined = undefined;

// This interval ensures unacknowledged ONE notifications are loaded periodically.
let unAckRefreshInterval: NodeJS.Timeout | undefined = undefined;

@Component({
  selector: 'crew-web-crew',
  templateUrl: './crew.component.html',
  styleUrls: ['./crew.component.scss'],
})
export class CrewComponent implements OnInit {
  constructor(
    private firebaseIdbService: FirebaseIdbService,
    private messageIdbService: MessageIdbService,
    private messageService: MessageService,
    private router: Router,
    private settingsModalService: SettingsModalService,
    private userService: UserService,
  ) {}

  routerUrl = '';
  isSettingsModalVisible = false;

  get isUserServiceLoading() {
    return this.userService.isLoading();
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.routerUrl = this.router.url;

    if (this.userService.apiDetails()) {
      // It is necessary to refresh messages on page load to ensure RA message page redirection happens.
      this.messageIdbService
        .refreshMessages(true)
        .then(() => this.readAckMessageRefreshAction());

      this.setRefreshIntervals();
    } else {
      this.userService.load().then(() => {
        this.messageIdbService
          .refreshMessages(true)
          .then(() => this.readAckMessageRefreshAction());

        this.setRefreshIntervals();
      });
    }
  }

  setRefreshIntervals() {
    if (readAckMessageRefreshInterval && unAckRefreshInterval) return;

    readAckMessageRefreshInterval = setInterval(
      () => this.readAckMessageRefreshAction(),
      environment.azure.services.readAndAckMessageRefreshIntervalMs,
    );

    unAckRefreshInterval = setInterval(
      () => this.unAckNotificationRefreshAction(),
      environment.azure.services.unAckRefreshIntervalMs,
    );
  }

  /**
   *  Logic for : Don't show RNA messages for the emulated user.
   *  Code used : this.userService.employeeNumber() ===
        this.userService.emulatedOrDefaultEmployeeNumber()
   */
  readAckMessageRefreshAction(): void {
    const count =
      this.messageService.readAcknowledgePriorityUnreadMessagesCount();
    if (
      count &&
      count > 0 &&
      this.userService.employeeNumber() ===
        this.userService.emulatedOrDefaultEmployeeNumber()
    ) {
      this.router.navigate(['/read-and-acknowledge']);
    }
  }

  unAckNotificationRefreshAction(): void {
    this.firebaseIdbService.reloadUnacknowledgedONENotifications();
  }

  getCrewPath = (path = '') => {
    return path
      ? `/${CREW_ROUTES.crew.root}/${path}`
      : `/${CREW_ROUTES.crew.root}`;
  };

  isActiveRoute = (path = '') => {
    return this.routerUrl === `/${CREW_ROUTES.crew.root}/${path}`;
  };

  showSettingsModal() {
    this.settingsModalService.isVisible();
  }
}
