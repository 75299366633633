import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // ! DYNATRACE NOT WORKING
  //   const script = document.createElement('script');
  //   script.src = 'TBD';
  //   script.type = 'text/javascript';
  //   script.crossOrigin = 'anonymous';
  //   document.head.appendChild(script);
  //   console.log('Production DT Script Loaded');
  // } else {
  //   const script = document.createElement('script');
  //   script.src =
  //     'https://js-cdn.dynatrace.com/jstag/185a0521924/bf46697lpb/9b8225825d77dd44_complete.js';
  //   script.type = 'text/javascript';
  //   script.crossOrigin = 'anonymous';
  //   document.head.appendChild(script);
  //   console.log('Non Prod DT Script Loaded');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
