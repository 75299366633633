import { Component } from '@angular/core';
import { CalendarCode } from './../calendar.constant';
import { ToggleButtonOption } from './../calendar.types';
import {
  Colors,
  CrewUIDarkModeService,
  SizeTypes,
  Selected,
} from '@cci-web/crew-ui';
import { formatDate } from '../../../../../shared/utils/date.utils';
import { CalendarService } from '../calendar.service';
import { SabreDocumentService } from '../../../../../shared/components/sabre-document/sabre-document.service';
import { SabreDocumentKeywordDisplay } from '../../../../../shared/components/sabre-document/sabre-document.constant';
import { FlightLeg } from '../../../../../shared/services/azure/calendar.service.types';
@Component({
  selector: 'crew-web-calendar-menu',
  templateUrl: './calendar-menu.component.html',
  styleUrls: ['./calendar-menu.component.scss'],
})
export class CalendarMenuComponent {
  isFilterVisible = false;

  toggleMore = false;

  isValidPreviousMonth = false;

  isValidNextMonth = false;

  size = SizeTypes.Small;

  colors = Colors;

  modifiedToggleOptions: boolean[] = [];

  get currentDate() {
    return this.calendarService.selectedDate();
  }

  get isDark() {
    return this.darkModeService.isDarkMode();
  }

  get selectedToggleEvents() {
    return this.calendarService.selectedToggleEvents();
  }

  get getToggleButtonOptions() {
    return this.calendarService.getToggleButtonOptions();
  }

  get isPreviousMonthValid() {
    return this.calendarService.isPreviousMonthValid();
  }

  get isNextMonthValid() {
    return this.calendarService.isNextMonthValid();
  }

  readonly sabreKeywordDisplay = SabreDocumentKeywordDisplay;

  constructor(
    private darkModeService: CrewUIDarkModeService,
    private calendarService: CalendarService,
    private sabreDocumentService: SabreDocumentService,
  ) {}

  applySwitchSettings(option: ToggleButtonOption) {
    this.calendarService.onChangeToggleEvents(option);
  }

  stopEventPropagation(event: Event) {
    event.stopPropagation();
  }

  onToggleMore(event: Event) {
    this.stopEventPropagation(event);
    this.toggleMore = !this.toggleMore;
  }

  onClickList(event: Event, optionType: string): void {
    this.stopEventPropagation(event);
    if (optionType === 'filter') {
      this.isFilterVisible = !this.isFilterVisible;
    } else if (optionType === SabreDocumentKeywordDisplay.HI1) {
      this.sabreDocumentService.doSabreCall(optionType, {} as FlightLeg);
    } else if (optionType === 'refreshCalendar') {
      this.calendarService.loadCalendar();
    }
  }

  /**
   * On click previous month
   */
  onClickPreviousMonth(isValid: boolean) {
    if (isValid) {
      this.calendarService.onChangeCalendarMonth(
        this.calendarService.monthIndex() - 1,
        CalendarCode.PREVIOUSMONTH,
      );
    }
  }

  /**
   * On click next month
   */
  onClickNextMonth(isValid: boolean) {
    if (isValid) {
      this.calendarService.onChangeCalendarMonth(
        this.calendarService.monthIndex() + 1,
        CalendarCode.NEXTMONTH,
      );
    }
  }

  formatDate(date: Date): string {
    return formatDate(date.toString(), 'MMMM');
  }

  /**
   * On change Previous/Next month, change the button color
   * @param value - true/false
   * @returns- Colors
   */
  iconColor(value: boolean): Colors {
    let style = this.isDark
      ? this.colors.Troposphere
      : this.colors.Stratosphere;
    if (value) {
      style = this.isDark ? this.colors.Steel : this.colors.Nickel;
    }
    return style;
  }

  toggleDarkMode($event: Selected) {
    this.darkModeService.toggle($event.selected);
  }
}
