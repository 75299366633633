import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CREW_ROUTES } from '../routes';
import { CalendarComponent } from './outlets/calendar/calendar.component';
import { OverviewComponent } from './outlets/overview/overview.component';
import { MessageComponent } from './outlets/message/message.component';
import { SearchComponent } from './outlets/search/search.component';
import { CrewComponent } from './crew.component';
import { InstallationGuideComponent } from '../installation-guide/installation-guide.component';
import { CustomPingAuthGuard } from '../../shared/guards/custom-ping-auth.guard';

const appRoutes: Routes = [
  {
    path: '',
    component: CrewComponent,
    data: { role: ['crew'] },
    canActivate: [CustomPingAuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: CREW_ROUTES.crew.overview,
      },
      {
        path: CREW_ROUTES.crew.overview,
        component: OverviewComponent,
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: CREW_ROUTES.crew.calendar,
        component: CalendarComponent,
        data: {
          title: 'Calendar',
        },
      },
      {
        path: CREW_ROUTES.crew.message,
        component: MessageComponent,
        data: {
          title: 'Message',
        },
      },
      {
        path: CREW_ROUTES.crew.search,
        component: SearchComponent,
        data: {
          title: 'Search',
        },
      },
      {
        path: CREW_ROUTES.crew.install,
        component: InstallationGuideComponent,
        data: {
          title: 'Install',
        },
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class CrewRoutingModule {}
