import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'calendarEventTitle',
})
export class CalendarEventTitlePipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) return '';
    let title = value;
    if (value === 'GD') {
      title = 'Golden day';
    } else if (value === 'DFP') {
      title = 'Duty free period';
    } else if (value === 'FD') {
      title = 'Flex day';
    } else if (value === 'SK') {
      title = 'Sick';
    }
    return title;
  }
}
