import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MenuList } from '../../../../../shared/services/azure/core.service.types';
@Component({
  selector: 'crew-web-search-menu-card',
  templateUrl: './search-menu-card.component.html',
  styleUrls: ['./search-menu-card.component.scss'],
})
export class SearchMenuCardComponent {
  @Input()
  menuList: MenuList[] = [];

  @Input()
  title = '';

  @Output() selectedMenu = new EventEmitter<string>();

  onClickMenuList(title: string) {
    this.selectedMenu.emit(title);
  }
}
