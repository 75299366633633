import { environment } from '../../../../environments/environment';
import {
  GetScheduleDetailsRequest,
  GetScheduleDetailsResponse,
} from './calendar.service.types';
import { HttpService } from '../http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private httpService: HttpService) {}

  private readonly base = `${environment.azure.services.url}/calendar/v1`;

  getScheduleDetails(body: GetScheduleDetailsRequest, bypassLoader = false) {
    const url = `${this.base}/getScheduleDetails`;
    return this.httpService.post<
      GetScheduleDetailsRequest,
      GetScheduleDetailsResponse
    >(url, body, bypassLoader);
  }
}
