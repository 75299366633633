<li
  (click)="showMessageMenu()"
  [ngClass]="{
    'bg-ozone dark:bg-carbon text-stratosphere':
      priority && selectedPriority === priority
  }"
  class="hover:bg-cirrus dark:hover:bg-carbon flex cursor-pointer items-center py-2 pl-3 pr-1">
  <crew-ui-icon
    class="flex w-full max-w-[26px] items-center justify-center"
    [name]="icon"
    [color]="color" />
  <div class="flex items-center justify-start">
    <p class="px-4 py-2 text-base font-normal dark:text-white">
      {{ modalTitle ?? title }}
    </p>
  </div>
  <div class="ml-auto flex items-center justify-end">
    <span
      *ngIf="messageCount && messageCount > 0"
      class="px-4 py-2 text-base font-normal dark:text-white">
      {{ messageCount > 99 ? '99+' : messageCount }}
    </span>
    <crew-ui-icon
      name="chevron_right"
      [color]="isDark ? colors.White : colors.Black" />
  </div>
</li>
