<section
  class="text-carbon dark:bg-charcoal flex h-full flex-col bg-white dark:text-white">
  <div class="w-full justify-between py-2">
    <div class="flex items-center px-4 py-2">
      <button
        type="button"
        (click)="onCloseDocument()"
        class="dark:text-troposphere text-stratosphere text-[17px] font-normal leading-[24px]">
        Close
      </button>
      <div class="ml-auto flex justify-between">
        <button
          title="Share"
          type="button"
          (click)="onShareDocument($event)"
          class="dark:text-troposphere text-stratosphere px-2">
          <crew-ui-icon name="ios_share"></crew-ui-icon>
        </button>
      </div>
    </div>
    <div
      class="border-cirrus dark:border-charcoal flex items-center border-b px-4 py-2">
      <div
        class="text-carbon text-[20px] font-medium leading-[25px] dark:text-white">
        <span *ngIf="sabreResponse?.keywordDisplay">
          {{ sabreResponse?.keywordDisplay | uppercase }}
        </span>
      </div>
    </div>
    <div
      class="border-cirrus dark:border-charcoal flex items-center border-b px-4 py-2">
      <span
        class="text-carbon text-[14px] font-normal leading-[15px] dark:text-white">
        {{ showUpdatedTime(sabreResponse?.dateTime) }}
      </span>
      <div
        *ngIf="
          sabreResponse?.payload && (sabreResponse?.payload | json) !== '{}'
        "
        class="ml-auto flex items-center justify-between">
        <button
          type="button"
          (click)="onRefresh(sabreResponse)"
          title="refresh"
          class="dark:text-troposphere text-stratosphere px-2">
          <crew-ui-icon name="refresh"></crew-ui-icon>
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="sabreResponse?.returnData"
    class="lt-sm:w-[22rem] flex h-full flex-col overflow-y-auto px-4 py-2">
    <crew-web-safe-html
      *ngIf="sabreResponse?.returnData"
      [content]="sabreResponse?.returnData"></crew-web-safe-html>
  </div>
  <div *ngIf="sabreResponse?.pdfOutput" class="h-full w-full">
    <crew-web-pdf-viewer
      [fileName]="getEFlightPlanPdfFileName(sabreResponse?.payload)"
      [pdfString]="sabreResponse?.pdfOutput"></crew-web-pdf-viewer>
  </div>
  <ng-container *ngIf="(signInFlightPlanEvent | json) !== '{}'">
    <section
      class="lt-md:bottom-12 ng-star-inserted absolute bottom-0 w-full dark:text-white"
      ><div
        class="dark:bg-charcoal bg-ozone gt-md:-ml-24 gt-md:pl-24 p-4 text-center dark:text-white">
        <ng-container *ngIf="signFlightPlan(signInFlightPlanEvent)">
          <button
            [ngClass]="[signFlightPlan(signInFlightPlanEvent, 'style')]"
            (click)="onClickSignFlightButton(signInFlightPlanEvent)"
            class="lt-sm:w-4/5 inline-flex w-1/3 justify-center rounded px-4 py-2 font-medium text-white">
            <span
              class="pr-2"
              *ngIf="signFlightPlan(signInFlightPlanEvent, 'icon')"
              ><crew-ui-icon name="edit_square" /></span
            >{{ signFlightPlan(signInFlightPlanEvent, 'title') }}
          </button>
        </ng-container>
      </div></section
    >
  </ng-container>
</section>
<crew-web-alert-modal
  *ngIf="tripSignInAlert && tripSignInAlert?.message !== ''"
  [alertMessage]="tripSignInAlert"
  (clickClose)="onClickClose($event)"></crew-web-alert-modal>
<crew-ui-overlay *ngIf="shareMenu" (click)="onShareDocument($event)">
  <div
    class="gt-xs:top-12 text-md lt-sm:bottom-0 lt-sm:left-0 lt-sm:p-2 lt-sm:my-2 lt-sm:w-full dark:bg-carbon absolute right-4 z-10 w-56 rounded-lg bg-white shadow-lg">
    <ul class="py-2 text-sm">
      <li
        ><button
          type="button"
          (click)="callForwardMessage()"
          title="Forward via email"
          class="hover:bg-cirrus dark:hover:bg-charcoal hover:text-carbon text-carbon flex w-full cursor-pointer items-center justify-between p-3 dark:text-white dark:hover:text-white">
          <div class="flex w-full items-center">
            <div class="px-1">
              <crew-ui-icon
                name="forward"
                [color]="isDark ? colors.White : colors.Carbon"></crew-ui-icon>
            </div>
            <div class="ml-4 flex w-full flex-col text-left">
              <div
                class="text-carbon text-[15px] font-medium leading-[24px] dark:text-white"
                >Forward via email</div
              >
            </div>
          </div>
        </button></li
      >
    </ul>
  </div>
</crew-ui-overlay>
<crew-web-forward-message
  (callOnClickCancel)="callForwardMessage()"
  *ngIf="isForwardComponentVisible" />
