import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CREW_ROUTES } from '../routes';
import { environment } from '../../../environments/environment';
import {
  Colors,
  CrewUIDividerComponent,
  CrewUIToastService,
} from '@cci-web/crew-ui';

@Component({
  selector: 'crew-web-installation-guide',
  templateUrl: './installation-guide.component.html',
  styleUrls: ['./installation-guide.component.scss'],
  standalone: true,
  imports: [CommonModule, CrewUIDividerComponent],
})
export class InstallationGuideComponent implements OnInit {
  constructor(
    private router: Router,
    private toastService: CrewUIToastService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      const fragment = this.router.parseUrl(this.router.url).fragment;
      if (fragment) {
        window.location.href = `install#${fragment}`;
      }
    }, 1000);
  }

  goToOverview() {
    this.router.navigate([CREW_ROUTES.crew.overview]);
  }

  copy(anchor: string) {
    anchor = `${environment.pingFederate.logoutUrl}/install#${anchor}`;
    const type = 'text/plain';
    const blob = new Blob([anchor], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data);
    this.toastService.showToast({
      message: 'Copied',
      backgroundColor: Colors.Zanzibar,
      textColor: Colors.White,
    });
  }
}
