<span *ngIf="values.length === 0">--</span>
<span *ngFor="let v of values; last as isLast">
  <a
    class="text-stratosphere dark:text-troposphere cursor-pointer"
    [href]="v.link"
    target="_blank">
    {{ v.text }}
  </a>
  <span *ngIf="!isLast"> / </span>
</span>
