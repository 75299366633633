import { Injectable, WritableSignal, signal } from '@angular/core';
import { CREW_ROUTES } from '../../routes/routes';

export type Tokens = {
  accessToken: AccessToken;
  accessTokenValue: string;
  idToken: IdToken;
  idTokenValue: string;
  refreshTokenValue: string;
};

export type AccessToken = {
  aagemployeestatus: 'A' | 'I' | 'R';
  amraccounttype: string;
  amrcompany: string; // 'AA'
  amrdisabled: '0' | '1';
  amrlocked: '0' | '1';
  amrpwdexpiredate: string; // 08/08/24 12:07:10
  aud: string;
  authorization_details: [];
  client_id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Email: string;
  exp: number; // epoch time
  first_name: string;
  iat: number; // epoch time
  iss: string;
  job_title: string;
  jti: string; // "A3HwDqI"
  last_name: string;
  location: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Organization: 'AA';
  samaccountname: string;
  scope: string;
  sub: string;
  uid: string;
};

export type IdToken = {
  amraccounttype: string;
  amrappmfa: string;
  amrcompany: string;
  amrpwdexpiredate: string;
  aud: string;
  auth_time: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Email: string;
  exp: number;
  first_name: string;
  iat: number;
  iss: string;
  job_title: string;
  jti: string;
  last_name: string;
  location: string;
  samaccountname: string;
  sub: string;
  uid: string;
  username: string;
};

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  tokens: WritableSignal<Tokens | undefined> = signal<Tokens | undefined>(
    undefined,
  );

  load(): void {
    const item = this.getLocalStorageKeyStartingWith('oidc.user');

    if (item === null) return undefined;

    const accessTokenValue = JSON.parse(item.value).access_token;
    const idTokenValue = JSON.parse(item.value).id_token;
    const refreshTokenValue = JSON.parse(item.value).refresh_token;

    if (!accessTokenValue || !idTokenValue || !refreshTokenValue)
      return undefined;

    const accessToken = this.decodeTokenBody<AccessToken>(accessTokenValue);

    const idToken = this.decodeTokenBody<IdToken>(idTokenValue);

    this.tokens.set({
      accessTokenValue,
      idTokenValue,
      refreshTokenValue,
      accessToken,
      idToken,
    });
  }

  logout(): void {
    this.clearStorage();
    window.location.href = CREW_ROUTES.authLogout;
  }

  decodeTokenBody<T>(token: string): T {
    const body = atob(token.split('.')[1]);
    return JSON.parse(body) as T;
  }

  isExpired(exp: number): boolean {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return currentTime > exp;
  }

  private clearStorage(): void {
    const item = this.getAllLocalStorageKeysStartingWith('oidc');
    item.forEach((item) => {
      localStorage.removeItem(item.key);
    });
    sessionStorage.clear();
  }

  getLocalStorageKeyStartingWith(
    prefix: string,
  ): { key: string; value: string } | null {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith(prefix)) {
        const value = localStorage.getItem(key);
        if (value === null) return null;
        return { key, value };
      }
    }
    return null;
  }

  private getAllLocalStorageKeysStartingWith(
    prefix: string,
  ): { key: string; value: string }[] {
    const results: { key: string; value: string }[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith(prefix)) {
        const value = localStorage.getItem(key);
        if (value !== null) {
          results.push({ key, value });
        }
      }
    }
    return results;
  }
}
