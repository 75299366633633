<div class="h-full w-full">
  <router-outlet />
  <crew-ui-toast />
  <crew-ui-overlay [isHidden]="!isLoading">
    <div class="flex flex-col items-center justify-center">
      <div class="mb-4 h-24 w-24">
        <crew-ui-logo [full]="false"></crew-ui-logo>
      </div>
      <crew-ui-spinner [size]="'sm'"></crew-ui-spinner>
    </div>
  </crew-ui-overlay>
  <crew-web-settings-modal />
</div>
